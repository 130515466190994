import { mapImage } from '@headless-workspace/domain/common/client';
import { Image } from '@headless-workspace/typings';
import { HomeButtonsWebmoDTO, HomeSlotBase, HomeSlotType, mapHomeSlotBase } from '../../../../../src';

export type HomeMobileButtonsValue = HomeSlotBase & {
    type: HomeSlotType.MobileButtons;
    buttons: {
        image?: Image;
        title?: string;
        href?: string;
    }[];
};

export const mapHomeMobileButtons = (dto: HomeButtonsWebmoDTO): HomeMobileButtonsValue => {
    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.MobileButtons,
        buttons: dto.details.items.map((item) => ({
            image: mapImage(item.image),
            title: item.title,
            href: item.link,
        })),
    };
};
