import { getTranslations } from 'next-intl/server';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { AsyncComponent, VipBrandsValue } from '@headless-workspace/domain/common/server';
import { PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { DI, PriceLegalMentionsRepositoryType, ProductCarousel, ProductsRepositoryType } from '../../../../../src';

type ProductCarouselContainerProps = PropsWithLocale &
    PropsWithClassName & {
        productsIds: string[];
        vipBrands: VipBrandsValue;
        type?: 'beautyTips';
        navigationButtonClassName?: string;
    };

export const ProductCarouselContainer: AsyncComponent<ProductCarouselContainerProps> = async ({
    productsIds,
    locale,
    vipBrands,
    className,
    navigationButtonClassName,
    type,
}) => {
    const enablePriceLegalMentionsResult = await DI.get(PriceLegalMentionsRepositoryType).fetchEnablePriceLegalMentions(
        locale,
    );

    if (productsIds.length === 0) {
        return null;
    }

    const t = await getTranslations('Discover.Home.carousel');

    const productsResult = await DI.get(ProductsRepositoryType).getProducts(locale, productsIds, vipBrands, true);

    if (productsResult.type !== 'success') {
        return null;
    }

    const enablePriceExceptionLegalMentions = unwrapResultSuccess(enablePriceLegalMentionsResult) ?? false;

    return (
        <ProductCarousel
            className={className}
            products={productsResult.data}
            prevBtnAriaLabel={t('ariaLabel.prevBtn')}
            nextBtnAriaLabel={t('ariaLabel.nextBtn')}
            wishlistBtnAriaLabel={t('ariaLabel.wishlist')}
            thumbArialLabel={t('ariaLabel.thumb')}
            locale={locale}
            navigationButtonClassName={navigationButtonClassName}
            type={type}
            enablePriceExceptionLegalMentions={enablePriceExceptionLegalMentions}
        />
    );
};
