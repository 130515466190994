import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';

const mockBrandCornerStyle = 'h-[112px] desktopS:h-[80px] desktop:h-[48px] bg-background-infos'; //Fixme: delete

export const ProductDetailFooterSection: Component = () => {
    return (
        <section
            id={'pdp-footer-section'}
            className={clsx(
                'flex justify-center items-center',
                'py-0.5 px-1 desktopS:p-1 desktop:px-1.5',
                mockBrandCornerStyle,
            )}
        >
            Pdp footer section
        </section>
    );
};
