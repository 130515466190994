import { Locale, ReviewsSortOption } from '@headless-workspace/config';
import { UserSephoraCard, UserSephoraCardUnlimited } from '@headless-workspace/domain/common/server';

export enum ReviewPurchaserType {
    VERIFIED = 'verified',
    TESTER = 'tester',
    UNCONTROLLED = 'uncontrolled',
}

export type ProductReview = {
    id: string;
    rating: number;
    createdAt: Date;

    userName: string;
    userGender?: string;
    userAge?: string;
    userCard?: UserSephoraCard | UserSephoraCardUnlimited;

    purchaserType: ReviewPurchaserType;
    purchaseProductSince: string;

    title: string;
    content?: string;
    language?: Locale;
    isRecommended?: boolean;
    source?: {
        label: string;
        href: string;
    };
    vote: {
        like: number;
        dislike: number;
    };
};

export type ReviewSortOption = {
    id: string;
    value: string;
    tag: ReviewsSortOption;
};

export type RatingFilterData = {
    ratingValue: number;
    reviewsCountPerRating: number;
    progressValue: number;
};

export type ProductDetailReviewsWithSortingValue = Readonly<{
    reviews: ProductReview[];
    sortingOption: ReviewSortOption[];
    ratingFilter: RatingFilterData[];
}>;
