'use client';

import { useTranslations } from 'next-intl';
import { MediaValue } from '@headless-workspace/domain/common/client';
import { Component } from '@headless-workspace/typings';
import { ProductVisualDesktop } from './ProductVisualDesktop';
import { ProductVisualMobile } from './ProductVisualMobile';

export type ProductDetailVisualProps = {
    medias: MediaValue[];
    onProductDetailVisualClick: (index: number) => void;
    isSocialProof: boolean;
};

export const ProductDetailVisual: Component<ProductDetailVisualProps> = ({
    medias,
    onProductDetailVisualClick,
    isSocialProof,
}) => {
    const t = useTranslations('Discover.Pdp');

    const sharedProps = {
        medias,
        onProductVisualClick: onProductDetailVisualClick,
        socialProofLabel: isSocialProof ? t('carousel.socialProof') : undefined,
    };

    return (
        <>
            <ProductVisualMobile
                {...sharedProps}
                className={'desktopS:hidden'}
                prevBtnAriaLabel={t('carousel.ariaLabel.prevBtn')}
                nextBtnAriaLabel={t('carousel.ariaLabel.nextBtn')}
                thumbArialLabel={t('carousel.ariaLabel.thumb')}
            />
            <ProductVisualDesktop {...sharedProps} className={'hidden desktopS:block'} />
        </>
    );
};
