'use client';

import clsx from 'clsx';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { GlowTextBody, PropsWithFontFamily, typographyStyle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import styles from './product-info-section.module.scss';

type ProductInfoSectionProps = PropsWithFontFamily & {
    value: HTMLString;
    label: string;
};

export const ProductInfoDescription: Component<ProductInfoSectionProps> = ({ value, label, fontFamily }) => {
    return (
        <div id={'product-description-box'} className={'whitespace-normal w-full'} itemProp={'description'}>
            <GlowTextBody text={label} fontWeight={'bold'} fontFamily={fontFamily} />
            <InnerHTML
                className={clsx(
                    styles.descriptionWrapper,
                    styles.descriptionContent,
                    typographyStyle({ fontFamily }),
                    'overflow-hidden',
                    'max-h-productDescription',
                )}
                rawHtml={value}
            />
        </div>
    );
};
