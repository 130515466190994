'use client';

import { useCallback, useMemo } from 'react';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import {
    DataLayerEvent,
    DataLayerEventCategory,
    useDataLayer,
    useUserInfo,
} from '@headless-workspace/domain/common/client';
import { isConnectedUserInfo } from '@headless-workspace/domain/common/server';
import { useWishlistMutations } from './useWishlistMutations';
import { useWishlistProductsList } from './useWishlistProductsList';

const WishlistDataLayerAction = {
    Add: 'Add to wishlist',
    Remove: 'Remove from wishlist',
};

type WishlistOnClickParams = { productId: string; productSku: string; productName: string };

export const useWishlist = () => {
    const { trackEvent } = useDataLayer();

    // Retrieve wishlist and wishlist actions
    const { result: wishlistResult } = useWishlistProductsList();
    const wishlist = unwrapResultSuccess(wishlistResult);

    const { add: addToWishlist, remove: removeFromWishlist } = useWishlistMutations();

    // Retrieve user information
    const { result: userInfoResult } = useUserInfo();
    const isConnected = useMemo(
        () => userInfoResult.type === 'success' && isConnectedUserInfo(userInfoResult.data),
        [userInfoResult],
    );

    const findProductInWishlist = useCallback(
        (productSku: string) => {
            return wishlist?.products.find((wishlistProduct) => wishlistProduct.productId === productSku);
        },
        [wishlist],
    );

    const update = useCallback(
        ({ productSku, productName, productId }: WishlistOnClickParams) => {
            if (!wishlist) {
                return;
            }

            let wishlistProductsCount = wishlist.products.length;
            const isProductInWishlist = !!findProductInWishlist(productSku);

            if (!isProductInWishlist) {
                wishlistProductsCount++;

                addToWishlist(productSku);
            } else {
                wishlistProductsCount--;

                const wishListProductId = findProductInWishlist(productSku)?.id;
                if (wishListProductId) {
                    removeFromWishlist(wishListProductId);
                }
            }
            // Track add delete from/to wishlist event
            trackEvent(DataLayerEvent.Click, {
                id: 'click',
                event_type: 'Wishlist',
                category: DataLayerEventCategory.Wishlist,
                action: !isProductInWishlist ? WishlistDataLayerAction.Add : WishlistDataLayerAction.Remove,
                label: `${productName}|${productId}`,
                wishlist_number: `${wishlistProductsCount}`,
            });
        },
        [wishlist, findProductInWishlist, trackEvent, addToWishlist, removeFromWishlist],
    );

    return {
        shouldAddToWishlist: isConnected,
        update,
        isProductInWishlist: (productSku: string) => !!findProductInWishlist(productSku),
    };
};
