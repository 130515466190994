import { CountryKeyValue, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result } from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheRefreshTokenStrategy,
    memoryCacheQueryParamStrategy,
} from '@headless-workspace/data';
import { LanguageHelpers } from '@headless-workspace/utils';
import { OmnibusInformationsDTO } from './OmnibusInformationsDTO';
import { OmnibusInformationsValue } from './OmnibusInformationsValue';

export interface OmnibusInformationsRepositorySpec {
    getOmnibusInformations(locale: Locale): Promise<Result<OmnibusInformationsValue>>;
}

const OMNIBUS_INFORMATIONS_KEY = 'OmnibusInformations';

export class OmnibusInformationsRepository extends ConfigByKeyRepository implements OmnibusInformationsRepositorySpec {
    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getOmnibusInformations(locale: Locale): Promise<Result<OmnibusInformationsValue>> {
        return await this.getConfigByKey(OMNIBUS_INFORMATIONS_KEY, { locale }, (data) =>
            OmnibusInformationsDTO.parse(
                LanguageHelpers.retrieveDataByCountry(data as CountryKeyValue<OmnibusInformationsValue>, locale),
            ),
        );
    }
}
