import { mapImage } from '@headless-workspace/domain/common/client';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { Image } from '@headless-workspace/typings';
import {
    CTA,
    HomeBannerBaseDTO,
    HomeMosaicWindowsDTO,
    HomeSlotBase,
    HomeSlotDTOTypes,
    HomeSlotType,
    HomeUnderBannersDTO,
    mapHomeSlotBase,
} from '../../../../index';

export type HomeBannerBase = {
    dataLayer?: {
        id: string;
        name: string;
        action: string;
    };
    href?: string;
    title?: HTMLString;
    subTitle?: HTMLString;
    label?: HTMLString;
    content?: HTMLString;
    image?: Image;
    redirectionCTA?: CTA;
    legalMention?: {
        prefix: string;
        cta?: CTA;
    };
    tags?: CTA[];
};

export const mapHomeBanners = (
    dto: HomeMosaicWindowsDTO | HomeUnderBannersDTO,
): HomeMainBannerValue | HomeUnderBannersValue | null => {
    if (dto.type === HomeSlotDTOTypes.MozaicWindows.value) {
        return mapHomeMainBanner(dto);
    }
    return mapHomeUnderBanners(dto);
};

const MAIN_BANNER = 'main banner';

export const mapHomeBannerBase = (dto: HomeBannerBaseDTO, action: string): HomeBannerBase => {
    const titles = dto.title?.split('\r\n');
    const redirectionCTA = dto.ctas && dto.ctas?.length > 0 ? dto.ctas?.[0] : undefined;
    const mentionCTA = dto.ctas && dto.ctas?.length > 1 ? dto.ctas?.[1] : undefined;
    const href = redirectionCTA?.link;

    return {
        href,
        dataLayer:
            dto.id && dto.name
                ? {
                      id: dto.id,
                      name: dto.name,
                      action: action,
                  }
                : undefined,
        title: titles ? HTMLString(titles[0]) : undefined,
        subTitle: titles && titles.length > 1 ? HTMLString(titles[titles.length - 1]) : undefined,
        label: dto.subTitle ? HTMLString(dto.subTitle) : undefined,
        content: dto.content ? HTMLString(dto.content) : undefined,
        image: mapImage(dto.image),
        redirectionCTA:
            redirectionCTA?.label && redirectionCTA?.link
                ? {
                      label: redirectionCTA.label,
                      href: redirectionCTA.link,
                  }
                : undefined,
        legalMention: dto.legalMention
            ? {
                  prefix: dto.legalMention,
                  cta:
                      mentionCTA && mentionCTA.label && mentionCTA.link
                          ? {
                                label: mentionCTA.label,
                                href: mentionCTA.link,
                            }
                          : undefined,
              }
            : undefined,
    };
};

/** Main Banner **/
export type HomeMainBannerValue = HomeSlotBase &
    HomeBannerBase & {
        type: HomeSlotType.MainBanner;
        underBanners?: HomeUnderBannersValue;
    };

export const mapHomeMainBanner = (dto: HomeMosaicWindowsDTO): HomeMainBannerValue | null => {
    const mainBanner = dto.details.items.find((item) => item.type === 'MAIN');

    return mainBanner
        ? {
              type: HomeSlotType.MainBanner,
              ...mapHomeSlotBase(dto),
              ...mapHomeBannerBase(mainBanner, MAIN_BANNER),
              tags: mainBanner.tags?.reduce<CTA[]>((acc, curr) => {
                  if (curr?.label && curr?.link) {
                      acc.push({
                          label: curr.label,
                          href: curr.link,
                      });
                  }
                  return acc;
              }, []),
              underBanners: mapHomeUnderBanners(dto) ?? undefined,
          }
        : null;
};

/** Under Banner **/
export type HomeUnderBannersValue = HomeSlotBase & {
    type: HomeSlotType.UnderBanners;
    banners: HomeBannerBase[];
};

export const mapHomeUnderBanners = (dto: HomeUnderBannersDTO | HomeMosaicWindowsDTO): HomeUnderBannersValue | null => {
    // Check if it's underbanner 1/2 or 3/4
    const underBannerActionLabel = dto.type === HomeSlotDTOTypes.MozaicWindows.value ? 'main' : 'Footer';

    return dto.details.items.length > 0
        ? {
              type: HomeSlotType.UnderBanners,
              ...mapHomeSlotBase(dto),
              banners: dto.details.items
                  .filter(
                      (_, index) =>
                          dto.type === HomeSlotDTOTypes.UnderBanners.value || dto.details.items[index].type !== 'MAIN',
                  )
                  .map((banner, index) => {
                      const dataLayerBannerAction = `${underBannerActionLabel} under banner ${index + 1}`;
                      return {
                          ...mapHomeBannerBase(banner, dataLayerBannerAction),
                      };
                  }),
          }
        : null;
};
