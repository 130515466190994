import { PropsWithButton } from '@headless-workspace/glow-ds';
import { PredicateHelpers } from '@headless-workspace/utils';
import { ProductDetailBannerDTOTypes } from './ProductDetailBannerDTOCommon';
import { ProductDetailBannerDTO, ProductDetailBannersDTO } from './ProductDetailBannerDTOTypes';
import { FidelityBannerValue, mapFidelityBanner } from './fidelityBanner';
import { GWPBannerValue, mapGWPBanner } from './gwpBanner';
import { mapMarketingBanner, MarketingBannerValue } from './marketingBanner/MarketingBannerValue';

type ProductDetailBannerValue = (FidelityBannerValue | GWPBannerValue) & Partial<PropsWithButton>;

export type ProductDetailBannersValue = ProductDetailBannerValue[];

export const mapProductDetailBanner = (
    banner: ProductDetailBannerDTO,
): FidelityBannerValue | MarketingBannerValue | null => {
    switch (banner.type) {
        case ProductDetailBannerDTOTypes.Loyalty.value:
            return mapFidelityBanner(banner);
        case ProductDetailBannerDTOTypes.Marketing.value:
            return mapMarketingBanner(banner);
        case ProductDetailBannerDTOTypes.GiftWithPurchase.value:
            return mapGWPBanner(banner);
        default:
            return null;
    }
};

export const mapProductDetailBanners = (DTOBanners: ProductDetailBannersDTO): ProductDetailBannersValue => {
    const banners = DTOBanners.map((banner) => mapProductDetailBanner(banner));
    return banners.filter(PredicateHelpers.notNullOrUndefined) as ProductDetailBannersValue;
};
