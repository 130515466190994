import { CtaDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeProductRecommendationsDTO = z.infer<typeof HomeProductRecommendationsDTO>;
export const HomeProductRecommendationsDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.ProductRecommendations,
    details: z.object({
        title: z.string(),
        cta: CtaDTO,
        productsIds: z.array(z.string()),
    }),
});
