import { z } from 'zod';

export const PromoTextDTO = z.object({
    originalPriceText: z.string(),
    legalMentionOne: z.string(),
    legalMentionTwo: z.string(),
});

export const PromoStandardTextDTO = PromoTextDTO;

export type PromoStandardTextDTO = z.infer<typeof PromoStandardTextDTO>;
