import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { getIsNotifyMeEnabledServerAction } from '../../../../server';
import { NotifyMeErrors } from './NotifyMeErrors';

export const useIsNotifyMeEnabled = (): AsyncData<boolean> => {
    const t = useTranslations('Discover.Config.notifyMe.error');

    const {
        data: isNotifyMeEnabledData,
        isLoading: isNotifyMeEnabledLoading,
        error: isNotifyMeEnabledError,
    } = useSafeSWR(
        UserRevalidationKey.NotifyMe,
        ServerActionName.getIsNotifyMeEnabled,
        getIsNotifyMeEnabledServerAction,
    );

    if (!isNotifyMeEnabledData) {
        if (isNotifyMeEnabledError) {
            return {
                isLoading: isNotifyMeEnabledLoading,
                result: ResultFailure(
                    NotifyMeErrors.Default,
                    isNotifyMeEnabledError instanceof Error ? isNotifyMeEnabledError.message : t('default'),
                ),
            };
        }

        return { isLoading: isNotifyMeEnabledLoading, result: ResultEmpty() };
    }

    return {
        isLoading: isNotifyMeEnabledLoading,
        result: isNotifyMeEnabledData,
    };
};
