'use client';

import { useState } from 'react';
import { ServerActionName } from '@headless-workspace/config';
import { UserRevalidationKey, useSafeSWRMutation } from '@headless-workspace/domain/common/client';
import {
    getReviewsWithSortingServerAction,
    ProductDetailReviewsWithSortingValue,
    ProductReview,
} from '../../../../../src';

type UseFilteredReviewsProps = {
    baseReviews: ProductReview[];
    reviewCount: number;
    productId: string;
};

type UseFilteredReviewsValue = {
    onRatingChange: (rating: number) => Promise<void>;
    onResetSelectedRatings: () => Promise<void>;
    onOptionUpdate: (value: string) => Promise<void>;
    onSeeMoreReviewsClick: () => Promise<void>;
    selectedRatings: number[];
    displayedReviews: number;
    reviews: ProductReview[];
    isMutating: boolean;
};

const MORE_REVIEWS_BY_FETCH = 4;
const FILTER_FETCH_OFFSET = 0;
const DEFAULT_REVIEWS_DISPLAYED = 2;

export const useFilteredReviews = ({
    baseReviews,
    reviewCount,
    productId,
}: UseFilteredReviewsProps): UseFilteredReviewsValue => {
    const [selectedRatings, setSelectedRatings] = useState<number[]>([]);
    const [filterOption, setFilterOption] = useState('');
    const [reviews, setReviews] = useState<ProductReview[]>(baseReviews);
    const [displayedReviews, setDisplayedReviews] = useState<number>(DEFAULT_REVIEWS_DISPLAYED);

    const { trigger: fetchMoreReviews, isMutating } = useSafeSWRMutation<
        ProductDetailReviewsWithSortingValue,
        Parameters<typeof getReviewsWithSortingServerAction>
    >(
        UserRevalidationKey.UnusedKey,
        ServerActionName.getReviews,
        (_, { arg }) => {
            return getReviewsWithSortingServerAction(...arg);
        },
        (result) => {
            if (result.type === 'success') {
                setReviews(result.data.reviews);
            }
        },
    );

    const onRatingChange = async (rating: number) => {
        if (!isMutating) {
            const newlySelectedRatings = selectedRatings.includes(rating)
                ? [...selectedRatings].filter((r) => r !== rating)
                : [...selectedRatings, rating];

            setSelectedRatings(newlySelectedRatings);
            await fetchMoreReviews([
                productId,
                FILTER_FETCH_OFFSET,
                displayedReviews,
                filterOption,
                newlySelectedRatings,
            ]);
        }
    };

    const onOptionUpdate = async (value: string) => {
        if (!isMutating) {
            setFilterOption(value);
            await fetchMoreReviews([productId, FILTER_FETCH_OFFSET, displayedReviews, value, selectedRatings]);
        }
    };

    const onResetSelectedRatings = async () => {
        if (!isMutating) {
            const defaultRatings: number[] = [];
            setSelectedRatings(defaultRatings);
            await fetchMoreReviews([productId, FILTER_FETCH_OFFSET, displayedReviews, filterOption, defaultRatings]);
        }
    };

    const onSeeMoreReviewsClick = async () => {
        if (
            !isMutating &&
            displayedReviews < reviewCount &&
            reviews.length < displayedReviews + MORE_REVIEWS_BY_FETCH
        ) {
            // Fetch before setting displayed reviews as we use currently displayed reviews as an offset
            await fetchMoreReviews([productId, displayedReviews, MORE_REVIEWS_BY_FETCH, filterOption, selectedRatings]);
        }
        setDisplayedReviews((prevDisplay) => Math.min(prevDisplay + MORE_REVIEWS_BY_FETCH, reviewCount));
    };

    return {
        onRatingChange,
        onOptionUpdate,
        onResetSelectedRatings,
        onSeeMoreReviewsClick,
        selectedRatings,
        displayedReviews,
        reviews,
        isMutating,
    };
};
