import React, { useMemo, useState } from 'react';
import { UIStoreInfoCard } from '@headless-workspace/core-ui';
import { GlowClickable, GlowTextBody, GlowTextBodySmall, GlowTextCaption } from '@headless-workspace/glow-ds';
import { Component, DeliveryMethod } from '@headless-workspace/typings';
import { ArrayHelpers } from '@headless-workspace/utils';
import { StoreInfo } from '../../../storesAndServices';

const PropsTest = {
    store: {
        id: 'S5181',
        name: 'THIAIS BELLE EPINE - RDC',
        isSelected: false,
        favoriteLabel: 'Favorite store',
        addressText: '1 Rue du Luxembourg, 94320 Thiais',
        distanceText: '4.5 KM',
        productAvailability: {
            isAvailable: true,
            text: 'Product available',
        },
        shippingMethodInfo: {
            groupId: DeliveryMethod.clickandcollect,
            freeLabel: 'Free',
            deliveryMessage: '2-hour pickup',
        },
        scheduleInfo: {
            openStatusTitle: 'Open', // StoreHelpers.getStoreOpeningInfo().isOpenNow ? 'Open' : 'Closed',
            openStatusText: 'today close at 19:00', // StoreHelpers.getStoreOpeningInfo().nextOpeningText,
            openHours: [
                // FormatHelpers.formatOpenHours()
                { day: 'Thursday', hours: '09:00 - 18:00' },
                { day: 'Friday', hours: '09:00 - 18:00' },
                { day: 'Saturday', hours: '10:00 - 19:00' },
                { day: 'Sunday', hours: 'Closed' },
                { day: 'Monday', hours: '10:00 - 18:00' },
                { day: 'Tuesday', hours: '09:00 - 18:00' },
                { day: 'Wednesday', hours: '09:00 - 18:00' },
            ],
        },
    },
    onSelect: () => alert('Store selected!'),
};

function splitByAvailableStock(data: StoreInfo[], favoriteStoreId: string, selectedStoreId?: string) {
    return data.reduce(
        (acc, item) => {
            if (item.isAvailable) {
                if (item.id === favoriteStoreId || item.id === selectedStoreId) {
                    acc.availableStock.unshift(item); // Ensure favorite store  or selected store is first
                } else {
                    acc.availableStock.push(item);
                }
            } else {
                acc.unavailableStock.push(item);
            }
            return acc;
        },
        { availableStock: [] as StoreInfo[], unavailableStock: [] as StoreInfo[] },
    );
}

type StoresListProps = {
    favoriteStoreId: string;
    selectedStore?: StoreInfo;
    stores: StoreInfo[];
    stockDisclaimer: string;
    listTitle: string;
    listCaption: string;
    seeMore: string;
    handleSelectedStore: (storeId: StoreInfo) => void;
};

export const StoresList: Component<StoresListProps> = ({
    favoriteStoreId,
    handleSelectedStore,
    selectedStore,
    stockDisclaimer,
    stores,
    listCaption,
    listTitle,
    seeMore,
}) => {
    const [isMoreStoresVisible, setIsMoreStoresVisible] = useState(false);

    const { availableStock, unavailableStock } = useMemo(
        () => splitByAvailableStock(stores, favoriteStoreId, selectedStore?.id),
        [stores, favoriteStoreId, selectedStore],
    );

    const getStoreProps = (store: StoreInfo) => ({
        ...PropsTest,
        favoriteLabel: store.id === favoriteStoreId ? favoriteStoreId : '',
    });

    const handleClickMoreStoresVisible = () => {
        setIsMoreStoresVisible(!isMoreStoresVisible);
    };

    const onSelectedStore = (store: StoreInfo) => () => {
        handleSelectedStore(store);
    };

    return (
        <>
            <div>
                <GlowTextBody text={listTitle} fontWeight={'bold'} />
                {listCaption && <GlowTextBodySmall text={listCaption} color={'tertiary'} />}
            </div>

            {availableStock.map((store) => {
                return <UIStoreInfoCard key={store.id} {...getStoreProps(store)} onSelect={onSelectedStore(store)} />;
            })}
            {!isMoreStoresVisible && ArrayHelpers.isNotEmpty(unavailableStock) ? (
                <GlowClickable
                    content={{
                        labelElement: <GlowTextBody text={seeMore} textDecoration={'underline'} />,
                    }}
                    onClick={handleClickMoreStoresVisible}
                    className={'p-1 mx-auto'}
                />
            ) : (
                <>
                    {unavailableStock.map((store) => {
                        return <UIStoreInfoCard key={store.id} {...PropsTest} />;
                    })}
                </>
            )}
            <GlowTextCaption text={stockDisclaimer} color={'tertiary'} className={'mt-auto'} />
        </>
    );
};
