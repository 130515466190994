'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { GlowTextBody, GlowTextBodySmall, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { VIP_BORDER_CLASSES } from './ProductPushLoyalty';
import styles from './loyalty.module.css';

type PushLoyaltyModalProps = PropsWithLocale & {
    loyaltyPoints: string;
    loyaltyPointsPerCurrency: number;
    currency: string;
    isVipBrand: boolean;
};

export const PushLoyaltyModal: Component<PushLoyaltyModalProps> = ({
    loyaltyPoints,
    loyaltyPointsPerCurrency,
    locale,
    currency,
    isVipBrand,
}) => {
    const t = useTranslations('Discover.Pdp.loyalty');
    const fidPointRulesLabel = t('label.fidPointRules', {
        value: StringHelpers.formatPrice(loyaltyPointsPerCurrency, currency, locale, true),
    });

    return (
        <div className={'flex flex-col gap-1'}>
            <GlowTextBody fontSize={'title5'} text={t('label.fidContent')} fontWeight={'bold'} />
            <GlowTextBody text={fidPointRulesLabel} />

            <div
                className={clsx(
                    'flex flex-row gap-1',
                    isVipBrand ? VIP_BORDER_CLASSES : styles.customGradientFid,
                    'rounded-0.5 p-1.5',
                )}
            >
                <GlowTextBody
                    className={'px-0.5 bg-background-neutral rounded-0.25 justify-center'}
                    text={loyaltyPoints}
                    fontWeight={'bold'}
                />
                <GlowTextBodySmall className={'flex flex-col justify-center'} text={t('label.fidAddToAccount')} />
            </div>
        </div>
    );
};
