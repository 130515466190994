'use client';

import { tailwindTheme } from '@headless-workspace/theme';

const IFRAME_ID = 'sephora-booxi-iframe';

const BOOK_NOW_DEFAULT_OPTIONS = {
    showIcon: false,
    useDefaultButton: false,
    locationCategory: 'required',
    primaryColor:
        (tailwindTheme.colors as { [key: string]: string })['surface-promotion'] || 'var(--surface-promotion)',
};

export const bookNow = (
    apiKey: string,
    language: string,
    bookingFlow = 'locations',
    serviceTags = '',
    iframeId = IFRAME_ID
) => {
    window.BookNow.open({
        ...BOOK_NOW_DEFAULT_OPTIONS,
        apiKey,
        language,
        iframeId,
        serviceTags,
        bookingFlow,
    });
};
