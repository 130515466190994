export * from './productRecommendations';
export * from './delivery';
export * from './breadcrumb';
export * from './ProductDetailTemplate';
export * from './brandCorner';
export * from './ProductDetailFooterSection';
export * from './ratingsAndReviews';
export * from './ProductDetailDTO';
export * from './ProductDetailValue';
export * from './ProductDetailVariantValue';
export * from './ProductInfoTabParamsValue';
export * from './CapacityFormat';
export * from './banner';
