import { HTMLString } from '@headless-workspace/domain/common/server';
import { DateHelpers } from '@headless-workspace/utils';
import { PersistentBannerSlotDTO } from './PersistentBannerSlotDTO';

export const DEFAULT_BG_COLOR = '';

export type PersistentBannerSlotValue = Readonly<{
    text: HTMLString;
    backgroundColor: string;
    linkLabel?: string;
    href?: string;
    endOfCountDownDateISO: string | null;
}>;

export const mapPersistentBannerSlot = (dto: PersistentBannerSlotDTO): PersistentBannerSlotValue | undefined => {
    if (!dto.details.cta?.label && !dto.details.subTitle) {
        return;
    }

    return {
        text: HTMLString(dto.details.subTitle ?? ''),
        backgroundColor: dto.details.backgroundColor ?? DEFAULT_BG_COLOR,
        linkLabel: dto.details.cta?.label,
        href: dto.details.cta?.link,
        endOfCountDownDateISO: dto.details.endOfCountDown
            ? DateHelpers.formatToISODate(dto.details.endOfCountDown)
            : null,
    };
};
