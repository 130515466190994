import { ReviewsSortOption } from '@headless-workspace/config';
import { UserSephoraCard } from '@headless-workspace/domain/common/server';
import {
    ProductDetailReviewsWithSortingValue,
    ReviewPurchaserType,
} from '../../../../server/lib/productDetail/ratingsAndReviews/ProductDetailReviewValue';

export const ReviewsMock: ProductDetailReviewsWithSortingValue = {
    reviews: [
        {
            id: 'review-1',
            rating: 4,
            createdAt: new Date('2023-07-22'),
            userName: 'Kdubos73',
            userGender: 'Femme',
            userAge: '45 - 54 ans',
            userCard: UserSephoraCard.Black,
            purchaserType: ReviewPurchaserType.VERIFIED,
            purchaseProductSince: '1 semaine',
            title: "J'adore Dior",
            content:
                "J'adore la fragrance. C’est un parfum doux fleuries il me colle à la peau. Le produit est bon et naturel. Mais le bouchon qui sert à sortir le parfum est très difficile à enlever. Il faut vraiment forcer pour le sortir. C’est dommage. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum tortor eget risus imperdiet finibus. In nibh turpis, venenatis eu justo sit amet, viverra malesuada ligula. Vivamus placerat ligula in augue vulputate rhoncus. Ut in elit dolor. In hac habitasse platea dictumst. Mauris sodales odio ac tortor elementum, id ornare massa efficitur.",
            isRecommended: true,
            source: {
                label: 'Dior',
                href: 'https://www.dior.com/',
            },
            vote: {
                like: 145,
                dislike: 145,
            },
        },
        {
            id: 'review-2',
            rating: 0.5,
            createdAt: new Date('2023-01-10'),
            userName: 'AnnaBerg',
            purchaserType: ReviewPurchaserType.TESTER,
            purchaseProductSince: '2 semaines',
            title: 'Nicht mein Fall',
            language: 'de-DE',
            isRecommended: false,
            vote: {
                like: 210,
                dislike: 15,
            },
        },
        {
            id: 'review-3',
            rating: 3.5,
            createdAt: new Date('2022-03-15'),
            userName: 'CarlosM92',
            userGender: 'Hombre',
            userAge: '35 - 44 ans',
            userCard: UserSephoraCard.Gold,
            purchaserType: ReviewPurchaserType.UNCONTROLLED,
            purchaseProductSince: '1 mois',
            title: 'Buen aroma, pero caro',
            content:
                'El perfume tiene un aroma muy agradable y duradero, pero sinceramente creo que el precio es demasiado alto para lo que ofrece. Es bueno, pero no excelente.',
            language: 'es-ES',
            isRecommended: false,
            source: {
                label: 'El Corte Inglés',
                href: 'https://www.elcorteingles.es/',
            },
            vote: {
                like: 67,
                dislike: 42,
            },
        },
        {
            id: 'review-4',
            rating: 4.2,
            createdAt: new Date('2021-06-06'),
            userName: 'LuciaF89',
            userGender: 'Donna',
            userAge: '45 - 54 ans',
            userCard: UserSephoraCard.White,
            purchaserType: ReviewPurchaserType.TESTER,
            purchaseProductSince: '3 ans',
            title: 'Elegante e sofisticato',
            content:
                'Adoro questo profumo! È molto sofisticato e perfetto per ogni occasione. Anche la confezione è bellissima. Lo consiglio vivamente a chi cerca qualcosa di speciale.',
            language: 'it-IT',
            isRecommended: true,
            source: {
                label: 'Dior Italia',
                href: 'https://www.dior.com/it_it',
            },
            vote: {
                like: 189,
                dislike: 12,
            },
        },
        {
            id: 'review-5',
            rating: 1.2,
            createdAt: new Date('2022-05-06'),
            userName: 'LuciaF89',
            userGender: 'Donna',
            userAge: '45 - 54 ans',
            userCard: UserSephoraCard.White,
            purchaserType: ReviewPurchaserType.TESTER,
            purchaseProductSince: '3 ans',
            title: 'Elegante e sofisticato',
            content:
                'Adoro questo profumo! È molto sofisticato e perfetto per ogni occasione. Anche la confezione è bellissima. Lo consiglio vivamente a chi cerca qualcosa di speciale.',
            language: 'it-IT',
            isRecommended: true,
            source: {
                label: 'Dior Italia',
                href: 'https://www.dior.com/it_it',
            },
            vote: {
                like: 189,
                dislike: 12,
            },
        },
        {
            id: 'review-6',
            rating: 2.2,
            createdAt: new Date('2022-03-06'),
            userName: 'LuciaF89',
            userGender: 'Donna',
            userAge: '45 - 54 ans',
            userCard: UserSephoraCard.White,
            purchaserType: ReviewPurchaserType.TESTER,
            purchaseProductSince: '3 ans',
            title: 'Elegante e sofisticato',
            content:
                'Adoro questo profumo! È molto sofisticato e perfetto per ogni occasione. Anche la confezione è bellissima. Lo consiglio vivamente a chi cerca qualcosa di speciale.',
            language: 'it-IT',
            isRecommended: true,
            source: {
                label: 'Dior Italia',
                href: 'https://www.dior.com/it_it',
            },
            vote: {
                like: 189,
                dislike: 12,
            },
        },
        {
            id: 'review-7',
            rating: 4.9,
            createdAt: new Date('2022-01-22'),
            userName: 'Kdubos73',
            userGender: 'Femme',
            userAge: '45 - 54 ans',
            userCard: UserSephoraCard.Black,
            purchaserType: ReviewPurchaserType.VERIFIED,
            purchaseProductSince: '1 semaine',
            title: "J'adore Dior",
            content:
                "J'adore la fragrance. C’est un parfum doux fleuries il me colle à la peau. Le produit est bon et naturel. Mais le bouchon qui sert à sortir le parfum est très difficile à enlever. Il faut vraiment forcer pour le sortir. C’est dommage. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum tortor eget risus imperdiet finibus. In nibh turpis, venenatis eu justo sit amet, viverra malesuada ligula. Vivamus placerat ligula in augue vulputate rhoncus. Ut in elit dolor. In hac habitasse platea dictumst. Mauris sodales odio ac tortor elementum, id ornare massa efficitur.",
            isRecommended: true,
            source: {
                label: 'Dior',
                href: 'https://www.dior.com/',
            },
            vote: {
                like: 145,
                dislike: 145,
            },
        },
    ],
    sortingOption: [
        {
            id: 'les plus récents',
            value: 'les plus récents',
            tag: ReviewsSortOption.MostRecent,
        },
        {
            id: 'les moins récent',
            value: 'les moins récents',
            tag: ReviewsSortOption.LeastRecent,
        },
        {
            id: 'les mieux notés',
            value: 'les mieux notés',
            tag: ReviewsSortOption.BestRated,
        },
        {
            id: 'les moins bien notés',
            value: 'les moins bien notés',
            tag: ReviewsSortOption.WorstRated,
        },
    ],
    ratingFilter: [
        { ratingValue: 5, reviewsCountPerRating: 500, progressValue: (500 * 100) / 1070 },
        { ratingValue: 4, reviewsCountPerRating: 300, progressValue: (300 * 100) / 1070 },
        { ratingValue: 3, reviewsCountPerRating: 200, progressValue: (200 * 100) / 1070 },
        { ratingValue: 2, reviewsCountPerRating: 50, progressValue: (50 * 100) / 1070 },
        { ratingValue: 1, reviewsCountPerRating: 20, progressValue: (20 * 100) / 1070 },
    ],
};
