'use client';

import { HeaderConnectionBannerItemValue } from '@headless-workspace/core-domain';
import { Component } from '@headless-workspace/typings';
import { DynamicConnectionBanner } from './DynamicConnectionBanner';
import { StaticConnectionBanner } from './StaticConnectionBanner';

export type ConnectionBannerItemWrapperProps = HeaderConnectionBannerItemValue & {
    connectionDataLayer?: string;
};

export const ConnectionBannerItemWrapper: Component<ConnectionBannerItemWrapperProps> = ({
    dynamicOverlayIcons,
    dynamicHref,
    ...props
}) => {
    const dynamicData = dynamicHref ?? dynamicOverlayIcons;
    return dynamicData ? (
        <DynamicConnectionBanner dynamicData={dynamicData} {...props} />
    ) : (
        <StaticConnectionBanner {...props} />
    );
};
