import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { ProductDetailBannerDTOTypes } from '../ProductDetailBannerDTOCommon';

export type MarketingBannerDTO = z.infer<typeof MarketingBannerDTO>;
export const MarketingBannerDTO = z.object({
    type: ProductDetailBannerDTOTypes.Marketing,
    id: z.string(),
    title: z.string().optional(),
    description: z.string().optional(),
    legalMention: z.string().optional(),
    image: ImageDTO,
    cta: z.object({
        label: z.string(),
        link: z.string(),
    }),
});
