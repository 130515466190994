'use client';

import { useTranslations } from 'next-intl';
import { GlowTextBody, PropsWithFontFamily, typographyStyle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductInfoTabParamsValue } from '../../../../server';
import { ProductInfoCTA } from './ProductInfoCTA';
import { ProductInfoDescription } from './ProductInfoDescription';

type ProductInfoSectionProps = PropsWithFontFamily & {
    productInfoTabParams: ProductInfoTabParamsValue[];
};

export const DESCRIPTION_TAB_ID = 'description';

export const ProductInfoSection: Component<ProductInfoSectionProps> = ({ productInfoTabParams, fontFamily }) => {
    const t = useTranslations();

    const renderTabContent = (productInfoTabParam: ProductInfoTabParamsValue) => {
        let labelElement = (
            <GlowTextBody
                fontFamily={fontFamily}
                text={t(productInfoTabParam.label)}
                fontWeight={'bold'}
                TagName={'span'}
            />
        );

        if (productInfoTabParam.id === DESCRIPTION_TAB_ID && productInfoTabParam.displayContentInProductPage) {
            labelElement = (
                <GlowTextBody
                    text={t('Discover.Pdp.productInfo.productTab.action.readMore')}
                    textDecoration={'underline'}
                    fontFamily={fontFamily}
                    TagName={'span'}
                />
            );

            return (
                productInfoTabParam.value && (
                    <div className={'flex flex-col gap-1 pb-1'}>
                        <ProductInfoDescription
                            value={productInfoTabParam.value}
                            label={t(productInfoTabParam.label)}
                            fontFamily={fontFamily}
                        />
                        <ProductInfoCTA
                            productTabs={productInfoTabParams}
                            openTabWithId={productInfoTabParam.id}
                            labelElement={labelElement}
                            isHyperText={true}
                            fontFamily={fontFamily}
                        />
                    </div>
                )
            );
        }

        return (
            <div className={'border-t-border-primary border-t-medium border-solid'}>
                <ProductInfoCTA
                    productTabs={productInfoTabParams}
                    openTabWithId={productInfoTabParam.id}
                    labelElement={labelElement}
                    isHyperText={false}
                    fontFamily={fontFamily}
                />
            </div>
        );
    };

    return (
        productInfoTabParams && (
            <ul className={'gap-0.5 scrollbar-hide overflow-x-scroll max-w-full whitespace-nowrap'}>
                {productInfoTabParams.map((productTabParam) => (
                    <li id={productTabParam.id} key={productTabParam.id} className={productTabParam.className}>
                        {renderTabContent(productTabParam)}
                    </li>
                ))}
            </ul>
        )
    );
};
