'use client';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { AsyncDataWithoutResult } from '@headless-workspace/core-domain';
import { GlowButton, GlowDivider, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FunctionHelpers } from '@headless-workspace/utils';
import {
    CtaType,
    ProductDetailDeliveryInfoValue,
    ProductDetailInfoCTA,
    ProductDetailInfoModal,
    StoreInfo,
    useFavoriteStoreInfo,
} from '../../../../../src';
import { mapToChipColor } from './ChipColor';
import { DeliveryInformation } from './DeliveryInformation';
import { ProductDetailDeliveryInfo } from './ProductDetailDeliveryInfo';
import { StoresDeliveryContainer } from './StoresDeliveryContainer';
import { useDeliveryZoneBusinessRules } from './useDeliveryZoneBusinessRules';

type ProductDetailDeliveryZoneContainerProps = PropsWithLocale & {
    stock: number;
    productDeliveryInfoData: AsyncDataWithoutResult<ProductDetailDeliveryInfoValue>;
    isNotifyMeEnabledData: AsyncDataWithoutResult<boolean>;
    productId: string;
};

export const ProductDetailDeliveryZoneContainer: Component<ProductDetailDeliveryZoneContainerProps> = ({
    stock,
    productDeliveryInfoData,
    isNotifyMeEnabledData,
    productId,
    locale,
}) => {
    const t = useTranslations('Discover.Pdp.deliveryZone');

    const { isLoading: isFavoriteStoreLoading, result: favoriteStoreResult } = useFavoriteStoreInfo();

    const [selectedStore, setSelectedStore] = useState<StoreInfo | undefined>(undefined);

    useEffect(() => {
        if (!isFavoriteStoreLoading && favoriteStoreResult.type === 'success') {
            setSelectedStore(favoriteStoreResult.data);
        }
    }, [isFavoriteStoreLoading, favoriteStoreResult]);

    const { shipping, store } = useDeliveryZoneBusinessRules({
        stock,
        productDeliveryInfoData,
        isNotifyMeEnabledData,
        locale,
    });

    const shippingCta = useMemo(() => {
        const ctaType = shipping.data?.ctaType;

        switch (ctaType) {
            case CtaType.NotifyMe:
                return (
                    <GlowButton
                        label={t('action.notifyMe')}
                        onClick={FunctionHelpers.emptyFn}
                        Icon={Icons.Notification}
                        variant={'secondary'}
                    />
                );
            default:
                return undefined;
        }
    }, [shipping.data?.ctaType, t]);

    const storeCta = useMemo(() => {
        const ctaType = store.data?.ctaType;

        switch (ctaType) {
            case CtaType.SelectStore:
                return (
                    <ProductDetailInfoCTA
                        menu={
                            <ProductDetailInfoModal
                                title={t('stores.title')}
                                closeButtonLabel={t('stores.action')}
                                renderInformation={
                                    <StoresDeliveryContainer
                                        favoriteStoreId={selectedStore?.id}
                                        onSelectedStoreChange={setSelectedStore}
                                        productId={productId}
                                        locale={locale}
                                    />
                                }
                            />
                        }
                        buttonElement={(onClick) => (
                            <GlowButton label={t('action.selectStore')} onClick={onClick} variant={'secondary'} />
                        )}
                    />
                );
            case CtaType.ChangeStore:
                return (
                    <GlowButton
                        label={t('action.changeStore')}
                        onClick={FunctionHelpers.emptyFn}
                        variant={'secondary'}
                    />
                );
            case CtaType.NotifyMe:
                return (
                    <GlowButton
                        label={t('action.notifyMe')}
                        onClick={FunctionHelpers.emptyFn}
                        Icon={Icons.Notification}
                        variant={'secondary'}
                    />
                );
            default:
                return undefined;
        }
    }, [selectedStore, store.data?.ctaType, t]);

    const isDataUnavailable = !productDeliveryInfoData.data || !shipping.data || !store.data;

    if (isDataUnavailable) {
        return null;
    }

    return (
        <div
            id={'product-delivery-zone'}
            className={'flex flex-col gap-1.5 border-medium border-solid border-border-primary rounded-0.5'}
        >
            <DeliveryInformation
                Icon={Icons.Truck}
                title={t('label.shippingOrRelayPoint')}
                isLoading={shipping.isLoading}
                description={shipping.data.description}
                chipColor={mapToChipColor(shipping.data.status)}
                statusLabel={shipping.data.statusLabel}
                cta={shippingCta}
                className={'pt-1.5'}
            />
            <GlowDivider />
            <DeliveryInformation
                Icon={Icons.Storefront}
                title={t('label.inStorePickup')}
                isLoading={store.isLoading}
                description={store.data.description}
                chipColor={mapToChipColor(store.data.status)}
                statusLabel={store.data.statusLabel}
                cta={storeCta}
            />
            <GlowDivider />
            <ProductDetailInfoCTA
                menu={
                    <ProductDetailInfoModal
                        title={productDeliveryInfoData.data.termsAndConditions.title}
                        closeButtonLabel={productDeliveryInfoData.data.termsAndConditions.ctaLabel}
                        renderInformation={
                            <ProductDetailDeliveryInfo deliveryInfo={productDeliveryInfoData.data.termsAndConditions} />
                        }
                        contentContainerClassName={'p-1.5 gap-1'}
                    />
                }
                labelElement={t('action.deliveryAndReturns')}
                className={'px-1.5 pb-1.5 text-text-primary'}
            />
        </div>
    );
};
