import clsx from 'clsx';
import { BannerType, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeBanner, HomeUnderBannersValue } from '../../../../../src';

type HomeUnderBannersProps = PropsWithClassName & {
    underBanners: HomeUnderBannersValue;
    bannerType?: BannerType;
};

export const HomeUnderBanners: Component<HomeUnderBannersProps> = ({
    className,
    underBanners,
    bannerType = BannerType.Under,
}) => {
    const TagName = bannerType === BannerType.Main ? 'div' : 'section';

    return (
        <TagName
            className={clsx(
                'flex flex-col desktop:flex-row tablet:flex-wrap',
                'gap-1.5 px-1 desktop:px-1.5',
                className,
            )}
        >
            {underBanners.banners.map((banner, index) => {
                return <HomeBanner key={`banner-${index}`} banner={banner} type={underBanners.type} />;
            })}
        </TagName>
    );
};
