import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { ProductDetailBannerDTOTypes } from '../ProductDetailBannerDTOCommon';

// FIXME: to be adjusted when unmock
export const FidelityBannerDTO = z.object({
    type: ProductDetailBannerDTOTypes.Loyalty.optional(),
    id: z.string(),
    title: z.string().optional(),
    description: z.string().optional(),
    legalMention: z.string().optional(),
    image: ImageDTO,
});

export type FidelityBannerDTO = z.infer<typeof FidelityBannerDTO>;
