'use client';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import {
    PRODUCT_DETAIL_VISUAL_MODAL_ID,
    DEFAULT_ZOOM_LVL,
    DEFAULT_HIGH_ZOOM_LVL,
    ZOOM_DELTA_LVL,
} from '@headless-workspace/config';
import { UIContext, UIFullScreenMobileCarousel, useCarouselLogic } from '@headless-workspace/core-ui';
import { MediaValue, isImage, isVideo } from '@headless-workspace/domain/common/client';
import { GlowButton, GlowClickable, GlowIcon, GlowImage, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { YoutubeScriptProvider } from '../../providers';
import { ProductDetailVisualVideo, YT_PLAYER_STATES } from './ProductDetailVisualVideo';
import { ProductDetailZoomableImage } from './ProductDetailZoomableImage';

export type ProductDetailVisualModalProps = {
    medias: MediaValue[];
    activeIndex: number;
};

const TARGET_ORIGIN = 'https://www.youtube.com';

export const ProductDetailVisualModal: Component<ProductDetailVisualModalProps> = ({ medias, activeIndex }) => {
    const t = useTranslations('Discover.Pdp.carousel');
    const [videoStates, setVideoStates] = useState<Record<string, YT.PlayerState>>({});

    const {
        menuModal: { close: closeMenu, sideMenuModalList: modalList },
    } = useContext(UIContext);

    const [activeVisual, setActiveVisual] = useState(activeIndex);
    const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LVL);
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);

    const isZoomOutDisabled = zoomLevel <= DEFAULT_ZOOM_LVL;
    const isZoomInDisabled = zoomLevel >= DEFAULT_HIGH_ZOOM_LVL;
    const isMenuModalOpen = modalList[PRODUCT_DETAIL_VISUAL_MODAL_ID]?.isOpen ?? false;

    const options = {
        loop: true,
        startIndex: activeVisual,
    };

    useEffect(() => {
        if (isMenuModalOpen) {
            setActiveVisual(activeIndex);
        }
    }, [isMenuModalOpen, activeIndex]);

    const {
        emblaRef: emblaRefCarousel,
        emblaApi: emblaApiCarousel,
        prevBtnDisabled: prevBtnDisabledCarousel,
        nextBtnDisabled: nextBtnDisabledCarousel,
        onPrevButtonClick: onPrevButtonClickCarousel,
        onNextButtonClick: onNextButtonClickCarousel,
        selectedIndex: selectedIndexCarousel,
    } = useCarouselLogic(options);

    const {
        emblaRef: emblaRefThumbnail,
        prevBtnDisabled: prevBtnDisabledThumbnail,
        nextBtnDisabled: nextBtnDisabledThumbnail,
        onPrevButtonClick: onPrevButtonClickThumbnail,
        onNextButtonClick: onNextButtonClickThumbnail,
        selectedIndex: selectedIndexThumbnail,
    } = useCarouselLogic(options);

    const controlVideo = (media?: MediaValue) => {
        if (!media) {
            return;
        }

        const iframe = document.getElementById(`${media.id}`) as HTMLIFrameElement;

        setIsVideoPlaying((prevIsVideoPlaying) => {
            const contentWindow = iframe?.contentWindow;
            if (contentWindow) {
                contentWindow.postMessage(
                    JSON.stringify({
                        event: 'command',
                        func: isVideoPlaying ? 'pauseVideo' : 'playVideo',
                        args: [],
                    }),
                    TARGET_ORIGIN,
                );
                return !prevIsVideoPlaying;
            } else {
                return prevIsVideoPlaying;
            }
        });
    };

    const onProductVisualThumbClick =
        (index: number, media?: MediaValue, toggle = false) =>
        () => {
            if (toggle) {
                controlVideo(media);
            } else {
                setActiveVisual(index);
                setZoomLevel(DEFAULT_ZOOM_LVL);
                setIsVideoPlaying(true);
            }
        };

    useEffect(() => {
        if (selectedIndexCarousel !== activeVisual) {
            onProductVisualThumbClick(selectedIndexCarousel)();
        }
    }, [selectedIndexCarousel]);

    useEffect(() => {
        if (selectedIndexThumbnail !== activeVisual) {
            onProductVisualThumbClick(selectedIndexThumbnail)();
        }
    }, [selectedIndexThumbnail]);

    const handleZoom = (delta: number) => () => {
        setZoomLevel((prevZoom) => Math.min(DEFAULT_HIGH_ZOOM_LVL, Math.max(DEFAULT_ZOOM_LVL, prevZoom + delta)));
    };

    const onProductVisualClickCloseModal = useCallback(() => {
        closeMenu(PRODUCT_DETAIL_VISUAL_MODAL_ID);
    }, [closeMenu]);

    const sharedCarouselProps = useMemo(
        () => ({
            items: medias,
            prevBtnAriaLabel: t('ariaLabel.prevBtn'),
            nextBtnAriaLabel: t('ariaLabel.nextBtn'),
            thumbArialLabel: t('ariaLabel.thumb'),
            showDot: false,
        }),
        [medias, t],
    );
    const onVideoStateChange = (index: number) => (state: YT.PlayerState) => {
        setVideoStates((prevStates) => ({
            ...prevStates,
            [index]: state,
        }));
    };

    return (
        <div className={'w-fullViewport desktopS:w-full bg-background-l0 relative p-1 flex flex-col h-fullViewport'}>
            <YoutubeScriptProvider>
                <GlowClickable
                    className={'flex w-full justify-end'}
                    onClick={onProductVisualClickCloseModal}
                    ariaLabel={t('modal.closeBtn')}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                    }}
                />

                <div className={'flex flex-col flex-1 items-center justify-center gap-0.5'}>
                    <UIFullScreenMobileCarousel
                        {...sharedCarouselProps}
                        className={
                            'w-productModal tablet:w-productModalTablet desktopS:w-productModalDesktopS desktop:w-productModalDesktop gap-0.5 relative'
                        }
                        renderItem={(media, index) =>
                            isImage(media) ? (
                                <ProductDetailZoomableImage
                                    key={media.url}
                                    image={media}
                                    emblaApi={emblaApiCarousel}
                                    zoomLevel={isVideo(media) ? 1 : zoomLevel}
                                />
                            ) : (
                                <ProductDetailVisualVideo
                                    onStateChange={onVideoStateChange(index)}
                                    media={media}
                                    isDisplayed={isMenuModalOpen && activeVisual === index}
                                />
                            )
                        }
                        emblaRef={emblaRefCarousel}
                        prevBtnDisabled={prevBtnDisabledCarousel}
                        nextBtnDisabled={nextBtnDisabledCarousel}
                        onPrevButtonClick={onPrevButtonClickCarousel}
                        onNextButtonClick={onNextButtonClickCarousel}
                        selectedIndex={selectedIndexCarousel}
                        variant={'tertiary'}
                        navigationButtonClassName={'hidden desktopS:flex'}
                    />

                    {!isVideo(medias[activeVisual]) && (
                        <div className={'flex gap-x-0.5 justify-center'}>
                            <GlowButton
                                variant={isZoomOutDisabled ? 'disabled' : 'tertiary'}
                                onClick={handleZoom(-ZOOM_DELTA_LVL)}
                                disabled={isZoomOutDisabled}
                                Icon={Icons.ZoomOut}
                                ariaLabel={t('ariaLabel.zoomOut')}
                            />
                            <GlowButton
                                onClick={handleZoom(ZOOM_DELTA_LVL)}
                                disabled={isZoomInDisabled}
                                Icon={Icons.ZoomIn}
                                ariaLabel={t('ariaLabel.zoomIn')}
                                variant={isZoomInDisabled ? 'disabled' : 'tertiary'}
                            />
                        </div>
                    )}
                </div>

                <UIFullScreenMobileCarousel
                    {...sharedCarouselProps}
                    className={'w-productThumb tablet:w-auto justify-center gap-0.25'}
                    renderItem={(media, index) => {
                        return (
                            <GlowClickable
                                key={media.url}
                                onClick={onProductVisualThumbClick(index, media, activeVisual === index)}
                                className={clsx(
                                    'relative w-productModalThumbImg aspect-1/1 group border-solid border-bold hover:border-border-selected cursor-pointer rounded-0.25 overflow-hidden',
                                    activeVisual === index
                                        ? 'opacity-100 border-border-selected'
                                        : 'opacity-65 hover:opacity-100 border-border-primary',
                                )}
                                content={{
                                    icon: (
                                        <>
                                            <GlowImage
                                                image={media}
                                                className={
                                                    'flex items-center justify-center w-productModalThumbImg aspect-1/1 cursor-pointer'
                                                }
                                                imgClassName={clsx(
                                                    'object-cover aspect-1/1 ease-in duration-moderate3 group-hover:w-productModalAnimatedThumbImg',
                                                    activeVisual === index
                                                        ? 'w-productModalAnimatedThumbImg rounded-0.125'
                                                        : 'w-productModalThumbImg',
                                                )}
                                            />
                                            {isVideo(media) && (
                                                <div
                                                    className={clsx(
                                                        'absolute top-0 left-0 w-full aspect-1/1 flex items-center justify-center rounded-0.25 ease-in duration-moderate3',
                                                        !(activeVisual === index) &&
                                                            // FIXME : The issue with Tailwind will be resolved in the next version
                                                            'bg-background-overlay group-hover:bg-[transparent] group-hover:bg-opacity-0',
                                                    )}
                                                >
                                                    <GlowIcon
                                                        Icon={
                                                            [
                                                                YT_PLAYER_STATES.PLAYING,
                                                                YT_PLAYER_STATES.BUFFERING,
                                                            ].includes(videoStates[index])
                                                                ? Icons.Pause
                                                                : Icons.Play
                                                        }
                                                        type={'small'}
                                                        className={'text-text-primary-inverse'}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                    prevBtnDisabled={prevBtnDisabledThumbnail}
                    nextBtnDisabled={nextBtnDisabledThumbnail}
                    onPrevButtonClick={onPrevButtonClickThumbnail}
                    onNextButtonClick={onNextButtonClickThumbnail}
                    selectedIndex={selectedIndexThumbnail}
                    emblaRef={emblaRefThumbnail}
                    variant={'tertiary'}
                />
            </YoutubeScriptProvider>
        </div>
    );
};
