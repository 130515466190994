import { DEFAULT_LOCALE, Locale } from '@headless-workspace/config';
import { LanguageHelpers } from '@headless-workspace/utils';
import { FreeShippingPriceDTO } from '.';

export type FreeShippingPriceValue = Readonly<{
    minFreeShippingPrice: number;
    maxFreeShippingPrice: number;
}>;

const DEFAULT_FREE_SHIPPING_PRICE: FreeShippingPriceValue = {
    minFreeShippingPrice: 0,
    maxFreeShippingPrice: 60,
};

export const mapFreeShippingPrice = (
    dto: FreeShippingPriceDTO,
    locale: string = DEFAULT_LOCALE,
): FreeShippingPriceValue => {
    const language = LanguageHelpers.parseCountry(locale as Locale) as string;

    if (typeof dto.minPrice === 'number' && typeof dto.maxPrice === 'number') {
        return {
            minFreeShippingPrice: dto.minPrice,
            maxFreeShippingPrice: dto.maxPrice,
        };
    }

    const dtoLanguage = dto[language as keyof typeof dto] as { minPrice: number; maxPrice: number } | undefined;

    if (dtoLanguage) {
        return {
            minFreeShippingPrice: dtoLanguage.minPrice,
            maxFreeShippingPrice: dtoLanguage.maxPrice,
        };
    }

    return {
        ...DEFAULT_FREE_SHIPPING_PRICE,
    };
};
