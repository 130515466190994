import { Locale } from '@headless-workspace/config';
import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { mapPersistentBannerSlot, PersistentBannerSlotDTO, PersistentBannerSlotValue } from '.';

export interface PersistentBannerSlotRepositorySpec {
    fetchPersistentBannerSlot(locale: Locale): Promise<Result<PersistentBannerSlotValue>>;
}

const SLOT_ID = 'persistent-banner-global';

export class PersistentBannerSlotRepository extends Repository implements PersistentBannerSlotRepositorySpec {
    path = '/content/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async fetchPersistentBannerSlot(locale: Locale): Promise<Result<PersistentBannerSlotValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/slots/${SLOT_ID}`, {
                locale,
            });

            const persistentBannerSlotResponse = mapPersistentBannerSlot(PersistentBannerSlotDTO.parse(response));

            if (!persistentBannerSlotResponse) {
                return ResultEmpty();
            }

            return ResultSuccess(persistentBannerSlotResponse);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
