import { z } from 'zod';

export const WishlistContainerDTO = z.object({
    wishlists: z.array(
        z.object({
            id: z.string(),
            products: z.array(
                z.object({
                    id: z.string(),
                    productId: z.string(),
                }),
            ),
        }),
    ),
});
export type WishlistContainerDTO = z.infer<typeof WishlistContainerDTO>;

export const WishlistDTO = z.object({
    id: z.string(),
    products: z.array(
        z.object({
            id: z.string(),
            productId: z.string(),
        }),
    ),
});
export type WishlistDTO = z.infer<typeof WishlistDTO>;
