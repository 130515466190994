import { BucketConfig, DefaultValueConfig } from '@headless-workspace/config';
import {
    CountrySelectorValue,
    HeaderSlotsDTO,
    mapHeaderSlots,
    unwrapResultSuccess,
} from '@headless-workspace/core-domain';
import { UIHeaderShell } from '@headless-workspace/core-ui';
import { CommonDI, NetStorageRepositoryType } from '@headless-workspace/domain/common/DI';
import {
    AsyncComponent,
    CategoryValue,
    checkFeatureFlagServerAction,
    VipBrandsValue,
} from '@headless-workspace/domain/common/server';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import {
    DI,
    FixedHeader,
    FreeShippingPriceRepositoryType,
    LoyaltyConfigsRepositoryType,
    PersistentBanner,
    PersistentBannerSlotValue,
    PriceLegalMentionsRepositoryType,
    ScrollableHeader,
} from '../../../../../src';

type HeaderProps = PropsWithLocale & {
    categories: CategoryValue[];
    isH1Logo: boolean;
    persistentBannerData?: PersistentBannerSlotValue;
    vipBrands: VipBrandsValue;
    countrySelector?: CountrySelectorValue;
    hostUrl: string | null;
};

export const Header: AsyncComponent<HeaderProps> = async ({
    locale,
    vipBrands,
    categories,
    isH1Logo,
    persistentBannerData,
    countrySelector,
    hostUrl,
}) => {
    const [
        freeShippingPriceResult,
        enablePriceLegalMentionsResult,
        headerSlotsResult,
        isWishlistDisplayed,
        loyaltyConfigsResult,
    ] = await Promise.all([
        DI.get(FreeShippingPriceRepositoryType).fetchFreeShippingPrice(locale),
        DI.get(PriceLegalMentionsRepositoryType).fetchEnablePriceLegalMentions(locale),
        CommonDI.get(NetStorageRepositoryType).getCountrySpecificities(
            locale,
            BucketConfig.filename.headerSlots,
            HeaderSlotsDTO,
            mapHeaderSlots,
        ),
        checkFeatureFlagServerAction('isWishlistDisplayed', locale),
        DI.get(LoyaltyConfigsRepositoryType).fetchLoyaltyConfiguration(locale),
    ]);

    const headerSlots = unwrapResultSuccess(headerSlotsResult);
    const maxFreeShippingPrice =
        unwrapResultSuccess(freeShippingPriceResult)?.maxFreeShippingPrice ?? DefaultValueConfig.maxFreeShippingPrice;
    const enablePriceExceptionLegalMentions = unwrapResultSuccess(enablePriceLegalMentionsResult) ?? false;
    const isEnableOffers = unwrapResultSuccess(loyaltyConfigsResult)?.isEnableOffers ?? false;

    return (
        <UIHeaderShell
            topBannerChildren={persistentBannerData && <PersistentBanner locale={locale} data={persistentBannerData} />}
            fixedChildren={
                <FixedHeader
                    totalForFreeShipping={maxFreeShippingPrice}
                    locale={locale}
                    enablePriceExceptionLegalMentions={enablePriceExceptionLegalMentions}
                    isH1Logo={isH1Logo}
                    categories={categories}
                    vipBrands={vipBrands}
                    headerSlots={headerSlots}
                    isWishlistDisplayed={isWishlistDisplayed}
                    isEnableOffers={isEnableOffers}
                    countrySelector={countrySelector}
                    hostUrl={hostUrl}
                />
            }
        >
            <ScrollableHeader locale={locale} vipBrands={vipBrands} />
        </UIHeaderShell>
    );
};
