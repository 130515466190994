import { Locale } from '@headless-workspace/config';
import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { NewsletterDTO } from './NewsletterDTO';
import { NewsletterValue, mapNewsletter } from './NewsletterValue';

export interface NewsletterSlotRepositorySpec {
    fetchNewsletterSlot(locale: Locale): Promise<Result<NewsletterValue>>;
}

const SLOT_ID = 'newsletter-content-global';

export class NewsletterSlotRepository extends Repository implements NewsletterSlotRepositorySpec {
    readonly path = '/content/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async fetchNewsletterSlot(locale: Locale): Promise<Result<NewsletterValue>> {
        try {
            const response = await this.datasource.getResource(`/content/v1/slots/${SLOT_ID}`, {
                locale,
            });

            const newsLetterSlotResponse = mapNewsletter(NewsletterDTO.parse(response));
            if (!newsLetterSlotResponse) {
                return ResultEmpty();
            }

            return ResultSuccess(newsLetterSlotResponse);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
