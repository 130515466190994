'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { GlowTabsProps, GlowTabs, PropsWithClassName } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';
import { PredicateHelpers } from '@headless-workspace/utils';
import { ProductInfoTabParamsValue } from '../../../../server';
import styles from './product-info-section.module.scss';

type ProductInfoTabsProps = {
    productInfoTabs: ProductInfoTabParamsValue[];
    openTabWithId?: string;
} & PropsWithClassName;

const PRODUCT_INFO_TAB = 'productInfoTab';
const PRODUCT_DETAIL_INFO_MODAL_ID = 'product-detail-info-modal';

export const ProductInfoTabs: Component<ProductInfoTabsProps> = ({ productInfoTabs, openTabWithId, className }) => {
    const t = useTranslations();
    const defaultIndex = productInfoTabs.findIndex((item) => item?.id === openTabWithId);

    const generateTabs = (tabs: ProductInfoTabParamsValue[]) => {
        return tabs
            .map((productInfoTab) => {
                return (
                    productInfoTab.value && {
                        label: t(productInfoTab.label),
                        Content: () => <InnerHTML rawHtml={productInfoTab.value} />,
                    }
                );
            })
            .filter(PredicateHelpers.notNullOrUndefined);
    };

    const productTab: GlowTabsProps = {
        id: PRODUCT_INFO_TAB,
        previousButtonAriaLabel: t('Discover.Pdp.productInfo.productTab.ariaLabel.previousButton'),
        nextButtonAriaLabel: t('Discover.Pdp.productInfo.productTab.ariaLabel.nextButton'),
        defaultIndex,
        tabs: generateTabs(productInfoTabs),
    };

    // HACK MOBILE: to display close btn always sticky in mobile
    const productDetailInfoModal = document.getElementById(PRODUCT_DETAIL_INFO_MODAL_ID);
    const isMobile = useTailwindBreakpoint('tablet', false, true);

    if (isMobile && productDetailInfoModal) {
        productDetailInfoModal.style.height = tailwindTheme.height.dynamicViewportHeight;
    }

    return (
        <GlowTabs
            {...productTab}
            contentClassName={clsx('h-productInfoModalContainer overflow-x-auto', styles.descriptionWrapper, className)}
        />
    );
};
