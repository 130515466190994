import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import { RequestStrategy } from '@headless-workspace/data';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { DecoderHelpers } from '@headless-workspace/utils';
import { BasketProductQuantityValue } from './BasketProductQuantityValue';
import { BasketsDTO } from './BasketsDTO';
import { CustomerBasketContainerDTO, CustomerBasketProductDTO } from './CustomerBasketDTO';
import { CustomerBasketValue, mapCustomerBasket } from './CustomerBasketValue';

export interface BasketRepositorySpec {
    getCustomerBasket(
        locale: string,
        customerId: string,
        vipBrands: VipBrandsValue,
    ): Promise<Result<CustomerBasketValue>>;

    addToBasket(
        locale: string,
        customerId: string,
        vipBrands: VipBrandsValue,
        products: BasketProductQuantityValue[],
    ): Promise<Result<CustomerBasketValue>>;
}

export class BasketRepository extends Repository implements BasketRepositorySpec {
    readonly path = '/basket/v1';

    constructor(requestStrategy: RequestStrategy) {
        super(requestStrategy.authStrategy, requestStrategy.refreshTokenStrategy, requestStrategy.queryParamStrategy);
    }

    private mapCustomerBasketResponse(customerBasketResponse: unknown, vipBrands: VipBrandsValue): CustomerBasketValue {
        const basket = CustomerBasketContainerDTO.parse(customerBasketResponse);
        const basketProducts = DecoderHelpers.safeParseArray(basket.products, CustomerBasketProductDTO);
        return mapCustomerBasket({ ...basket, products: basketProducts }, vipBrands);
    }

    async getCustomerBasketId(locale: string, customerId: string): Promise<Result<string>> {
        try {
            const basketResponse = await this.datasource.getResource(`${this.path}/baskets`, {
                customerId,
                locale,
            });

            const basketResult = BasketsDTO.parse(basketResponse);
            const basketId = basketResult.baskets[0]?.id;

            return basketId ? ResultSuccess(basketId) : ResultEmpty();
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async getCustomerBasket(
        locale: string,
        customerId: string,
        vipBrands: VipBrandsValue,
    ): Promise<Result<CustomerBasketValue>> {
        try {
            const basketIdResult = await this.getCustomerBasketId(locale, customerId);

            if (basketIdResult.type !== 'success') {
                return ResultEmpty();
            }

            const customerBasketResponse = await this.datasource.getResource(
                `${this.path}/baskets/${basketIdResult.data}`,
                { locale },
            );

            return ResultSuccess(this.mapCustomerBasketResponse(customerBasketResponse, vipBrands));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async addToBasket(
        locale: string,
        customerId: string,
        vipBrands: VipBrandsValue,
        products: BasketProductQuantityValue[],
    ): Promise<Result<CustomerBasketValue>> {
        try {
            const basketResponse = await this.getCustomerBasketId(locale, customerId);
            if (basketResponse.type !== 'success') {
                return ResultEmpty();
            }

            const customerBasketResponse = await this.datasource.createResource(
                `${this.path}/baskets/${basketResponse.data}/products`,
                { products },
                { locale },
            );

            return ResultSuccess(this.mapCustomerBasketResponse(customerBasketResponse, vipBrands));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
