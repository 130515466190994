import { z } from 'zod';
import { FidelityBannerDTO } from './fidelityBanner';
import { GWPBannerDTO } from './gwpBanner';
import { MarketingBannerDTO } from './marketingBanner';

export const ProductDetailBannerDTO = z.union([GWPBannerDTO, FidelityBannerDTO, MarketingBannerDTO]);
export type ProductDetailBannerDTO = z.infer<typeof ProductDetailBannerDTO>;

export type ProductDetailBannersDTO = z.infer<typeof ProductDetailBannersDTO>;
export const ProductDetailBannersDTO = z.array(ProductDetailBannerDTO);
