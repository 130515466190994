import { MediaDTO, ProductTypeDTO } from '@headless-workspace/domain/common/client';
import { BrandDTO, PriceDTO } from '@headless-workspace/domain/common/server';
import { z } from 'zod';
import { ProductDetailBannerDTO } from './banner/ProductDetailBannerDTOTypes';
import { BreadcrumbDTO } from './breadcrumb';
import { BannersMock } from './mocks/BannersMock';
import { breadcrumbMock } from './mocks/BreadcrumbMock';

export const ProductDetailVariantDTO = z
    .object({
        id: z.string(),
        name: z.string().nullish(),
        swatchImageUrl: z.string().nullish(),
        isAvailable: z.boolean().nullish(),
        format: z.string().nullish(),
        promotion: z.string().nullish(),
    })
    .and(PriceDTO);

export type ProductDetailVariantDTO = z.infer<typeof ProductDetailVariantDTO>;

export const ProductDetailDTO = z
    .object({
        id: z.string(),
        name: z.string(),
        vat: z.number().nullish(),
        currency: z.string(),
        description: z.string().nullish(),
        tips: z.string().nullish(),
        testResults: z.string().nullish(),
        compositions: z.string().nullish(),
        clinicalTests: z.string().nullish(),
        ingredients: z.string().nullish(),
        usage: z.string().nullish(),
        notes: z.string().nullish(),
        moreInformations: z.string().nullish(),
        rating: z.number(),
        reviewCount: z.number(),
        stockLevel: z.number().nullish(),
        barCode: z.string().nullish(),
        orderable: z.boolean().nullish(),
        offers: z.array(z.unknown()), // FIXME: define OfferDTO, not defined in the API documentation
        banners: z.array(ProductDetailBannerDTO).default(BannersMock), // FIXME: define OfferDTO, not defined in the API documentation
        variants: z.array(ProductDetailVariantDTO).default([]),
        medias: z.array(MediaDTO).default([]),
        breadcrumb: z.array(BreadcrumbDTO).default(breadcrumbMock),
        pricePerUnit: z.string().optional(),
        buyNowPayLaterContent: z.string().nullish(),
        type: ProductTypeDTO,
        promotion: z.union([z.literal('STANDARD'), z.literal('OMNIBUS'), z.literal('REGULAR')]).optional(),
        isRedDot: z.boolean().optional(),
    })
    .and(BrandDTO)
    .and(PriceDTO);

export type ProductDetailDTO = z.infer<typeof ProductDetailDTO>;

export const ProductDetailContainerDTO = z.object({
    product: ProductDetailDTO,
});

export type ProductDetailContainerDTO = z.infer<typeof ProductDetailContainerDTO>;
