'use client';

import { useCallback, useEffect } from 'react';
import { Locale } from '@headless-workspace/config';
import {
    DataLayerClassName,
    DataLayerEvent,
    DataLayerEventCategory,
    DataLayerEventLabel,
    DataLayerKey,
    HeaderDidMount,
    useDataLayer,
} from '@headless-workspace/domain/common/client';
import { DataLayerUtils } from '@headless-workspace/domain/common/client';
import { Component } from '@headless-workspace/typings';

type HomeDidMountProps = {
    locale: Locale;
};

const DATA_LAYER_CONNECTION_ACTION = 'homepage login';

export const HomeDidMount: Component<HomeDidMountProps> = ({ locale }) => {
    const { trackEvent, addVariables } = useDataLayer({
        [DataLayerKey.EnvTemplate]: 'homepage',
        [DataLayerKey.PageName]: 'homepage',
        [DataLayerKey.PageTopCat]: 'homepage',
    });

    // Beauty Tips  event tracking
    const beautyTipsEventsClick = useCallback(() => {
        const promoPosition = 'hp body';
        const promoCreative = 'beauty tips';
        return () =>
            trackEvent(DataLayerEvent.Click, {
                id: 'click',
                category: DataLayerEventCategory.InternalMarketing,
                event_type: 'Internal_promotion',
                action: 'Promotion Click',
                label: '',
                promoId: `${promoPosition}_${promoCreative}_`,
                promoCreative: promoCreative,
                promoPosition: promoPosition,
            });
    }, [trackEvent]);

    // Banner click  event tracking
    const trackBannerClick = useCallback(
        (bannerType: string, bannerId: string, bannerName: string) => {
            return () =>
                trackEvent(DataLayerEvent.Click, {
                    id: 'click',
                    category: DataLayerEventCategory.Animation,
                    action: bannerType,
                    label: [bannerId, bannerName],
                });
        },
        [trackEvent],
    );

    useEffect(() => {
        const eventClickHandlerReferences: [Element, EventListener][] = [];

        // Add event listeners for beauty tips
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.beautyTips}`,
            () => beautyTipsEventsClick(),
            eventClickHandlerReferences,
        );

        // Add event listeners for nested category navigation
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.banner}`,
            (element) => {
                const dataLayerId = element.getAttribute('data-layer-id') ?? DataLayerEventLabel.Null;
                const dataLayerName = element.getAttribute('data-layer-name') ?? DataLayerEventLabel.Null;
                const dataLayerType = element.getAttribute('data-layer-banner-action') ?? DataLayerEventLabel.Null;
                return trackBannerClick(dataLayerType, dataLayerId, dataLayerName);
            },
            eventClickHandlerReferences,
        );

        return () => {
            eventClickHandlerReferences.forEach(([element, handler]) => {
                element.removeEventListener('click', handler);
            });
        };
    }, [beautyTipsEventsClick, trackBannerClick]);

    return (
        <HeaderDidMount
            dataLayerConnectionAction={DATA_LAYER_CONNECTION_ACTION}
            trackEvent={trackEvent}
            addVariables={addVariables}
            locale={locale}
        />
    );
};
