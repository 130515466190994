import { z } from 'zod';

export const HomeSlotDTOTypes = {
    Brandorama: z.literal('brandorama'),
    OpenBarServices: z.literal('open_bar_services'),
    ProductsList: z.literal('products_list'),
    ProductRecommendations: z.literal('product-recommendations'),
    ButtonsWebmo: z.literal('buttons-webmo'),
    ButtonsWebmoV2: z.literal('buttons-webmo_v2'),
    MozaicWindows: z.literal('mozaic-windows'),
    UnderBanners: z.literal('underbanners'),
    BeautyTips: z.literal('beauty-tips'),
};
