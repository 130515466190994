import { DefaultValueConfig, Locale } from '@headless-workspace/config';
import { mapImage } from '@headless-workspace/domain/common/client';
import { Image } from '@headless-workspace/typings';
import { LanguageHelpers, ObjectHelpers, UrlHelpers } from '@headless-workspace/utils';
import { HomeBrandoramaDTO, HomeSlotBase, HomeSlotType, mapHomeSlotBase } from '../../../../../src';

export type HomeBrandoramaValue = HomeSlotBase & {
    type: HomeSlotType.Brandorama;
    tiles: {
        image: Image;
        href: string;
        brandName: string;
        id: string;
        name: string;
    }[];
};

export const mapHomeBrandorama = (dto: HomeBrandoramaDTO, locale: Locale): HomeBrandoramaValue => {
    const language = LanguageHelpers.mapLocaleToLanguage(locale);

    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.Brandorama,
        tiles: dto.details.brands.map((brand) => ({
            image: mapImage(brand.image) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),
            href: UrlHelpers.getHrefPathname(brand.link ?? DefaultValueConfig.href, language),
            brandName: brand.title ?? '',
            id: brand.id ?? '',
            name: brand.name ?? '',
        })),
    };
};
