import { DateHelpers } from '@headless-workspace/utils';
import { useCountdown } from 'usehooks-ts';

export const usePersistentBannerCountDown = (targetTimestamp: number) => {
    const [count, { startCountdown }] = useCountdown({
        countStart: DateHelpers.getIntervalInSecondsFromNow(targetTimestamp),
        intervalMs: 1000,
    });
    return { ...DateHelpers.extractsDateFromSeconds(count), startCountdown };
};
