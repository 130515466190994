import { z } from 'zod';

const FreeShippingPrice = z.object({
    minPrice: z.number(),
    maxPrice: z.number(),
});

export const FreeShippingPriceDTO = z.union([FreeShippingPrice, z.record(FreeShippingPrice)]);

export type FreeShippingPriceDTO = z.infer<typeof FreeShippingPriceDTO>;
