import { UIBrandCorner } from '@headless-workspace/core-ui';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { productDetailBrandCornerValue } from '../../../../../__mocks__/factory/productDetailBrandCorner';
import { mapBrandCorner } from './ProductDetailBrandCornerValue';

type ProductDetailBrandCornerSectionProps = PropsWithFontFamily;

export const ProductDetailBrandCornerSection: Component<ProductDetailBrandCornerSectionProps> = ({ fontFamily }) => {
    const brandCorner = mapBrandCorner(productDetailBrandCornerValue);
    const BRAND_CORNER_SECTION_ID = 'brand-corner-section';

    return (
        <section id={BRAND_CORNER_SECTION_ID} className={'flex justify-center items-center py-2 px-1 desktop:px-0'}>
            <UIBrandCorner
                {...brandCorner}
                title={<InnerHTML rawHtml={{ content: brandCorner.title }} />}
                content={<InnerHTML rawHtml={{ content: brandCorner.content }} />}
                image={{ src: brandCorner.image }}
                fontFamily={fontFamily}
            />
        </section>
    );
};
