import clsx from 'clsx';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { getIcon, GlowIcon, GlowTextBody, GlowTextCaption } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductDetailDeliveryInfoMockType } from '../../../../client';

type DeliveryInfoProps = {
    icons: string[];
    text: string;
};

const DeliveryInfo: Component<DeliveryInfoProps> = ({ icons, text }) => (
    <div
        className={
            'flex flex-row justify-between items-center pb-1 mb-1 border-b-medium border-b-border-subtle border-solid'
        }
    >
        <GlowTextBody text={text} fontWeight={'bold'} />
        <div>
            {icons.map((icon, index) => (
                <GlowIcon key={index} Icon={getIcon(icon)} className={clsx(index > 0 && 'relative -top-0.75')} />
            ))}
        </div>
    </div>
);

type ProductDetailDeliveryInfoProps = {
    deliveryInfo: ProductDetailDeliveryInfoMockType;
};

export const ProductDetailDeliveryInfo: Component<ProductDetailDeliveryInfoProps> = ({ deliveryInfo }) => {
    return (
        <div className={'flex flex-col gap-2'}>
            {deliveryInfo.data.map((deliveryInformation) => (
                <div key={deliveryInformation.title}>
                    <DeliveryInfo text={deliveryInformation.title} icons={deliveryInformation.iconName} />
                    <InnerHTML rawHtml={deliveryInformation.description} />
                    {deliveryInformation.caption && <InnerHTML rawHtml={deliveryInformation.caption} />}
                </div>
            ))}
            <GlowTextCaption text={deliveryInfo.caption} />
        </div>
    );
};
