import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { GlowChip, GlowIcon, GlowTextBody, PropsWithClassName, PropsWithIcon } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductDetailInfoCTAProps } from '../../../../../src';
import { ChipColor } from './ChipColor';

type DeliveryInformationProps = PropsWithClassName &
    PropsWithIcon & {
        title: string;
        description?: string;
        chipColor: ChipColor;
        statusLabel: string;
        cta?: ReactNode;
        isLoading?: boolean;
        menu?: ProductDetailInfoCTAProps['menu'];
    };

export const DeliveryInformation: Component<DeliveryInformationProps> = ({
    Icon,
    title,
    description,
    chipColor,
    statusLabel,
    cta,
    isLoading = false,
    className,
}) => {
    if (isLoading) {
        // FIXME: Implement skeleton component
        return <p>Loading...</p>;
    }

    return (
        <div className={clsx('flex flex-col gap-1', 'w-full', 'px-1', className)}>
            <div className={'flex flex-row gap-1 w-full text-text-primary'}>
                <GlowIcon Icon={Icon} type={'large'} />
                <div className={'flex flex-col gap-0.5 w-full'}>
                    <div className={'flex flex-col'}>
                        <GlowTextBody text={title} fontWeight={'bold'} />
                        {description && <GlowTextBody text={description} />}
                    </div>
                    <div className={'flex flex-row gap-0.5'}>
                        <GlowChip hasDigit={false} color={chipColor} size={'medium'} className={'mt-0.375'} />
                        <GlowTextBody text={statusLabel} />
                    </div>
                </div>
            </div>
            {cta}
        </div>
    );
};
