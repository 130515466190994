'use client';

import React from 'react';
import clsx from 'clsx';
import { DataTestConfig, isUnlimitedLoyaltyLocale } from '@headless-workspace/config';
import { DynamicLabelValue, HeaderSlotType, HeaderSlotValue } from '@headless-workspace/core-domain';
import { UIHoverBannerButton } from '@headless-workspace/core-ui';
import { useUserInfo, DataLayerClassName } from '@headless-workspace/domain/common/client';
import {
    isConnectedUserInfo,
    hasFidelityProgram,
    getConnectedUserInfo,
} from '@headless-workspace/domain/common/server';
import { getIcon, PropsWithLocale, PropsWithSkeleton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useFindSpecificityRule } from '../../countrySpecificities';

type ConnectionHoverButtonProps = HeaderSlotValue &
    PropsWithLocale & {
        isLoggingOut: boolean;
    };

export const ConnectionHoverButton: Component<ConnectionHoverButtonProps> = ({
    type,
    iconName,
    label,
    href,
    displayClassName,
    labelDisplayClassName,
    dynamicLabel,
    isLoggingOut,
    locale,
}) => {
    const skeletonProps = {
        width: '6rem',
        height: '1.25rem',
        containerClassName: 'hidden desktop:flex',
    };

    const { result: userInfoResult } = useUserInfo();
    const userInfo = getConnectedUserInfo(userInfoResult);
    const iconHasChip =
        isUnlimitedLoyaltyLocale(locale) && isConnectedUserInfo(userInfo) && hasFidelityProgram(userInfo);

    if (dynamicLabel) {
        return (
            <ConnectionHoverDynamicButton
                isLoggingOut={isLoggingOut}
                type={type}
                iconName={iconName}
                label={label}
                href={href}
                dynamicLabel={dynamicLabel}
                displayClassName={displayClassName}
                labelDisplayClassName={labelDisplayClassName}
                skeleton={skeletonProps}
                iconHasChip={iconHasChip}
            />
        );
    }

    return (
        <UIHoverBannerButton
            Icon={getIcon(iconName)}
            label={label}
            labelClassName={labelDisplayClassName}
            href={href}
            className={clsx('h-full', DataLayerClassName.connection, displayClassName)}
            ariaLabel={label}
            isLoading={isLoggingOut}
            disabled={isLoggingOut}
            skeleton={skeletonProps}
            hasChip={iconHasChip}
            dataTestId={DataTestConfig.testIds.accountUserConnectionButton}
        />
    );
};

type ConnectionHoverDynamicButtonProps = PropsWithSkeleton & {
    type: HeaderSlotType;
    iconName: string;
    label: string;
    href: string;
    dynamicLabel: DynamicLabelValue[];
    displayClassName?: string;
    labelDisplayClassName?: string;
    isLoggingOut: boolean;
    iconHasChip?: boolean;
};

const ConnectionHoverDynamicButton: Component<ConnectionHoverDynamicButtonProps> = ({
    type,
    iconName,
    label,
    href,
    dynamicLabel,
    displayClassName,
    labelDisplayClassName,
    isLoggingOut,
    skeleton,
    iconHasChip,
}) => {
    const specificityRuleAsyncData = useFindSpecificityRule(type, dynamicLabel);

    if (specificityRuleAsyncData.isLoading || specificityRuleAsyncData.result.type !== 'success') {
        return (
            <UIHoverBannerButton
                Icon={getIcon(iconName)}
                label={label}
                labelClassName={labelDisplayClassName}
                href={href}
                className={clsx('h-full', DataLayerClassName.connection, displayClassName)}
                ariaLabel={label}
                isLoading={isLoggingOut}
                disabled={specificityRuleAsyncData.isLoading || isLoggingOut}
                skeleton={skeleton}
                hasChip={iconHasChip}
            />
        );
    }

    return (
        <UIHoverBannerButton
            Icon={getIcon(iconName)}
            label={specificityRuleAsyncData.result.data.text}
            labelClassName={specificityRuleAsyncData.result.data.displayClassName}
            href={specificityRuleAsyncData.result.data.href}
            className={clsx('h-full', DataLayerClassName.connection, displayClassName)}
            ariaLabel={specificityRuleAsyncData.result.data.text}
            isLoading={isLoggingOut}
            disabled={isLoggingOut}
            skeleton={skeleton}
            hasChip={iconHasChip}
            dataTestId={DataTestConfig.testIds.accountUserConnectionButton}
        />
    );
};
