import { Locale } from '@headless-workspace/config';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { LanguageHelpers, UrlHelpers } from '@headless-workspace/utils';
import {
    CTA,
    HomeProductRecommendationsDTO,
    HomeProductsListDTO,
    HomeSlotBase,
    HomeSlotType,
    mapHomeSlotBase,
} from '../../../../index';

export type HomeCarouselValue = HomeSlotBase & {
    type: HomeSlotType.Carousel;
    title: HTMLString;
    cta?: CTA;
    productsIds: string[];
};

export const mapHomeCarousel = (
    dto: HomeProductsListDTO | HomeProductRecommendationsDTO,
    locale: Locale,
): HomeCarouselValue => {
    const language = LanguageHelpers.mapLocaleToLanguage(locale);

    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.Carousel,
        title: HTMLString(dto.details.title),
        cta:
            dto.details.cta?.label && dto.details.cta?.link
                ? {
                      label: dto.details.cta.label,
                      href: UrlHelpers.getHrefPathname(dto.details.cta.link, language),
                  }
                : undefined,
        productsIds: dto.details.productsIds,
    };
};
