'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { CountrySelectorValue, HeaderSlotsValue } from '@headless-workspace/core-domain';
import { UIBurgerMenuButton, UISearchIconButton } from '@headless-workspace/core-ui';
import { CountrySwitcher, LanguageSwitcher, SearchContainer } from '@headless-workspace/domain/common/client';
import { CategoryNavigation, CategoryValue, VipBrandsValue } from '@headless-workspace/domain/common/server';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { BasketSummaryContainer, WishlistContainer } from '../../../../../src';
import { FixedHeaderSlots } from './FixedHeaderSlots';
import { Logo } from './Logo';

type FixedHeaderProps = PropsWithLocale & {
    isH1Logo: boolean;
    categories: CategoryValue[];
    vipBrands: VipBrandsValue;
    totalForFreeShipping: number;
    headerSlots?: HeaderSlotsValue;
    enablePriceExceptionLegalMentions: boolean;
    isWishlistDisplayed?: boolean;
    isEnableOffers?: boolean;
    countrySelector?: CountrySelectorValue;
    hostUrl: string | null;
};

export const FixedHeader: Component<FixedHeaderProps> = ({
    totalForFreeShipping,
    locale,
    isH1Logo,
    categories,
    vipBrands,
    headerSlots,
    enablePriceExceptionLegalMentions,
    isWishlistDisplayed,
    isEnableOffers,
    countrySelector,
    hostUrl,
}) => {
    const t = useTranslations('Header');

    return (
        <>
            <div
                className={clsx(
                    'flex flex-row items-center justify-between',
                    'h-headerFixedMobile tablet:h-headerFixedTablet desktop:h-headerFixedMainDesktop',
                    'px-1 desktop:px-1.5 gap-1',
                    'desktop:mx-auto desktop:w-maxWidth',
                )}
                id={'fixed-header'}
            >
                <div className={'flex flex-row items-center justify-center gap-0.5'}>
                    <UIBurgerMenuButton ariaLabel={t('navigation.ariaLabel.burger')} />
                    {isH1Logo ? (
                        <h1>
                            <Logo locale={locale} />
                        </h1>
                    ) : (
                        <Logo locale={locale} />
                    )}
                </div>
                <div className={'w-full hidden tablet:flex'}>
                    <SearchContainer locale={locale} vipBrands={vipBrands} />
                </div>
                <div className={'flex flex-row justify-center items-center h-full'}>
                    <UISearchIconButton />

                    {headerSlots && (
                        <FixedHeaderSlots isEnableOffers={isEnableOffers} locale={locale} headerSlots={headerSlots} />
                    )}

                    {isWishlistDisplayed && <WishlistContainer locale={locale} />}

                    <BasketSummaryContainer
                        locale={locale}
                        totalForFreeShipping={totalForFreeShipping}
                        vipBrands={vipBrands}
                        enablePriceExceptionLegalMentions={enablePriceExceptionLegalMentions}
                    />
                </div>
                {countrySelector ? (
                    <CountrySwitcher
                        className={'pb-1.5 desktopS:pb-0 hidden desktop:flex'}
                        countrySelector={countrySelector}
                        displayType={'header'}
                        hostUrl={hostUrl}
                        locale={locale}
                    />
                ) : (
                    <LanguageSwitcher
                        className={'pb-1.5 desktopS:pb-0 hidden desktop:flex'}
                        displayType={'header'}
                        hostUrl={hostUrl}
                    />
                )}
            </div>

            {categories.length > 0 && (
                <CategoryNavigation
                    className={'hidden desktop:flex justify-center overflow-hidden'}
                    locale={locale}
                    categories={categories}
                />
            )}
        </>
    );
};
