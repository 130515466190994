import React from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute, DataTestConfig } from '@headless-workspace/config';
import { GlowButton, GlowTextBody, GlowTitle, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const ConnectionBannerNotConnectedGreeting: Component<PropsWithLocale> = ({ locale }) => {
    const t = useTranslations('Header.connection');

    return (
        <>
            <GlowTitle text={t('banner.label.greeting')} fontSize={'title4'} />
            <GlowTextBody text={t('banner.label.info')} />
            <GlowButton
                className={'self-stretch transition-backgroundOutline'}
                label={t('banner.action.loginOrRegister')}
                href={buildRoute(locale, 'connection')}
                variant={'primary'}
                dataTestId={DataTestConfig.testIds.connectionBannerLoginButton}
            />
        </>
    );
};
