'use client';

import { PropsWithChildren, useCallback, useState } from 'react';
import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { useUserInfo } from '@headless-workspace/domain/common/client';
import { getConnectedUserInfo } from '@headless-workspace/domain/common/server';
import { GlowIcon, GlowTextBody, GlowTitle, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { NewsletterForm } from './NewsletterForm';
import { NewsletterPolicy } from './NewsletterPolicy';
import { NewsletterSkeleton } from './NewsletterSkeleton';
import { useNewsletter } from './useNewsletter';

const NewsletterContainerSection: Component<PropsWithChildren> = ({ children }) => {
    return (
        <div className={'bg-background-neutral'} data-testid={DataTestConfig.testIds.newsletterSection}>
            <div
                className={clsx(
                    'desktop:mx-auto desktop:w-maxWidth',
                    'relative flex flex-col tablet:flex-row gap-1.5',
                    'p-1 desktop:px-5.5 desktop:py-2.5',
                    'min-h-newsletterMobile tablet:min-h-newsletter justify-center items-center',
                )}
            >
                {children}
            </div>
        </div>
    );
};

type NewsletterSectionProps = PropsWithLocale;

export const NewsletterSection: Component<NewsletterSectionProps> = ({ locale }) => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { result: userInfoResult, isLoading: isUserInfoLoading } = useUserInfo();
    const alreadySubscribed = getConnectedUserInfo(userInfoResult)?.isSubscribedByEmail ?? false;
    const { result: newsletterSlotResult, isLoading: isLoadingSlot } = useNewsletter();
    const newsletterSlot = unwrapResultSuccess(newsletterSlotResult);
    const email = getConnectedUserInfo(userInfoResult)?.email;

    const onSubscribe = useCallback(() => {
        setIsSubmitted(false);
        setIsSubscribed(true);
    }, [setIsSubscribed]);

    const onUserSubmit = (status: boolean) => {
        setIsSubmitted(status);
    };

    if (isUserInfoLoading || isSubmitted || isLoadingSlot) {
        return (
            <NewsletterContainerSection>
                <NewsletterSkeleton />
            </NewsletterContainerSection>
        );
    }
    if (alreadySubscribed || !newsletterSlot) {
        return null;
    }

    const userInfo = unwrapResultSuccess(userInfoResult);

    return (
        <NewsletterContainerSection>
            <NewsletterPolicy
                newsletterBanner={newsletterSlot?.newsletterBannerOffer}
                newsletterLegalMention={newsletterSlot.newsletterLegalMention}
                newsletteOfferLegalMention={newsletterSlot?.newsletteOfferLegalMention}
                isNewsletterBoostEnabled={newsletterSlot?.isNewsletterBoostEnabled}
                locale={locale}
            />
            <NewsletterForm
                className={'flex flex-col flex-1 gap-0.5 w-full'}
                locale={locale}
                onSubscribe={onSubscribe}
                onUserSubmit={onUserSubmit}
                userInfo={userInfo}
                defaultEmail={email}
                newsletterConfirmationPopup={newsletterSlot.newsletterConfirmationPopup}
            />
            {isSubscribed && (
                <div
                    className={clsx(
                        'absolute inset-0',
                        'flex flex-col tablet:flex-row tablet:items-center justify-center tablet:justify-start',
                        'gap-1.5',
                        'p-1 desktop:px-5.5 desktop:py-2.5',
                        'bg-background-neutral',
                    )}
                >
                    <span className={'h-newsletterIcon w-newsletterIcon'}>
                        <GlowIcon Icon={Icons.Newsletter} type={'fullSize'} />
                    </span>
                    <div
                        className={'flex flex-col gap-0.5'}
                        data-testid={DataTestConfig.testIds.newsletterSubscriptionConfirmMessage}
                    >
                        <GlowTitle
                            text={newsletterSlot.newsletterSuccessfulSubscription?.title}
                            fontSize={'title3'}
                            role={'status'}
                        />
                        <GlowTextBody text={newsletterSlot.newsletterSuccessfulSubscription?.subtitle} />
                    </div>
                </div>
            )}
        </NewsletterContainerSection>
    );
};
