import { DefaultValueConfig } from '@headless-workspace/config';
import { mapURLToImage } from '@headless-workspace/domain/common/client';
import { mapProductPriceData, ProductPriceData } from '@headless-workspace/domain/common/server';
import { Image } from '@headless-workspace/typings';
import { ObjectHelpers, ValueHelpers } from '@headless-workspace/utils';
import { CapacityFormat, ProductDetailVariantDTO } from '../../../server';

export type ProductDetailVariantValue = ProductPriceData & {
    id: string;
    name?: string;
    thumbnailImage: Image;
    isAvailable: boolean;
    format: CapacityFormat;
};

// FIXME: should be removed when API is ready
const getCapacityFormat = (id: string) => {
    const idInNumber = Number(id);
    if (Number.isNaN(idInNumber)) {
        return CapacityFormat.Standard;
    }
    return idInNumber % 2 === 0 ? CapacityFormat.Mini : CapacityFormat.Standard;
};

export const mapProductDetailVariant = (dto: ProductDetailVariantDTO, currency: string): ProductDetailVariantValue => {
    return {
        id: dto.id,
        name: ValueHelpers.mapOptional(dto.name),
        thumbnailImage:
            mapURLToImage(dto.swatchImageUrl) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),
        isAvailable: dto.isAvailable ?? true,
        format: getCapacityFormat(dto.id), // FIXME: should be updated in FHEAD-790 or when API is ready
        ...mapProductPriceData(dto, currency),
    };
};
