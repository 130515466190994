'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowContainerSection, PropsWithFontFamily, PropsWithId, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useRecommendedProductsList } from '../../../../client';
import { ProductCarousel } from '../../products';
import { RecommendedProductSlot } from './../../../../server';

type ProductDetailCarouselContainerProps = PropsWithLocale &
    PropsWithFontFamily &
    PropsWithId & {
        vipBrands: VipBrandsValue;
        slot: RecommendedProductSlot;
    };

export const ProductDetailCarouselContainer: Component<ProductDetailCarouselContainerProps> = ({
    locale,
    vipBrands,
    slot,
    fontFamily,
}) => {
    const t = useTranslations('Discover.Pdp.carousel');

    const { result: recommendedProductResult } = useRecommendedProductsList({ vipBrands }); // FIXME: should use isLoading return by the hook to display skeleton
    const recommendedProducts = unwrapResultSuccess(recommendedProductResult);
    const productSlot =
        slot === RecommendedProductSlot.FIRST ? recommendedProducts?.firstSlot : recommendedProducts?.secondSlot;

    // TODO : To replace with the CTA see all when we will have information
    const mockedButton = {
        href: '',
        label: t('action.seeAll'),
    };

    if (!productSlot) {
        return;
    }

    return (
        <section className={clsx('flex-col justify-center items-center')}>
            <GlowContainerSection
                className={clsx('gap-1.5 tablet:gap-2 desktop:px-1.5 tablet:px-1')}
                titleContainerClassName={'px-1 desktop:px-4'}
                title={productSlot.title}
                button={mockedButton}
                fontFamily={fontFamily}
            >
                <ProductCarousel
                    products={productSlot.products}
                    prevBtnAriaLabel={t('ariaLabel.prevBtn')}
                    nextBtnAriaLabel={t('ariaLabel.nextBtn')}
                    wishlistBtnAriaLabel={t('ariaLabel.wishlist')}
                    thumbArialLabel={t('ariaLabel.thumb')}
                    locale={locale}
                    navigationButtonClassName={'hidden desktop:block'}
                    showStockStatusBtn={true}
                />
            </GlowContainerSection>
        </section>
    );
};
