import { z } from 'zod';
import { ImageDTO } from '@headless-workspace/domain/common/client';
import { Image } from '@headless-workspace/typings';
import { PropsWithButton } from '@headless-workspace/glow-ds';

export interface BrandCornerValue extends PropsWithButton {
    title: string;
    content: string;
    image: Image;
}

export type BrandCornerDTO = z.infer<typeof BrandCornerDTO>;
export const BrandCornerDTO = z.object({
    title: z.string(),
    content: z.string(),
    image: ImageDTO,
    button: z.object({
        label: z.string(),
        href: z.string(),
    }),
});

export const productDetailBrandCornerValue: BrandCornerDTO = {
    title: '<p>Découvrir la marque Dior</p>',
    content: '<p>Lorsque Christian Dior crée sa maison de couture, en 1946, il imagine une nouvelle Féminité, plus belle et plus heureuse...</p>',
    button: {
        label: 'Découvrir',
        href: 'https://www.sephora.fr/dior/DIOR-HubPage.html',
    },
    image: {
        default: '/images/brandCorner.png',
        alt: 'Brand Corner',
    },
};
