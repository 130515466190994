import 'reflect-metadata';
import { ConstructorArguments, Deps } from '@headless-workspace/typings';
import { Container } from 'typedi';
import { deps } from './dependencyInjection';

export * from './dependencyInjection';

export class DI {
    static deps: Readonly<Deps<ReturnType<typeof deps>>> = {} as Deps<ReturnType<typeof deps>>;
    static instance: Readonly<DI | null> = null;

    getDependency<Type extends keyof typeof DI.deps>(
        type: Extract<Type, string>,
        ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
    ): (typeof DI.deps)[Type] {
        if (!DI.deps[type]) {
            const Constructor = deps()[type] as new (
                ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
            ) => (typeof DI.deps)[Type];
            DI.deps = { ...DI.deps, [type]: new Constructor(...args) };
            Container.set<(typeof DI.deps)[typeof type]>(type, DI.deps[type]);
        }
        return Container.get<(typeof DI.deps)[Type]>(type);
    }

    static get<Type extends keyof typeof DI.deps>(
        type: Extract<Type, string>,
        ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
    ): (typeof DI.deps)[Type] {
        if (!DI.instance) {
            DI.instance = new DI();
        }
        return DI.instance.getDependency(type, ...args);
    }
}
