'use client';

import { useCallback, useState } from 'react';
import { CommonDI, LoggerType, SearchGeolocationRepositoryType } from '@headless-workspace/domain/common/DI';
import { ClientEnvKey, useClientData } from '@headless-workspace/domain/common/client';
import { SearchGeolocationAddressValue } from '@headless-workspace/domain/common/server';
import { FormatHelpers, IsomorphicLogger } from '@headless-workspace/utils';

const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
};

const GEOLOCATION_REFUSED = 'Geolocation refused by user';
const GEOLOCATION_ERROR = 'Geolocation error';

export const useUserGeolocation = () => {
    const { getClientEnvVariable } = useClientData();
    const apiBaseUrl = getClientEnvVariable(ClientEnvKey.WoosmapApiBaseUrl);

    const [userPosition, setUserPosition] = useState<GeolocationPosition | null>(null);
    const [userAddress, setUserAddress] = useState<SearchGeolocationAddressValue | null>(null);

    const fetchAddressFromGeolocation = useCallback(
        async (latitude: number, longitude: number, setPositionCallback?: (input: string) => void) => {
            const address = await CommonDI.get(SearchGeolocationRepositoryType, apiBaseUrl).fetchAddressFromGeolocation(
                latitude,
                longitude,
                getClientEnvVariable(ClientEnvKey.WoosmapKey),
            );

            if (address.type === 'success') {
                setUserAddress(address.data);
                if (setPositionCallback) {
                    setPositionCallback(
                        FormatHelpers.formatAddress(
                            address.data.postalCode,
                            address.data.locality,
                            address.data.country,
                        ),
                    );
                }
            }
        },
        [apiBaseUrl, getClientEnvVariable],
    );

    const getCurrentPosition = useCallback(
        (setPositionCallback?: (input: string) => void) => {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    setUserPosition(position);
                    await fetchAddressFromGeolocation(
                        position.coords.latitude,
                        position.coords.longitude,
                        setPositionCallback,
                    );
                },
                (error) => {
                    if (error?.code === error?.PERMISSION_DENIED) {
                        CommonDI.get(LoggerType).warn(GEOLOCATION_REFUSED, error);
                    } else {
                        CommonDI.get(LoggerType).error(GEOLOCATION_ERROR, error);
                    }
                },
                geolocationOptions,
            );
        },
        [fetchAddressFromGeolocation],
    );

    return { userPosition, userAddress, getCurrentPosition };
};
