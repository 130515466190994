'use client';

import { useContext } from 'react';
import { DataTestConfig, NEWSLETTER_MODAL_ID } from '@headless-workspace/config';
import { UIContext } from '@headless-workspace/core-ui';
import {
    GlowButton,
    GlowClickable,
    GlowIcon,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTitle,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { NewsletterConfirmationModal } from '../../../../server';

type NewsletterModalProps = PropsWithLocale & {
    email: string;
    newsletterConfirmationPopup: NewsletterConfirmationModal;
    onSubscribe: () => void;
    onUserSubmit: (status: boolean) => void;
};

export const NewsletterModal: Component<NewsletterModalProps> = ({
    email,
    newsletterConfirmationPopup,
    onSubscribe,
    onUserSubmit,
}) => {
    const {
        menuModal: { close },
    } = useContext(UIContext);

    const onConfirm = () => {
        onSubscribe();
        onUserSubmit(true);
        close(NEWSLETTER_MODAL_ID);
    };

    const onClose = () => {
        onUserSubmit(false);
        close(NEWSLETTER_MODAL_ID);
    };

    if (!newsletterConfirmationPopup) {
        return null;
    }

    return (
        <div
            className={
                'flex flex-col gap-1.5 p-1 bg-background-l0 w-redirectionModalMobile tablet:w-redirectionModalTablet rounded-0.5'
            }
            data-testid={DataTestConfig.testIds.newsletterModal}
        >
            <div className={'flex flex-row justify-between items-center'}>
                <GlowTitle text={newsletterConfirmationPopup.title} fontSize={'title3'} />
                <GlowClickable
                    onClick={onClose}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                    }}
                />
            </div>
            <div className={'flex flex-col gap-0.5'}>
                <GlowTextBody text={newsletterConfirmationPopup?.subtitle} />
                <GlowTextBodySmall className={'break-words'} text={email} fontWeight={'bold'} />
            </div>
            <div className={'flex flex-col gap-0.5'}>
                <GlowButton
                    label={newsletterConfirmationPopup.confirmationPopupCTALabel}
                    variant={'primary'}
                    onClick={onConfirm}
                    dataTestId={DataTestConfig.testIds.newsletterSubscriptionConfirmButton}
                />
                <GlowButton
                    label={newsletterConfirmationPopup.editPopupCTALabel}
                    variant={'secondary'}
                    onClick={onClose}
                    dataTestId={DataTestConfig.testIds.newsletterSubscriptionEditButton}
                />
            </div>
        </div>
    );
};
