export enum HomeSlotType {
    MainBanner = 'mainBanner',
    UnderBanners = 'underBanners',
    Brandorama = 'brandorama',
    Carousel = 'carousel',
    FastAccess = 'fastAccess',
    MobileButtons = 'mobileButtons',
    OpenBarServices = 'openBarServices',
    BeautyTips = 'beautyTips',
}
