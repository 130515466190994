'use client';

import { useCallback, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { buildRoute, DataTestConfig, WISHLIST_MODAL_ID } from '@headless-workspace/config';
import { useUIContext } from '@headless-workspace/core-ui';
import { CommonDI, UserCookiesRepositoryType } from '@headless-workspace/domain/common/DI';
import { ClientEnvKey, useClientData, useNavigation } from '@headless-workspace/domain/common/client';
import {
    GlowButton,
    GlowClickable,
    GlowIcon,
    GlowTextBody,
    GlowTitle,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type WishlistModalProps = PropsWithLocale & {
    productSku: string;
};

export const WishlistModal: Component<WishlistModalProps> = ({ productSku, locale }) => {
    const t = useTranslations('Modal.wishlist');
    const { getClientEnvVariable } = useClientData();
    const navigation = useNavigation();
    const pathname = usePathname();
    const [isRedirecting, setIsRedirecting] = useState(false);
    const {
        menuModal: { close: closeModal },
    } = useUIContext();

    const onConnectionClick = useCallback(async () => {
        setIsRedirecting(true);
        await CommonDI.get(UserCookiesRepositoryType).createGuestProductIdCookie(locale, productSku);
        navigation.push(
            buildRoute(locale, 'connection', {
                original: `${getClientEnvVariable(ClientEnvKey.HostUrl)}${buildRoute(locale, 'wishlist')}`,
            }),
        );
    }, [getClientEnvVariable, locale, productSku, navigation]);

    const onClose = useCallback(() => {
        closeModal(WISHLIST_MODAL_ID);
    }, [closeModal]);

    useEffect(() => {
        setIsRedirecting(false);
    }, [pathname]);

    return (
        <div
            className={
                'flex flex-col items-center justify-center gap-1.5 w-wishlistModalMobile tablet:w-wishlistModal p-1 bg-background-l0 rounded-0.5'
            }
            data-testid={DataTestConfig.testIds.wishlistLoginModal}
        >
            <div className={'flex flex-row gap-x-1 w-full'}>
                <GlowTitle text={t('label.title')} fontSize={'title3'} TagName={'h2'} className={'flex-1'} />
                <GlowClickable content={{ icon: <GlowIcon Icon={Icons.Cross} type={'large'} /> }} onClick={onClose} />
            </div>
            <GlowTextBody text={t('label.description')} />

            <GlowButton
                label={t('action.login')}
                variant={'primary'}
                size={'large'}
                onClick={onConnectionClick}
                className={'w-full'}
                disabled={isRedirecting}
                isSpinnerActive={isRedirecting}
                dataTestId={DataTestConfig.testIds.wishlistLoginModalConnectionButton}
            />
        </div>
    );
};
