import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeBrandoramaDTO = z.infer<typeof HomeBrandoramaDTO>;
export const HomeBrandoramaDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.Brandorama,
    details: z.object({
        brands: z.array(
            z.object({
                image: ImageDTO.optional(),
                link: z.string().optional(),
                title: z.string().optional(),
                id: z.string().optional(),
                name: z.string().optional(),
            }),
        ),
    }),
});
