'use client';

import { useTranslations } from 'next-intl';
import { ADDED_TO_BASKET_MODAL_ID, buildRoute, NavRoute } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { useUIContext } from '@headless-workspace/core-ui';
import { useNavigation } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    GlowBasketProductPlot,
    GlowButton,
    GlowClickable,
    GlowIcon,
    GlowTypography,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { BasketProductValue, ProductCarousel } from '../../../../../src';
import { useProductRecommendationsList } from './useProductRecommendationsList';

type ProductAddToBasketModalProps = PropsWithLocale & {
    product: BasketProductValue;
    vipBrands: VipBrandsValue;
};

export const ProductAddToBasketModal: Component<ProductAddToBasketModalProps> = ({ product, locale, vipBrands }) => {
    const {
        menuModal: { close },
    } = useUIContext();

    const t = useTranslations('Discover.Pdp.information.basket.modal');
    const navigation = useNavigation();

    const handleRedirect = (route: NavRoute) => (event: React.MouseEvent) => {
        navigation.push(buildRoute(locale, route));
    };

    const { result: productRecommendationsResult, isLoading } = useProductRecommendationsList(product.id, vipBrands);

    const productRecommendations = unwrapResultSuccess(productRecommendationsResult) ?? [];

    const closeModal = () => {
        close(ADDED_TO_BASKET_MODAL_ID);
    };

    return (
        <section className={'flex flex-col bg-background-l0 w-full rounded-0.5'}>
            <div className={'flex justify-between p-1'}>
                <div className={'flex gap-1'}>
                    <GlowIcon Icon={Icons.Check} type={'large'} />
                    <GlowTypography
                        fontSize={'title3'}
                        TagName={'h3'}
                        fontWeight={'bold'}
                        innerHTML={t('label.title')}
                    />
                </div>
                <GlowClickable
                    content={{ icon: <GlowIcon Icon={Icons.Cross} type={'large'} /> }}
                    onClick={closeModal}
                />
            </div>
            <div className={'flex flex-col justify-center gap-1.25 tablet:gap-2'}>
                <GlowBasketProductPlot
                    {...product}
                    discountPercentageLabel={t('label.discountPercentage', {
                        discountPercentage: product.discountPercentage,
                    })}
                    description={undefined}
                    price={
                        product.price > 0
                            ? StringHelpers.formatPrice(product.price, product.currency, locale)
                            : undefined
                    }
                    priceBeforeDiscount={
                        product.priceBeforeDiscount
                            ? StringHelpers.formatPrice(product.priceBeforeDiscount, product.currency, locale)
                            : undefined
                    }
                />
                <div className={'flex flex-col tablet:flex-row justify-center p-1 gap-0.5'}>
                    <GlowButton
                        onClick={handleRedirect('payment')}
                        className={'flex-1 '}
                        label={t('label.payment')}
                        size={'large'}
                        variant={'primary'}
                    />
                    <GlowButton
                        onClick={handleRedirect('basket')}
                        className={'flex-1'}
                        label={t('label.seeBasket')}
                        size={'large'}
                        variant={'secondary'}
                    />
                </div>
            </div>
            {!isLoading && productRecommendations.length > 0 && (
                <div className={'flex flex-col p-1 gap-1.5 tablet:gap-2'}>
                    <GlowTypography
                        fontSize={'title3'}
                        TagName={'h3'}
                        fontWeight={'bold'}
                        className={'inline-flex text-center'}
                        innerHTML={t('carousel.label.title')}
                    />
                    <ProductCarousel
                        products={productRecommendations}
                        prevBtnAriaLabel={t('carousel.ariaLabel.prevBtn')}
                        nextBtnAriaLabel={t('carousel.ariaLabel.nextBtn')}
                        wishlistBtnAriaLabel={t('carousel.ariaLabel.wishlist')}
                        thumbArialLabel={t('carousel.ariaLabel.thumb')}
                        locale={locale}
                        navigationButtonClassName={'hidden desktop:block'}
                    />
                </div>
            )}
        </section>
    );
};
