'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    getProductRecommendationsServerAction,
    ProductRecommendationsErrors,
    TileProductValue,
} from '../../../../server';

type UseProductRecommendationsReturnType = AsyncData<TileProductValue[]>;

export const useProductRecommendationsList = (
    productId: string,
    vipBrands: VipBrandsValue,
): UseProductRecommendationsReturnType => {
    const t = useTranslations('Discover.Pdp.information.basket.modal');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.ProductRecommendations,
        ServerActionName.getProductRecommendations,
        () => getProductRecommendationsServerAction(productId, vipBrands),
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    ProductRecommendationsErrors.Default,
                    error instanceof Error ? error.message : t('error.productRecommendations'),
                ),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
