import clsx from 'clsx';
import { GlowIcon, Icons } from '@headless-workspace/glow-ds';

export const ProductDetailVisualWithoutMedia = () => {
    return (
        <div
            className={clsx(
                'w-productVisual tablet:w-productVisualTablet',
                'desktopS:w-productVisualCarousel desktop:w-productVisualCarouselTablet',
                'bg-background-disabled mx-auto',
                'flex justify-center items-center',
                'aspect-1/1 h-full',
            )}
        >
            <GlowIcon Icon={Icons.CameraSlash} type={'fullSize'} width={'2.5rem'} height={'2.5rem'} />
        </div>
    );
};
