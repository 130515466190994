import { Locale } from '@headless-workspace/config';
import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { DecoderHelpers } from '@headless-workspace/utils';
import { HomeSlotDTO, HomeSlotsContainerDTO, HomeSlotsValue, mapHomeSlots } from '../../../index';

export interface HomeSlotsRepositorySpec {
    getSlots(locale: string): Promise<Result<HomeSlotsValue>>;
}

export class HomeSlotsRepository extends Repository implements HomeSlotsRepositorySpec {
    path = '/content/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getSlots(locale: Locale): Promise<Result<HomeSlotsValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/slots`, { scope: 'homepage', locale });
            const array = HomeSlotsContainerDTO.parse(response);
            const slots = DecoderHelpers.safeParseArray(array.slots, HomeSlotDTO);

            if (slots.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(mapHomeSlots({ slots }, locale));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
