import { HTMLString } from '@headless-workspace/domain/common/server';
import { NewsletterDTO, NewsletterItemDTO } from './NewsletterDTO';

export enum NewsletterType {
    NewsletterBannerOffer = 'NEWSLETTER_BANNER_OFFER',
    newsletterLegalMention = 'NEWSLETTER_LEGAL_MENTIONS_RIGHTS',
    newsletteOfferLegalMention = 'LEGAL_MENTION_OFFER_SUBSCRIPTION',
    NewsletterConfirmationPopup = 'NEWSLETTER_CONFIRMATION_POPUP',
    NewsletterSuccessfulSubscription = 'NEWSLETTER_SUCCESSFUL_SUBSCRIPTION',
}

export type NewsletterInformation = {
    id: string;
    title: string;
    subtitle?: string;
    name?: string;
    content: HTMLString;
};

export type NewsletterConfirmationModal = NewsletterInformation & {
    confirmationPopupCTALabel: string;
    editPopupCTALabel: string;
};

export type NewsletterValue = {
    newsletterBannerOffer: NewsletterInformation;
    newsletterLegalMention: NewsletterInformation;
    newsletteOfferLegalMention: NewsletterInformation;
    newsletterConfirmationPopup: NewsletterConfirmationModal;
    newsletterSuccessfulSubscription: NewsletterInformation;
    isNewsletterBoostEnabled: boolean;
};

export const mapNewsletterItem = (dto: NewsletterItemDTO): NewsletterInformation | NewsletterConfirmationModal => {
    const confirmationPopupCTA = dto.ctas && dto.ctas?.length > 1 ? dto.ctas?.[0] : undefined;
    const editPopupCTA = dto.ctas && dto.ctas?.length > 1 ? dto.ctas?.[1] : undefined;

    return {
        id: dto.id,
        title: dto.title ?? '',
        subtitle: dto.subtitle,
        name: dto.name,
        content: HTMLString(dto.description ?? ''),
        confirmationPopupCTALabel: confirmationPopupCTA?.label ?? '',
        editPopupCTALabel: editPopupCTA?.label ?? '',
    };
};

export const mapNewsletter = (dto: NewsletterDTO): NewsletterValue => {
    return dto.details.items.reduce((acc, item) => {
        switch (item.id) {
            case NewsletterType.NewsletterBannerOffer:
                acc.newsletterBannerOffer = mapNewsletterItem(item);
                break;
            case NewsletterType.newsletterLegalMention:
                acc.newsletterLegalMention = mapNewsletterItem(item);
                break;
            case NewsletterType.newsletteOfferLegalMention:
                acc.newsletteOfferLegalMention = mapNewsletterItem(item);
                break;
            case NewsletterType.NewsletterConfirmationPopup:
                acc.newsletterConfirmationPopup = mapNewsletterItem(item) as NewsletterConfirmationModal;
                break;
            case NewsletterType.NewsletterSuccessfulSubscription:
                acc.newsletterSuccessfulSubscription = mapNewsletterItem(item);
                break;
            default:
                break;
        }
        return acc;
    }, {} as NewsletterValue);
};
