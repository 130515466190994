import React from 'react';
import { UIFastAccessList } from '@headless-workspace/core-ui';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeFastAccessValue } from '../../../../../src';

type HomeFastAccessSectionProps = PropsWithClassName & {
    fastAccess: HomeFastAccessValue;
};
export const HomeFastAccessSection: Component<HomeFastAccessSectionProps> = ({ fastAccess, className }) => {
    return (
        <section className={className}>
            <UIFastAccessList fastAccessLinks={fastAccess.links} />
        </section>
    );
};
