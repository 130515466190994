import { GlowCheckbox, GlowProgressBar, GlowRatingStar, GlowTextBody } from '@headless-workspace/glow-ds';
import { RatingFilterData } from '../../../../../src';

type ReviewScoreFilterProps = {
    selectedRatings: number[];
    ratingFilter: RatingFilterData[];
    onCheckboxChange: (ratingValue: number) => void;
};

export const ReviewScoreFilter = ({ ratingFilter, onCheckboxChange, selectedRatings }: ReviewScoreFilterProps) => {
    const handleCheckboxChange = (rating: number) => () => {
        onCheckboxChange(rating);
    };

    const isSelected = (ratingValue: number) => selectedRatings.includes(ratingValue);

    return ratingFilter.map(({ ratingValue, reviewsCountPerRating, progressValue }) => (
        <div key={ratingValue} className={'flex gap-1 items-center justify-center'}>
            <div className={'flex gap-0.25 items-center justify-center'}>
                <GlowCheckbox
                    label={ratingValue}
                    name={`${ratingValue}`}
                    id={`rating-${ratingValue}`}
                    onChange={handleCheckboxChange(ratingValue)}
                    checked={isSelected(ratingValue)}
                />
                <GlowRatingStar fillRatio={1} />
                <GlowTextBody text={`(${reviewsCountPerRating})`} />
            </div>
            <GlowProgressBar value={progressValue} />
        </div>
    ));
};
