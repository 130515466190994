import React from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute } from '@headless-workspace/config';
import { UIBrandoramaContainer } from '@headless-workspace/core-ui';
import { PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeBrandoramaValue } from '../../../../../src';

type HomeBrandoramaSectionProps = PropsWithLocale &
    PropsWithClassName & {
        brandorama: HomeBrandoramaValue;
    };

export const HomeBrandoramaSection: Component<HomeBrandoramaSectionProps> = ({ brandorama, locale, className }) => {
    const t = useTranslations('Discover.Home');

    return (
        <UIBrandoramaContainer
            title={t('brandorama.title')}
            subtitle={t('brandorama.subtitle')}
            button={{
                href: buildRoute(locale, 'allBrands'),
                label: t('brandorama.seeAll'),
            }}
            brandoramaTiles={brandorama.tiles}
            className={className}
        />
    );
};
