export type LoyaltyConfigsValue = Readonly<EnableOffers>;

export type EnableOffers = {
    isEnableOffers?: boolean;
};

export const mapLoyaltyConfigsValue = (isEnableOffers?: boolean): LoyaltyConfigsValue => {
    return {
        isEnableOffers: isEnableOffers,
    };
};
