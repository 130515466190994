'use client';

import { useCallback, useMemo, useState } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { ProductType } from '@headless-workspace/domain/common/client';
import {
    GlowPills,
    GlowPrice,
    GlowSelector,
    GlowTextBody,
    Pill,
    PropsWithFontFamily,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component, NoEmptyArray } from '@headless-workspace/typings';
import { FunctionHelpers, StringHelpers } from '@headless-workspace/utils';
import { ChipColor, ProductDetailInfoModal } from '../../../../../client';
import { CapacityFormat, ProductDetailVariantValue } from '../../../../../server';

const FILTER_ALL_FORMATS_ID = 'all-formats';

type ProductDetailSelectorMenuProps = PropsWithLocale &
    PropsWithFontFamily & {
        type: ProductType;
        formats: CapacityFormat[];
        variants: ProductDetailVariantValue[];
        priceAlwaysBlack?: boolean;
        enableDiscountInfo?: boolean;
        currency: string;
        imgShapeClassName?: string;
    };

export const ProductDetailSelectorMenu: Component<ProductDetailSelectorMenuProps> = ({
    type,
    formats,
    variants,
    fontFamily,
    priceAlwaysBlack,
    enableDiscountInfo,
    currency,
    imgShapeClassName,
    locale,
}) => {
    const t = useTranslations('Discover.Pdp.information.selector');
    const [filteredVariants, setFilteredVariants] = useState<ProductDetailVariantValue[]>(variants);

    const menuTitle = useMemo(() => {
        const titleMap = {
            [ProductType.PRODUCT_SET]: '',
            [ProductType.MONO_SKU]: '',
            [ProductType.MULTI_SKU_SIZE]: t('modal.label.selectMultiSkuSizeTitle'),
            [ProductType.MULTI_SKU_SHADE_UNI]: t('modal.label.selectMultiSkuShadeTitle'),
            [ProductType.MULTI_SKU_SHADE_DIFF]: t('modal.label.selectMultiSkuShadeTitle'),
        };

        return titleMap[type];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const pills: NoEmptyArray<Pill> = useMemo(() => {
        const allFormatsPill = { id: FILTER_ALL_FORMATS_ID, label: t('modal.action.allFormats') };
        const formatPills = formats.map((format) => ({
            id: format.toString(),
            label: format.toString(),
        }));
        return [allFormatsPill, ...formatPills];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formats]);

    const getStockInformation = (isAvailable: boolean) => {
        if (isAvailable) {
            return { label: t('modal.label.available'), chipColor: ChipColor.Success };
        }

        return { label: t('modal.label.unavailable'), chipColor: ChipColor.Error };
    };

    const onPillSelected = useCallback(
        (selectedPillId: string) => {
            if (selectedPillId === FILTER_ALL_FORMATS_ID) {
                setFilteredVariants(variants);
            } else {
                setFilteredVariants(variants.filter((variant) => variant.format === selectedPillId));
            }
        },
        [variants],
    );

    const variantSelectorList = useMemo(() => {
        return filteredVariants.map((variant) => (
            <div key={variant.id} className={'flex flex-row justify-between'}>
                <GlowSelector
                    image={variant.thumbnailImage}
                    ImageTagName={Image}
                    imageClassName={imgShapeClassName}
                    titleContent={{
                        label: variant.name,
                        fontWeight: 'medium',
                    }}
                    chipWithText={getStockInformation(variant.isAvailable)}
                    RightElement={
                        <GlowPrice
                            price={
                                variant.price > 0
                                    ? StringHelpers.formatPrice(variant.price, currency, locale)
                                    : undefined
                            }
                            priceBeforeDiscount={
                                variant.priceBeforeDiscount
                                    ? StringHelpers.formatPrice(variant.priceBeforeDiscount, currency, locale)
                                    : undefined
                            }
                            discountPercentageLabel={t('modal.label.discountPercentage', {
                                discountPercentage: variant.discountPercentage,
                            })}
                            priceAlwaysBlack={priceAlwaysBlack}
                            enableDiscountInfo={enableDiscountInfo}
                            fontFamily={fontFamily}
                            className={'items-end'}
                        />
                    }
                    fontFamily={fontFamily}
                    onClick={FunctionHelpers.emptyFn}
                />
            </div>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency, enableDiscountInfo, filteredVariants, fontFamily, getStockInformation, locale, priceAlwaysBlack]);

    return (
        <ProductDetailInfoModal
            fontFamily={fontFamily}
            title={menuTitle}
            renderInformation={
                <div className={'flex flex-col gap-1.5'}>
                    {formats.length > 1 && (
                        <div className={'flex flex-col gap-1'}>
                            <GlowTextBody
                                text={t('modal.label.formats', {
                                    formatCount: formats.length + 1,
                                })}
                                fontWeight={'bold'}
                                fontFamily={fontFamily}
                            />
                            <GlowPills
                                type={'radio'}
                                contents={pills}
                                defaultSelected={FILTER_ALL_FORMATS_ID}
                                onChange={onPillSelected}
                                className={'flex flex-row flex-wrap gap-0.5'}
                                fontFamily={fontFamily}
                            />
                        </div>
                    )}
                    <div className={'flex flex-col gap-1'}>
                        <GlowTextBody
                            text={t('modal.label.products', {
                                variantCount: filteredVariants.length,
                            })}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                        />
                        <div className={'flex flex-col gap-0.5'}>{variantSelectorList}</div>
                    </div>
                </div>
            }
            contentContainerClassName={'p-1 overflow-y-auto'}
        />
    );
};
