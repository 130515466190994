import { DefaultValueConfig, Locale } from '@headless-workspace/config';
import { mapURLToImage, ProductType } from '@headless-workspace/domain/common/client';
import {
    BaseProductValue,
    mapProductBrandData,
    mapProductPriceData,
    VipBrandsValue,
} from '@headless-workspace/domain/common/server';
import { LanguageHelpers, ObjectHelpers, PredicateHelpers, UrlHelpers } from '@headless-workspace/utils';
import { TileProductDTO, VariantDTO } from './TileProductDTO';

type ProductTag = {
    id: string;
    label: string;
};

export type TileProductValue = Readonly<
    BaseProductValue & {
        minPrice?: number;
        totalIndividualPrice?: number;
        pricePerUnit?: string;

        reviewsCount?: number;
        rating?: number;

        defaultVariantId: string;
        variantName?: string;
        variantsCount?: number;

        isEngravable: boolean;
        sku: string;
        type: ProductType;
        tags?: ProductTag[];
    }
>;

const findDefaultVariant = (variants: VariantDTO[]): VariantDTO | undefined => {
    return variants.find(({ isDefault }) => isDefault);
};

export const mapTileProduct = (dto: TileProductDTO, vipBrands: VipBrandsValue, locale: Locale): TileProductValue => {
    const [name, ...description] = dto.name.split(' - ');
    const defaultVariant = dto.isMaster ? findDefaultVariant(dto.variants) : undefined;
    const defaultImage = ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder);
    const imageAndVariantName =
        defaultVariant && defaultVariant.imageURL && defaultVariant.description
            ? {
                  image: mapURLToImage(defaultVariant.imageURL) ?? defaultImage,
                  variantName: defaultVariant.description,
              }
            : {
                  image: mapURLToImage(dto.imageUrl) ?? defaultImage,
                  variantName: dto.description,
              };

    const language = LanguageHelpers.mapLocaleToLanguage(locale);

    return {
        id: dto.id,
        name,
        description: description.join(' - '),
        href: UrlHelpers.getHrefPathname(dto.url ?? DefaultValueConfig.href, language),
        isEngravable: dto.isEngravable,
        minPrice: dto.minPrice,
        pricePerUnit: dto.pricePerUnit,
        totalIndividualPrice: dto.valuePrice,
        reviewsCount: dto.reviewsCount,
        rating: dto.rating,
        defaultVariantId: dto.defaultVariantId ?? '',
        sku: dto.defaultVariantId ?? dto.id,
        variantsCount: dto.variantsCount,
        type: ProductType[dto.type],
        tags: dto.flags
            ?.map(({ id, label }) => (label ? { id, label } : null))
            .filter(PredicateHelpers.notNullOrUndefined),
        ...mapProductBrandData(dto, vipBrands),
        ...mapProductPriceData(dto, dto.currency),
        ...imageAndVariantName,
    };
};
