import { StockStatus } from '@headless-workspace/domain/common/client';

export enum ChipColor {
    Success = 'success',
    Error = 'error',
}

export const mapToChipColor = (status?: StockStatus): ChipColor => {
    if (status === StockStatus.Available) {
        return ChipColor.Success;
    }
    return ChipColor.Error;
};
