import { InnerHTML } from '@headless-workspace/domain/common/client';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { GlowTextBody, PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type ProductDetailBuyNowPayLaterTextProps = PropsWithFontFamily & {
    buyNowPayLaterContent: HTMLString;
};

export const ProductDetailBuyNowPayLaterText: Component<ProductDetailBuyNowPayLaterTextProps> = ({
    buyNowPayLaterContent,
    fontFamily,
}) => {
    return (
        <GlowTextBody
            fontFamily={fontFamily}
            className={'mt-1'}
            innerHTML={<InnerHTML rawHtml={buyNowPayLaterContent} />}
        />
    );
};
