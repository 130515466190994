import { CountryKeyValue, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultEmpty, ResultSuccess } from '@headless-workspace/core-domain';
import { LanguageHelpers } from '@headless-workspace/utils';

const ENABLE_NEWS_LETTER_BOOST_OPTIN_OFFER_KEY = 'enableNewsletterBoostOptinOffer';

export interface NewsletterConfigRepositorySpec {
    fetchEnableNewsletterBoostOptinOfferConfiguration(locale: Locale): Promise<Result<boolean | null>>;
}

export class NewsletterConfigRepository extends ConfigByKeyRepository implements NewsletterConfigRepositorySpec {
    async fetchEnableNewsletterBoostOptinOfferConfiguration(locale: Locale): Promise<Result<boolean | null>> {
        const enableNewsletterBoostOptinOfferConfigsResult = await this.getConfigByKey(
            ENABLE_NEWS_LETTER_BOOST_OPTIN_OFFER_KEY,
            { locale },
            (value) => LanguageHelpers.retrieveDataByCountry(value as CountryKeyValue<boolean>, locale),
        );

        if (enableNewsletterBoostOptinOfferConfigsResult.type !== 'success') {
            return ResultEmpty();
        }

        return ResultSuccess(enableNewsletterBoostOptinOfferConfigsResult.data);
    }
}
