import { DataLayerEventLabel } from '@headless-workspace/domain/common/client';
import useSWRMutation from 'swr/mutation';
import { newsletterSubscribeServerAction } from '../../../../../src';

const NEWSLETTER_SUBSCRIBE_KEY = 'newsletterSubscribeKey';

export const useTriggerNewsletterSubscription = (
    email: string,
    onSubscribe: () => void,
    trackEventClick: (label: DataLayerEventLabel) => void,
) => {
    const { trigger: triggerSubscribe } = useSWRMutation(NEWSLETTER_SUBSCRIBE_KEY, async () => {
        const response = await newsletterSubscribeServerAction(email);
        if (response.type === 'success') {
            onSubscribe();
            trackEventClick(DataLayerEventLabel.Confirm);
        }
    });
    return triggerSubscribe;
};
