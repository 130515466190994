import { DefaultValueConfig } from '@headless-workspace/config';
import { mapImage } from '@headless-workspace/domain/common/client';
import { Image } from '@headless-workspace/typings';
import { ObjectHelpers } from '@headless-workspace/utils';
import { HomeButtonsWebmoV2DTO, HomeSlotBase, HomeSlotType, mapHomeSlotBase } from '../../../../index';

export type HomeFastAccessValue = HomeSlotBase & {
    type: HomeSlotType.FastAccess;
    links: {
        label: string;
        subLabel?: string;
        href: string;
        image: Image;
    }[];
};

export const mapHomeFastAccess = (dto: HomeButtonsWebmoV2DTO): HomeFastAccessValue => {
    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.FastAccess,
        links: dto.details.buttons.map((button) => ({
            label: button.title,
            subLabel: button.subTitle,
            href: button.link,
            image: mapImage(button.image) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),
        })),
    };
};
