import { Locale } from '@headless-workspace/config';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { ProductDetailDeliveryInfoValue, productDetailDeliveryInfoValueMock } from '../../productDetail';

export interface ProductDetailDeliveryInfoRepositorySpec {
    getProductDeliveryInformation(locale: Locale, id: string): Promise<Result<ProductDetailDeliveryInfoValue>>;
}

export class ProductDetailDeliveryInfoRepository extends Repository implements ProductDetailDeliveryInfoRepositorySpec {
    readonly path = '/catalog/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getProductDeliveryInformation(locale: Locale, id: string): Promise<Result<ProductDetailDeliveryInfoValue>> {
        try {
            // FIXME: implement the fetch when SODA delivered the API with deliveryAvailability attribute on /products/:id endpoint
            return ResultSuccess(productDetailDeliveryInfoValueMock);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
