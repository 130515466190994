import { SchemaOrgItemType } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { AsyncComponent, VipBrandsValue } from '@headless-workspace/domain/common/server';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import {
    getPromotionInfoServerAction,
    ProductDetailContainerSection,
    ProductDetailValue,
    RecommendedProductSlot,
} from '../../../../src';
import { ProductDetailCarouselContainer } from './../../../client';
import { ProductDetailFooterSection } from './ProductDetailFooterSection';
import { ProductDetailBrandCornerSection } from './brandCorner';
import { ProductDetailRatingsAndReviewsSection } from './ratingsAndReviews';

type ProductDetailTemplateProps = PropsWithLocale & {
    product: ProductDetailValue;
    vipBrands: VipBrandsValue;
    redDotText?: string;
};

export const ProductDetailTemplate: AsyncComponent<ProductDetailTemplateProps> = async ({
    product,
    locale,
    vipBrands,
    redDotText,
}) => {
    const promotionInformation =
        product.promotion || product.price !== product.originalPrice
            ? unwrapResultSuccess(await getPromotionInfoServerAction(product.promotion))
            : undefined;

    const fontFamily = product.isArialFont ? 'arial' : 'brand';

    return (
        <section
            id={'product-detail-template'}
            className={'flex flex-col gap-2 desktop:gap-4'}
            itemScope
            itemType={SchemaOrgItemType.Product}
            data-product-id={product.id}
        >
            <ProductDetailContainerSection
                promotionInformation={promotionInformation}
                product={product}
                locale={locale}
                vipBrands={vipBrands}
                fontFamily={fontFamily}
                redDotText={redDotText}
            />
            <ProductDetailCarouselContainer
                fontFamily={fontFamily}
                locale={locale}
                vipBrands={vipBrands}
                slot={RecommendedProductSlot.FIRST}
            />
            <ProductDetailRatingsAndReviewsSection
                reviewCount={product.reviewCount}
                rating={product.rating}
                productId={product.id}
                locale={locale}
            />
            <ProductDetailCarouselContainer
                fontFamily={fontFamily}
                locale={locale}
                vipBrands={vipBrands}
                slot={RecommendedProductSlot.SECOND}
            />
            <ProductDetailBrandCornerSection fontFamily={fontFamily} />
            <ProductDetailFooterSection />
        </section>
    );
};
