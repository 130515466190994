import { HolidaysValue } from './HolidaysValue';

export const addBusinessDays = (date: Date, daysToAdd: number, holidays?: HolidaysValue) => {
    const resultDate = new Date(date);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
        resultDate.setDate(resultDate.getDate() + 1);

        // Check if date is a weekend
        const isWeekend = resultDate.getDay() === 0 || resultDate.getDay() === 6;

        // Check if date is a holiday
        const month = resultDate.getMonth() + 1;
        const day = resultDate.getDate();
        const isHoliday = holidays?.[month]?.includes(day) ?? false;

        if (isWeekend || isHoliday) {
            continue;
        }
        addedDays++;
    }
    return resultDate;
};
