import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { StoreDTO, StoreDTOList } from './StoreDTO';
import { mapStoreInfo, StoreInfo } from './StoreInfo';

export interface StoreRepositorySpec {
    getStoreById(locale: string, storeId: string): Promise<Result<StoreInfo>>;
    getStoresByIdsAndLocation(
        locale: string,
        productIds: string[],
        latitude: number,
        longitude: number,
    ): Promise<Result<StoreInfo[]>>;
}

export class StoreRepository extends Repository implements StoreRepositorySpec {
    path = '/store/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getStoreById(locale: string, storeId: string): Promise<Result<StoreInfo>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/stores/${storeId}`, { locale });
            const result = StoreDTO.parse(response);

            return ResultSuccess(mapStoreInfo(result));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async getStoresByIdsAndLocation(
        locale: string,
        productIds: string[],
        latitude: number,
        longitude: number,
    ): Promise<Result<StoreInfo[]>> {
        try {
            const productIdsMapped = productIds.join(',');

            const response = await this.datasource.getResource(`${this.path}/stores`, {
                locale,
                productIds: productIdsMapped,
                latitude,
                longitude,
            });
            const results = StoreDTOList.parse(response);

            return ResultSuccess(results.stores.map(mapStoreInfo));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError);
        }
    }
}
