import { Locale } from '@headless-workspace/config';
import { DisplayDevice, mapDeviceDisplay } from '@headless-workspace/domain/common/client';
import { PredicateHelpers } from '@headless-workspace/utils';
import {
    HomeBeautyTipsValue,
    HomeBrandoramaValue,
    HomeCarouselValue,
    HomeFastAccessValue,
    HomeMainBannerValue,
    HomeMobileButtonsValue,
    HomeOpenBarServicesValue,
    HomeSlotDTO,
    HomeSlotDTOTypes,
    HomeSlotsDTO,
    HomeUnderBannersValue,
    mapHomeBanners,
    mapHomeBeautyTips,
    mapHomeBrandorama,
    mapHomeCarousel,
    mapHomeFastAccess,
    mapHomeMobileButtons,
    mapHomeOpenBarServices,
} from '../../../index';

export type HomeSlotBase = Readonly<{
    id: string;
    display: DisplayDevice;
}>;

export const mapHomeSlotBase = (dto: HomeSlotDTO): HomeSlotBase => {
    return {
        id: dto.id,
        display: mapDeviceDisplay(dto.display),
    };
};

export type HomeSlotValue =
    | HomeBrandoramaValue
    | HomeOpenBarServicesValue
    | HomeCarouselValue
    | HomeMobileButtonsValue
    | HomeFastAccessValue
    | HomeMainBannerValue
    | HomeUnderBannersValue
    | HomeBeautyTipsValue;

export type HomeSlotsValue = HomeSlotValue[];

export const mapHomeSlot = (slot: HomeSlotDTO, locale: Locale): HomeSlotValue | null => {
    switch (slot.type) {
        case HomeSlotDTOTypes.ButtonsWebmo.value:
            return mapHomeMobileButtons(slot);
        case HomeSlotDTOTypes.ProductsList.value:
        case HomeSlotDTOTypes.ProductRecommendations.value:
            return mapHomeCarousel(slot, locale);
        case HomeSlotDTOTypes.Brandorama.value:
            return mapHomeBrandorama(slot, locale);
        case HomeSlotDTOTypes.ButtonsWebmoV2.value:
            return mapHomeFastAccess(slot);
        case HomeSlotDTOTypes.OpenBarServices.value:
            return mapHomeOpenBarServices(slot, locale);
        case HomeSlotDTOTypes.MozaicWindows.value:
        case HomeSlotDTOTypes.UnderBanners.value:
            return mapHomeBanners(slot);
        case HomeSlotDTOTypes.BeautyTips.value:
            return mapHomeBeautyTips(slot, locale);
        default:
            return null;
    }
};

export const mapHomeSlots = (dto: HomeSlotsDTO, locale: Locale): HomeSlotsValue => {
    const slots = dto.slots.map((slot) => mapHomeSlot(slot, locale));
    return slots.filter(PredicateHelpers.notNullOrUndefined) as HomeSlotsValue;
};
