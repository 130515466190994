'use client';

import { useEffect } from 'react';
import { Locale } from '@headless-workspace/config';
import { DataLayerKey, HeaderDidMount, useDataLayer } from '@headless-workspace/domain/common/client';
import { Component } from '@headless-workspace/typings';

type ProductDetailDidMountProps = {
    locale: Locale;
};

const DATA_LAYER_CONNECTION_ACTION = '';

export const ProductDetailDidMount: Component<ProductDetailDidMountProps> = ({ locale }) => {
    const { trackEvent, addVariable, addVariables } = useDataLayer({
        [DataLayerKey.EnvTemplate]: 'product page',
        [DataLayerKey.ProductDisplayType]: 'product page',
    });

    useEffect(() => {
        if (typeof document !== 'undefined') {
            const productElement = document.querySelector('#pdp-container');
            const productId = productElement?.getAttribute('data-product-id') ?? '';
            addVariable(DataLayerKey.ProductPid, productId);
        }
    }, [addVariable]);

    return (
        <HeaderDidMount
            dataLayerConnectionAction={DATA_LAYER_CONNECTION_ACTION}
            trackEvent={trackEvent}
            locale={locale}
            addVariables={addVariables}
        />
    );
};
