import { StockStatus } from '@headless-workspace/domain/common/client';
import { productDetailDeliveryTermsAndConditions } from './mocks/productDetailDeliveryTermsAndConditions';

export type ProductDetailDeliveryShippingValue = {
    stockStatus: StockStatus;
};

export type ProductDetailDeliveryStoreValue = {
    stockStatus: StockStatus;
};

export type ProductDetailDeliveryInfoMockType = typeof productDetailDeliveryTermsAndConditions;

export type ProductDetailDeliveryInfoValue = {
    shipping: ProductDetailDeliveryShippingValue;
    allStores: ProductDetailDeliveryStoreValue;
    currentStore?: ProductDetailDeliveryStoreValue;
    termsAndConditions: ProductDetailDeliveryInfoMockType;
};

export const productDetailDeliveryInfoValueMock: ProductDetailDeliveryInfoValue = {
    shipping: {
        stockStatus: StockStatus.Available,
    },
    allStores: {
        stockStatus: StockStatus.Available,
    },
    currentStore: {
        stockStatus: StockStatus.Available,
    },
    termsAndConditions: productDetailDeliveryTermsAndConditions,
};
