import React from 'react';
import { GlowTextBodySmall } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export type CountDownType = {
    value: number;
    label: string;
};

type CountDownProps = {
    values: CountDownType[];
};

export const CountDown: Component<CountDownProps> = ({ values }) => {
    return (
        <GlowTextBodySmall
            text={values.map(({ value, label }) => `${value}${label}`).join(' ')}
            suppressHydrationWarning
            fontWeight={'bold'}
        />
    );
};
