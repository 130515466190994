import { ProductTypeDTO } from '@headless-workspace/domain/common/client';
import { ExtendedProductDTO } from '@headless-workspace/domain/common/server';
import { z } from 'zod';

const VariantDTO = z.object({
    id: z.string().optional(),
    imageURL: z.string().optional(),
    description: z.string().optional(),
    isDefault: z.boolean().optional(),
});

export type VariantDTO = z.infer<typeof VariantDTO>;

export const TileProductDTO = ExtendedProductDTO.extend({
    pricePerUnit: z.string().optional(),
    valuePrice: z.number().optional(),
    reviewsCount: z.number().optional(),
    rating: z.number().optional(),
    isMaster: z.boolean().default(false),
    variants: z.array(VariantDTO).default([]),
    defaultVariantId: z.string().optional(),
    variantsCount: z.number().optional(),
    type: ProductTypeDTO,
    flags: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
});

export type TileProductDTO = z.infer<typeof TileProductDTO>;

export const TileProductsContainerDTO = z.object({
    products: z.array(z.unknown()),
});
