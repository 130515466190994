import { DefaultValueConfig } from '@headless-workspace/config';
import { UIMainBanner, UIUnderBanner } from '@headless-workspace/core-ui';
import { DataLayerClassName, InnerHTML, RichText } from '@headless-workspace/domain/common/client';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeBannerBase, HomeSlotType } from '../../../../../src';

type HomeBannerProps = PropsWithClassName & {
    type: HomeSlotType.MainBanner | HomeSlotType.UnderBanners;
    banner: HomeBannerBase;
};

export const HomeBanner: Component<HomeBannerProps> = ({ className, type, banner }) => {
    const Banner = type === HomeSlotType.MainBanner ? UIMainBanner : UIUnderBanner;

    return (
        <Banner
            className={className}
            title={banner.title && <InnerHTML rawHtml={banner.title} />}
            subTitle={banner.subTitle && <InnerHTML rawHtml={banner.subTitle} />}
            label={banner.label && <InnerHTML rawHtml={banner.label} />}
            content={banner.content && <InnerHTML rawHtml={banner.content} />}
            image={{
                src: banner.image,
                href: banner.href,
            }}
            button={banner.redirectionCTA}
            dataLayer={{
                ...banner.dataLayer,
                className: DataLayerClassName.banner,
            }}
            legalMention={
                banner.legalMention && (
                    <RichText
                        TagName={'span'}
                        fontSize={'caption'}
                        className={'line-clamp-2'}
                        messageKey={'Discover.Home.banner.format.conditions'}
                        color={'tertiary'}
                        values={{
                            prefix: banner.legalMention.prefix,
                            label: banner.legalMention.cta?.label,
                        }}
                        links={{
                            link: { href: banner.legalMention.cta?.href ?? DefaultValueConfig.href },
                        }}
                    />
                )
            }
            tags={banner.tags}
        />
    );
};
