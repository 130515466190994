'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { Locale } from '@headless-workspace/config';
import { DynamicLabelValue, HeaderSlotType, HeaderSlotValue, Result } from '@headless-workspace/core-domain';
import { UIHoverBanner, UIHoverBannerButton } from '@headless-workspace/core-ui';
import { getIcon, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FormatHelpers } from '@headless-workspace/utils';
import { FavoriteStoreInfo, StoreInfo, useFavoriteStoreInfo, useFindSpecificityRule } from '../../../../../src';
import { StoreLocator } from './StoreLocator';
import { StoresBannerSkeleton } from './StoresBannerSkeleton';
import styles from './storesBanner.module.css';

interface FavoriteStoreItemsProps {
    favoriteStoreItems: string[];
}

type StoresBannerProps = PropsWithLocale & HeaderSlotValue & FavoriteStoreItemsProps;

export const StoresBanner: Component<StoresBannerProps> = ({
    type,
    iconName,
    label,
    href,
    displayClassName,
    labelDisplayClassName,
    dynamicLabel,
    locale,
    favoriteStoreItems,
}) => {
    const t = useTranslations('Header.storesAndServices');
    const { isLoading, result, deleteFavoriteStore } = useFavoriteStoreInfo();
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

    const translatedDays = [
        t('banner.label.days.1'),
        t('banner.label.days.2'),
        t('banner.label.days.3'),
        t('banner.label.days.4'),
        t('banner.label.days.5'),
        t('banner.label.days.6'),
        t('banner.label.days.7'),
    ];

    const onDelete = useCallback(() => {
        setIsDeleteInProgress(true);
        deleteFavoriteStore();
    }, [deleteFavoriteStore]);

    useEffect(() => {
        setIsDeleteInProgress(false);
    }, [result.type]);

    const TitleElement = dynamicLabel ? (
        <StoreDynamicTitleButton
            type={type}
            iconName={iconName}
            label={label}
            href={href}
            dynamicLabel={dynamicLabel}
            displayClassName={displayClassName}
            labelDisplayClassName={labelDisplayClassName}
        />
    ) : (
        <UIHoverBannerButton
            Icon={getIcon(iconName)}
            label={label}
            href={href}
            className={clsx('h-full', displayClassName)}
            isLoading={false}
        />
    );

    if (!TitleElement) {
        return null;
    }

    const renderFavoriteStoreInfo = (storeInfo: StoreInfo) => (
        <FavoriteStoreInfo
            id={storeInfo.id}
            name={storeInfo.name}
            address={FormatHelpers.formatAddress(storeInfo.address, storeInfo.city, storeInfo.postalCode)}
            openHours={FormatHelpers.formatOpenHours(
                storeInfo.schedule.map(({ day, workingHours }) => ({ day, hours: workingHours ?? [] })),
                translatedDays,
                t('banner.label.closed'),
            )}
            availableServices={storeInfo.availableServices}
            deleteFavoriteStore={onDelete}
        />
    );

    const renderContent = (result: Result<StoreInfo>, isLoading: boolean, locale: Locale) => {
        if (isDeleteInProgress || isLoading) {
            return <StoresBannerSkeleton locale={locale} />;
        }

        if (result.type === 'empty' || result.type === 'failure') {
            return <StoreLocator locale={locale} favoriteStoreItems={favoriteStoreItems} />;
        }

        return renderFavoriteStoreInfo(result.data);
    };

    return (
        <UIHoverBanner
            className={clsx('flex-row justify-start gap-x-2.5 gap-y-2 w-storesBanner', styles.storesBanner)}
            hoverItem={TitleElement}
        >
            {renderContent(result, isLoading, locale)}
        </UIHoverBanner>
    );
};

type StoreDynamicTitleButtonProps = {
    type: HeaderSlotType;
    iconName: string;
    label: string;
    href: string;
    dynamicLabel: DynamicLabelValue[];
    displayClassName?: string;
    labelDisplayClassName?: string;
};

const StoreDynamicTitleButton: Component<StoreDynamicTitleButtonProps> = ({
    type,
    iconName,
    label,
    href,
    dynamicLabel,
    displayClassName,
}) => {
    const specificityRuleAsyncData = useFindSpecificityRule(type, dynamicLabel);

    if (specificityRuleAsyncData.isLoading || specificityRuleAsyncData.result.type !== 'success') {
        return (
            <UIHoverBannerButton
                Icon={getIcon(iconName)}
                label={label}
                ariaLabel={label}
                labelClassName={displayClassName}
                href={href}
                className={clsx('h-full', displayClassName)}
                isLoading={false}
            />
        );
    }

    return (
        <UIHoverBannerButton
            Icon={getIcon(iconName)}
            label={specificityRuleAsyncData.result.data.text}
            ariaLabel={specificityRuleAsyncData.result.data.text}
            labelClassName={specificityRuleAsyncData.result.data.displayClassName}
            href={specificityRuleAsyncData.result.data.href}
            className={clsx('h-full', displayClassName)}
            isLoading={false}
        />
    );
};
