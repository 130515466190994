'use client';

import { ServerActionName } from '@headless-workspace/config';
import { ResultServerActionType } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWRMutation } from '@headless-workspace/domain/common/client';
import { TriggerWithArgs } from 'swr/mutation';
import { updateWishlistServerAction, WishlistUpdateMode } from '../../../server';

type MutationTriggerReturnType = TriggerWithArgs<
    ResultServerActionType<boolean>,
    unknown,
    typeof UserRevalidationKey.Wishlist,
    string
>;

type UseWishlistMutationsReturnType = {
    add: MutationTriggerReturnType;
    remove: MutationTriggerReturnType;
};

export const useWishlistMutations = (): UseWishlistMutationsReturnType => {
    const { trigger: additionTrigger } = useSafeSWRMutation<boolean, string>(
        UserRevalidationKey.Wishlist,
        ServerActionName.updateWishlist,
        (_, { arg }) => updateWishlistServerAction(WishlistUpdateMode.Add, arg),
    );

    const { trigger: removalTrigger } = useSafeSWRMutation<boolean, string>(
        UserRevalidationKey.Wishlist,
        ServerActionName.updateWishlist,
        (_, { arg }) => updateWishlistServerAction(WishlistUpdateMode.Delete, arg),
    );
    return {
        add: additionTrigger,
        remove: removalTrigger,
    };
};
