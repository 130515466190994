import React from 'react';
import clsx from 'clsx';
import { GlowSkeleton } from '@headless-workspace/glow-ds';

export const NewsletterSkeleton = () => {
    return (
        <div className={'flex gap-1.5 flex-col gap tablet:flex-row w-full'}>
            <GlowSkeleton
                height={'100%'}
                containerClassName={'h-newsletterIcon w-newsletterIcon'}
                itemClassName={'rounded-0.5'}
            />
            <div
                className={clsx(
                    'flex flex-col gap-0.5 flex-1',
                    //max-w-[30rem]: Specific to this section only; avoid using useTailwindBreakpoint. Also, ensure that there is no overflow issue
                    'max-w-[30rem]',
                )}
            >
                <GlowSkeleton height={'1.75rem'} itemClassName={'rounded-0.5'} width={'80%'} />
                <GlowSkeleton height={'1.25rem'} width={'100%'} itemClassName={'rounded-0.5'} />
                <GlowSkeleton height={'1.25rem'} width={'60%'} itemClassName={'rounded-0.5 tablet:hidden'} />
            </div>
        </div>
    );
};
