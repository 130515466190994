'use client';

import { Position, WISHLIST_MODAL_ID } from '@headless-workspace/config';
import { SideMenuModal, useUIContext } from '@headless-workspace/core-ui';
import { GlowWishlistButton, ModalCommonClasses, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useWishlist, WishlistModal } from '../../../../../client';

type SelectorWishlistButtonProps = PropsWithLocale & {
    productId: string;
    sku: string;
    productName: string;
};

export const SelectorWishlistButton: Component<SelectorWishlistButtonProps> = ({
    productId,
    sku,
    productName,
    locale,
}) => {
    const {
        menuModal: { addIfAbsentAndOpen },
    } = useUIContext();

    const { shouldAddToWishlist, update: updateWishlist, isProductInWishlist } = useWishlist();

    const updateWishlistHandler = () => {
        if (shouldAddToWishlist) {
            updateWishlist({
                productId,
                productSku: sku,
                productName,
            });
        } else {
            addIfAbsentAndOpen(
                WISHLIST_MODAL_ID,
                SideMenuModal(
                    Position.None,
                    {
                        default: <WishlistModal productSku={sku} locale={locale} />,
                    },
                    ModalCommonClasses,
                ),
            );
        }
    };
    return (
        <GlowWishlistButton onClick={updateWishlistHandler} isInWishlist={isProductInWishlist(sku)} type={'button'} />
    );
};
