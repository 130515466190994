import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeButtonsWebmoV2DTO = z.infer<typeof HomeButtonsWebmoV2DTO>;
export const HomeButtonsWebmoV2DTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.ButtonsWebmoV2,
    details: z.object({
        buttons: z.array(
            z.object({
                title: z.string(),
                subTitle: z.string().optional(),
                link: z.string(),
                image: ImageDTO.optional(),
            }),
        ),
    }),
});
