'use client';

import { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { obfuscateLinks } from '@headless-workspace/config';
import {
    AsyncData,
    DynamicHrefValue,
    DynamicIconValue,
    DynamicLabelValue,
    DynamicTagValue,
    ResultEmpty,
    ResultFailure,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import { CommonDI, UserSessionRepositoryType } from '@headless-workspace/domain/common/DI';
import { useUserSession } from '@headless-workspace/domain/common/client';
import { UserInfoErrors } from '@headless-workspace/domain/common/server';
import useSWR from 'swr';
import { findSpecificityRule } from '../../../../src';

const SWR_RULE_KEY = 'specificity/rules';

export const useFindSpecificityRule = <
    T extends DynamicLabelValue | DynamicIconValue | DynamicHrefValue | DynamicTagValue,
>(
    id: string,
    specificityRules: T[],
): AsyncData<T> => {
    const t = useTranslations('Common.country');

    const userSession = useUserSession(CommonDI.get(UserSessionRepositoryType));
    const shouldFetch =
        !userSession.isLoading && userSession.result.type === 'success' && userSession.result.data.sessionInit;

    const { data, isLoading, error } = useSWR(shouldFetch ? `${SWR_RULE_KEY}/${id}` : null, () =>
        findSpecificityRule(specificityRules),
    );

    useEffect(() => {
        if (typeof document !== 'undefined' && shouldFetch) {
            obfuscateLinks();
        }
    }, [shouldFetch, data?.rule]);

    if (!shouldFetch) {
        return { isLoading: true, result: ResultEmpty() };
    }

    if (error) {
        return {
            isLoading,
            result: ResultFailure(UserInfoErrors.Default, error instanceof Error ? error.message : t('defaultError')),
        };
    }

    if (!data) {
        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: ResultSuccess(data) };
};
