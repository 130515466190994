import { HTMLString } from '@headless-workspace/domain/common/server';
import { IntlMessagesKeys } from '@headless-workspace/typings';
import { ProductDetailDTO } from './ProductDetailDTO';

export type ProductInfoTabParamsValue = {
    id: string;
    className: string;
    label: IntlMessagesKeys;
    index?: number;
    displayContentInProductPage?: boolean;
    value: HTMLString;
};

const createTabParam = (
    id: string,
    className: string,
    label: string,
    value: string | null | undefined,
    displayContentInProductPage?: boolean,
): ProductInfoTabParamsValue | undefined => {
    if (!value && !value?.length) {
        return;
    }

    return {
        id,
        className,
        label: label as IntlMessagesKeys,
        value: typeof value === 'string' ? HTMLString(value) : value,
        displayContentInProductPage: displayContentInProductPage,
    };
};

export const mapProductInfo = (dto: ProductDetailDTO): ProductInfoTabParamsValue[] => {
    const tabs = [
        {
            id: 'description',
            className: 'tab-description',
            displayContentInProductPage: true,
            label: 'Discover.Pdp.productInfo.productTab.label.description',
            value: dto.description,
        },
        {
            id: 'tips',
            className: 'tab-tips',
            label: 'Discover.Pdp.productInfo.productTab.label.tips',
            value: dto.tips,
        },
        {
            id: 'testResults',
            className: 'tab-test-results',
            label: 'Discover.Pdp.productInfo.productTab.label.testResults',
            value: dto.testResults,
        },
        {
            id: 'compositions',
            className: 'tab-compositions',
            label: 'Discover.Pdp.productInfo.productTab.label.composition',
            value: dto.compositions,
        },
        {
            id: 'clinicalTests',
            className: 'tab-clinical',
            label: 'Discover.Pdp.productInfo.productTab.label.clinicalTests',
            value: dto.clinicalTests,
        },
        {
            id: 'ingredients',
            className: 'tab-ingredients',
            label: 'Discover.Pdp.productInfo.productTab.label.ingredients',
            value: dto.ingredients,
        },
        {
            id: 'usage',
            className: 'tab-usage',
            label: 'Discover.Pdp.productInfo.productTab.label.usage',
            value: dto.usage,
        },
        {
            id: 'notes',
            className: 'tab-olfactory-notes',
            label: 'Discover.Pdp.productInfo.productTab.label.olfactoryNotes',
            value: dto.notes,
        },
    ];
    const filteredTabs = tabs
        .map((tab) =>
            tab.value
                ? createTabParam(tab.id, tab.className, tab.label, tab.value, tab.displayContentInProductPage)
                : undefined,
        )
        .filter((tab): tab is ProductInfoTabParamsValue => tab !== undefined);

    return filteredTabs.map((tab, index) => ({
        ...tab,
        index,
    }));
};
