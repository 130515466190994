import { DefaultValueConfig, Locale } from '@headless-workspace/config';
import { mapImage } from '@headless-workspace/domain/common/client';
import { Image } from '@headless-workspace/typings';
import { LanguageHelpers, ObjectHelpers, PredicateHelpers, UrlHelpers } from '@headless-workspace/utils';
import { HomeOpenBarServicesDTO, HomeSlotBase, HomeSlotType, mapHomeSlotBase } from '../../../../index';

export type HomeOpenBarService = {
    id: string;
    title: string;
    description: string;
    href: string;
    image: Image;
};

export type HomeOpenBarServicesValue = HomeSlotBase & {
    type: HomeSlotType.OpenBarServices;
    title: string;
    services: HomeOpenBarService[];
};

export const mapHomeOpenBarServices = (dto: HomeOpenBarServicesDTO, locale: Locale): HomeOpenBarServicesValue => {
    const language = LanguageHelpers.mapLocaleToLanguage(locale);

    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.OpenBarServices,
        title: dto.details.title ?? '',
        services: dto.details.items
            .map(({ id, title, subTitle, link, image }) => {
                const img = mapImage(image) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder);
                return {
                    id,
                    title: title ?? '',
                    description: subTitle ?? '',
                    href: UrlHelpers.getHrefPathname(link ?? DefaultValueConfig.href, language),
                    image: img,
                };
            })
            .filter(PredicateHelpers.notNullOrUndefined),
    };
};
