'use client';

import { Locale } from '@headless-workspace/config';
import { HeaderDidMount, useDataLayer } from '@headless-workspace/domain/common/client';
import { Component } from '@headless-workspace/typings';

type NotFoundDidMountProps = {
    locale: Locale;
};

const DATA_LAYER_CONNECTION_ACTION = '';

export const NotFoundDidMount: Component<NotFoundDidMountProps> = ({ locale }) => {
    const { trackEvent, addVariables } = useDataLayer();

    return (
        <HeaderDidMount
            dataLayerConnectionAction={DATA_LAYER_CONNECTION_ACTION}
            trackEvent={trackEvent}
            locale={locale}
            addVariables={addVariables}
        />
    );
};
