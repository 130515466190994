'use client';

import { useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { GlowProductRating, PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type ProductRatingProps = PropsWithFontFamily & {
    rating: number;
    reviewCount: number;
};

export const PRODUCT_DETAIL_RATING_SECTION_ID = 'product-detail-ratings-and-reviews-section';

export const ProductRating: Component<ProductRatingProps> = ({ rating, reviewCount, fontFamily }) => {
    const t = useTranslations('Discover.Pdp.information.rating');

    const onRatingClick = useCallback(() => {
        if (typeof document !== 'undefined') {
            const element = document.getElementById(PRODUCT_DETAIL_RATING_SECTION_ID);
            element?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, []);

    return (
        <GlowProductRating
            rating={rating}
            reviewCount={reviewCount}
            fontFamily={fontFamily}
            ariaLabel={t('ariaLabel.reviews')}
            reviewsText={t('action.reviews')}
            noReviewsText={t('label.noReview')}
            onClick={onRatingClick}
        />
    );
};
