import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { DeliveryMethod } from '@headless-workspace/typings';
import { BasketProductValue, CustomerBasketDTO, mapBasketProduct } from '../../../../src';

export type CustomerBasketValue = Readonly<{
    id: string;
    productsCount: number;
    samplesCount: number;
    totalBeforeDiscount: number;
    shippingTotal: number;
    currency: string;
    total: number;
    totalDiscount: number;
    products: BasketProductValue[];
    shipments?: {
        shippingOptions: DeliveryMethod;
        priceOfShipment: number;
    };
}>;

export const mapCustomerBasket = (dto: CustomerBasketDTO, vipBrands: VipBrandsValue): CustomerBasketValue => {
    return {
        id: dto.id,
        productsCount: dto.productsCount,
        samplesCount: dto.samplesCount,
        totalBeforeDiscount: dto.totalBeforeDiscount,
        shippingTotal: dto.shippingTotal,
        currency: dto.currency,
        total: dto.total,
        totalDiscount: dto.totalDiscount,
        products: dto.products.map((product) => mapBasketProduct(product, dto.currency, vipBrands)),
        shipments:
            dto.shipments && dto.shipments.length > 0
                ? {
                      shippingOptions: DeliveryMethod[dto.shipments[0].shippingMethod.groupId],
                      priceOfShipment: dto.shipments[0].shippingMethod.price,
                  }
                : undefined,
    };
};
