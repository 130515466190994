import { z } from 'zod';
import { PromoTextDTO } from '../promoStandardText/PromoStandardTextDTO';

export const OmnibusInformationsDTO = PromoTextDTO.extend({
    displayDiscount: z.boolean(),
    lowerPriceText: z.string(),
    legalMentionThree: z.string(),
    enableModalInformations: z.boolean(),
});

export type OmnibusInformationsDTO = z.infer<typeof OmnibusInformationsDTO>;
