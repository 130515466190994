import { DefaultValueConfig } from '@headless-workspace/config';
import { Image } from '@headless-workspace/typings';
import { ObjectHelpers } from '@headless-workspace/utils';
import { ProductDetailBannerType } from '../ProductDetailBannerType';
import { FidelityBannerDTO } from './FidelityBannerDTO';

// FIXME: to be adjusted when unmock
export type FidelityBannerValue = {
    type: ProductDetailBannerType;
    title: string;
    content: string;
    image?: Image;
    id: string;
    legalMention?: string;
};

export const mapFidelityBanner = ({
    image,
    title,
    description,
    id,
    legalMention,
}: FidelityBannerDTO): FidelityBannerValue => {
    const imageSrc = image?.default ?? DefaultValueConfig.imagePlaceholder;
    const imageAlt = image?.alt;

    return {
        type: ProductDetailBannerType.Loyalty,
        id: id,
        title: title ?? '',
        content: legalMention ?? '',
        image: ObjectHelpers.createImageWithSrc(imageSrc, imageAlt),
    };
};
