import { z } from 'zod';
import { HomeMosaicWindowsDTO, HomeUnderBannersDTO } from './banners';
import { HomeBeautyTipsDTO } from './beautyTips';
import { HomeBrandoramaDTO } from './brandorama';
import { HomeProductRecommendationsDTO, HomeProductsListDTO } from './carousel';
import { HomeButtonsWebmoV2DTO } from './fastAccess';
import { HomeButtonsWebmoDTO } from './mobileButtons';
import { HomeOpenBarServicesDTO } from './openBarService';

export type HomeSlotDTO = z.infer<typeof HomeSlotDTO>;
export const HomeSlotDTO = z.union([
    HomeMosaicWindowsDTO,
    HomeUnderBannersDTO,
    HomeBrandoramaDTO,
    HomeOpenBarServicesDTO,
    HomeProductRecommendationsDTO,
    HomeProductsListDTO,
    HomeButtonsWebmoDTO,
    HomeButtonsWebmoV2DTO,
    HomeBeautyTipsDTO,
]);

export const HomeSlotsContainerDTO = z.object({
    slots: z.array(z.unknown()),
});

export type HomeSlotsDTO = z.infer<typeof HomeSlotsDTO>;
export const HomeSlotsDTO = z.object({
    slots: z.array(HomeSlotDTO),
});
