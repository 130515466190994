import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { ProductDetailBannerDTOTypes } from '../ProductDetailBannerDTOCommon';

// FIXME: to be adjusted when unmock
export const GWPBannerDTO = z.object({
    type: ProductDetailBannerDTOTypes.GiftWithPurchase.optional(),
    id: z.string(),
    title: z.string().optional(),
    description: z.string().optional(),
    legalMention: z.string().optional(),
    image: ImageDTO,
});

export type GWPBannerDTO = z.infer<typeof GWPBannerDTO>;
