import { DisplayDevice } from '@headless-workspace/domain/common/client';
import { cva } from 'class-variance-authority';

export const homeDisplayStyle = cva('', {
    variants: {
        displayDevice: {
            [DisplayDevice.Mobile]: 'flex tablet:hidden',
            [DisplayDevice.TabletDesktop]: 'hidden tablet:flex',
            [DisplayDevice.All]: '',
        },
    },
    defaultVariants: {
        displayDevice: DisplayDevice.All,
    },
});
