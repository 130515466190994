'use client';

import { DefaultValueConfig } from '@headless-workspace/config';
import { DynamicHrefValue, DynamicIconValue, isDynamicIconValue } from '@headless-workspace/core-domain';
import { getIcon } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ConnectionBannerItem } from '../../../../server';
import { useCountrySpecificities } from '../../countrySpecificities/useCountrySpecificities';
import { ConnectionBannerItemWrapperProps } from './ConnectionBannerItemWrapper';
import { StaticConnectionBanner } from './StaticConnectionBanner';

type DynamicConnectionBannerProps = ConnectionBannerItemWrapperProps & {
    dynamicData: (DynamicHrefValue | DynamicIconValue)[];
};

export const DynamicConnectionBanner: Component<DynamicConnectionBannerProps> = ({
    connectionDataLayer,
    dynamicData,
    ...props
}) => {
    const { isLoading, data } = useCountrySpecificities(props.id, dynamicData);

    if (isLoading || !data) {
        return <StaticConnectionBanner {...props} />;
    }

    if (isDynamicIconValue(data)) {
        return (
            <ConnectionBannerItem
                key={props.id}
                Icon={getIcon(data.iconName)}
                label={props.label}
                ariaLabel={props.ariaLabel}
                href={props.href ?? DefaultValueConfig.href}
                dataLayerLabel={connectionDataLayer}
            />
        );
    }

    return (
        <ConnectionBannerItem
            {...props}
            Icon={getIcon(props.iconName)}
            href={data.href}
            dataLayerLabel={connectionDataLayer}
        />
    );
};
