'use client';

import {
    CountrySpecificitiesRule,
    DynamicHrefValue,
    DynamicIconValue,
    DynamicLabelValue,
    DynamicTagValue,
} from '@headless-workspace/core-domain';
import { UserConnectionStatus, getUserInfoServerAction } from '@headless-workspace/domain/common/server';
import { ArrayHelpers } from '@headless-workspace/utils';

export enum CountrySpecificityRuleKey {
    UserStatus = 'user.status',
    UserLoyaltyStatus = 'user.loyalty.status',
    UserLastOfferStatus = 'user.offer.status',
}

const applySpecificityRule = async (rule: CountrySpecificitiesRule): Promise<boolean> => {
    try {
        switch (rule.key) {
            case CountrySpecificityRuleKey.UserStatus: {
                const userInfo = await getUserInfoServerAction();
                return userInfo.type === 'success' && ArrayHelpers.findMatchingValue(rule.value, userInfo.data.status);
            }
            case CountrySpecificityRuleKey.UserLoyaltyStatus:
            case CountrySpecificityRuleKey.UserLastOfferStatus: {
                const userInfo = await getUserInfoServerAction();
                return (
                    userInfo.type === 'success' &&
                    userInfo.data.status === UserConnectionStatus.Registered &&
                    !!userInfo.data.loyalty &&
                    ArrayHelpers.findMatchingValue(rule.value, userInfo.data.loyalty?.sephoraCard)
                );
            }
            default:
                return false;
        }
    } catch (error) {
        return false;
    }
};

export const findSpecificityRule = async <
    T extends DynamicLabelValue | DynamicIconValue | DynamicHrefValue | DynamicTagValue,
>(
    elements: T[],
): Promise<T | undefined> => {
    return await ArrayHelpers.asyncFind(elements, async (item) => {
        return applySpecificityRule(item.rule);
    });
};
