'use client';

import { Locale } from '@headless-workspace/config';
import { PromotionType } from '@headless-workspace/domain/common/server';
import { GlowTextBodySmall, GlowTitle, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';

type ProductDetailPriceProps = PropsWithFontFamily &
    PropsWithLocale & {
        currency: string;
        locale: Locale;
        price: number;
        pricePerUnit?: string;
        promotion?: PromotionType;
        priceAlwaysBlack: boolean;
        displayLegalMentionInformation: boolean;
    };
const PRODUCT_DETAIL_PRICE_ID = 'product-detail-price';
const FIRST_SUP_INDEX = '(1)';

export const ProductDetailPrice: Component<ProductDetailPriceProps> = ({
    price,
    locale,
    currency,
    pricePerUnit,
    fontFamily,
    promotion,
    priceAlwaysBlack,
    displayLegalMentionInformation,
}) => {
    const promotionColor =
        priceAlwaysBlack || !promotion || promotion === PromotionType.REGULAR ? 'primary' : 'promotion';

    return (
        <div id={PRODUCT_DETAIL_PRICE_ID}>
            <div className={'flex flex-row gap-0.25'}>
                <GlowTitle
                    text={StringHelpers.formatPrice(price, currency, locale)}
                    fontFamily={fontFamily}
                    fontSize={'title3'}
                    color={promotionColor}
                />
                {displayLegalMentionInformation && (
                    <GlowTextBodySmall text={FIRST_SUP_INDEX} TagName={'sup'} fontWeight={'bold'} />
                )}
            </div>
            {pricePerUnit && <GlowTextBodySmall color={'secondary'} text={pricePerUnit} fontFamily={fontFamily} />}
        </div>
    );
};
