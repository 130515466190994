'use client';

import { useState } from 'react';
import { localitiesConfig } from '@headless-workspace/config';
import { CommonDI, SearchGeolocationRepositoryType } from '@headless-workspace/domain/common/DI';
import { ClientEnvKey, useAppLocale, useClientData } from '@headless-workspace/domain/common/client';
import { LocalityValue } from '@headless-workspace/domain/common/server';
import { LanguageHelpers } from '@headless-workspace/utils';

export const useStoreAutocomplete = () => {
    const [localitiesResults, setLocalitiesResults] = useState<LocalityValue[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { getClientEnvVariable } = useClientData();
    const locale = useAppLocale();

    const onLocalitiesError = () => {
        setLocalitiesResults([]);
    };

    const clearLocalities = () => {
        setLocalitiesResults([]);
    };

    const apiBaseUrl = getClientEnvVariable(ClientEnvKey.WoosmapApiBaseUrl);

    const searchLocalities = async (input: string) => {
        setIsLoading(true);

        try {
            const localitiesResult = await CommonDI.get(
                SearchGeolocationRepositoryType,
                apiBaseUrl,
            ).fetchAddressAutoComplete(
                input,
                getClientEnvVariable(ClientEnvKey.WoosmapKey),
                localitiesConfig.customDescription,
                [LanguageHelpers.parseLanguage(locale)],
            );
            if (localitiesResult.type === 'failure' || localitiesResult.type === 'empty') {
                onLocalitiesError();
            }
            if (localitiesResult.type === 'success') {
                setLocalitiesResults(localitiesResult.data.slice(0, localitiesConfig.maxLength));
            }
        } catch (e) {
            onLocalitiesError();
        } finally {
            setIsLoading(false);
        }
    };

    return { localitiesResults, searchLocalities, clearLocalities, isLoading };
};
