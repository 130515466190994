'use client';

import { useCallback, useEffect, useMemo } from 'react';
import { PERSONAL_DATA_MENU_ID, Position } from '@headless-workspace/config';
import { SideMenuModal, useUIContext } from '@headless-workspace/core-ui';
import { InnerHTML, PersonalDataMenu } from '@headless-workspace/domain/common/client';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { NewsletterInformation } from '../../../../../src/server';

type NewsletterPolicyProps = PropsWithLocale & {
    newsletterBanner: NewsletterInformation;
    newsletterLegalMention?: NewsletterInformation;
    newsletteOfferLegalMention: NewsletterInformation;
    isNewsletterBoostEnabled: boolean;
};

export const NewsletterPolicy: Component<NewsletterPolicyProps> = ({
    locale,
    newsletterBanner,
    newsletterLegalMention,
    newsletteOfferLegalMention,
    isNewsletterBoostEnabled,
}) => {
    const {
        menuModal: { addAndOpen: addAndOpenMenu },
    } = useUIContext();

    const menu = useMemo(
        () =>
            newsletterLegalMention && (
                <PersonalDataMenu
                    modalTitle={newsletterLegalMention.title}
                    description={newsletterLegalMention?.content}
                    offerLegalMention={newsletteOfferLegalMention.content}
                    isNewsletterBoostEnabled={isNewsletterBoostEnabled}
                    locale={locale}
                />
            ),
        [locale, isNewsletterBoostEnabled, newsletteOfferLegalMention, newsletterLegalMention],
    );

    const onOpenMenu = useCallback(() => {
        const personalDataMenuContainerClassName = 'flex flex-col w-full tablet:w-sideMenuTablet';
        addAndOpenMenu(
            PERSONAL_DATA_MENU_ID,
            SideMenuModal(Position.Right, { default: menu }, personalDataMenuContainerClassName),
        );
    }, [addAndOpenMenu, menu]);

    useEffect(() => {
        const personalInfoButtonElements = document.querySelectorAll('.btn-personal-info');

        if (personalInfoButtonElements) {
            const handleClick = (event: Event) => {
                event.preventDefault();
                onOpenMenu();
            };

            personalInfoButtonElements.forEach((element) => {
                element.addEventListener('click', handleClick);
            });

            return () => {
                personalInfoButtonElements.forEach((element) => {
                    element.removeEventListener('click', handleClick);
                });
            };
        }
    }, [onOpenMenu]);

    return (
        <div id={newsletterBanner.id} className={'flex-1'}>
            <InnerHTML className={'newsletter-banner'} rawHtml={newsletterBanner.content} />
        </div>
    );
};
