import { DefaultValueConfig } from '@headless-workspace/config';
import { Image } from '@headless-workspace/typings';
import { ObjectHelpers } from '@headless-workspace/utils';
import { ProductDetailBannerType } from '../ProductDetailBannerType';
import { GWPBannerDTO } from './GWPBannerDTO';

// FIXME: to be adjusted when unmock
export type GWPBannerValue = {
    type: ProductDetailBannerType;
    title: string;
    content: string;
    image?: Image;
    id: string;
    legalMention?: string;
};

const DEFAULT_GWP_IMG = '/images/genericGWP.png';

export const mapGWPBanner = ({ image, title, id, legalMention }: GWPBannerDTO): GWPBannerValue => {
    const imageSrc = image?.default ?? DEFAULT_GWP_IMG;
    const imageAlt = image?.alt;

    return {
        type: ProductDetailBannerType.GiftWithPurchase,
        id: id,
        title: title ?? '',
        content: legalMention ?? '',
        image: ObjectHelpers.createImageWithSrc(imageSrc, imageAlt),
    };
};
