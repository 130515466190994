import React from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { DataLayerClassName, InnerHTML } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    GlowClickable,
    GlowContainerSection,
    GlowImage,
    PropsWithClassName,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeBeautyTipsValue, ProductCarouselContainer } from '../../../../../src';

type HomeBeautyTipsSectionProps = PropsWithLocale &
    PropsWithClassName & {
        beautyTips: HomeBeautyTipsValue;
        vipBrands: VipBrandsValue;
    };

export const HomeBeautyTipsSection: Component<HomeBeautyTipsSectionProps> = ({
    beautyTips,
    locale,
    vipBrands,
    className,
}) => {
    const t = useTranslations('Discover.Home');

    return (
        <GlowContainerSection
            title={<InnerHTML rawHtml={beautyTips.title} />}
            subtitle={beautyTips.subTitle}
            isShowSubtitleInMobile={true}
            button={{
                href: beautyTips.cta.href,
                label: `${t('brandorama.seeAll')}`,
            }}
            className={clsx(
                'desktopS:flex-row gap-y-1.5 tablet:gap-y-2 gap-x-1.5 flex-wrap justify-between',
                className,
            )}
            titleContainerClassName={'px-1 desktop:px-1.5'}
            titleClassName={'line-clamp-2'}
            subTitleClassName={'line-clamp-3 tablet:line-clamp-2 desktopS:line-clamp-1'}
            dataLayerClassName={DataLayerClassName.beautyTips}
        >
            <GlowClickable
                href={beautyTips.cta.href}
                className={clsx(
                    'w-full tablet:w-auto tablet:max-w-full desktopS:flex-1',
                    '!items-start -order-1 desktopS:order-none',
                )}
                content={{
                    labelElement: (
                        <GlowImage
                            image={beautyTips.image}
                            className={clsx(
                                'rounded-0.5 overflow-hidden flex flex-1',
                                'px-1 desktopS:pr-0 desktop:px-1.5',
                                'desktopS:h-auto desktop:h-beautyTipsMainImgMaxWidth',
                                DataLayerClassName.beautyTips,
                            )}
                            imgClassName={'object-cover rounded-0.5 !relative aspect-3/2'}
                            loading={'lazy'}
                            TagName={Image}
                            fill
                        />
                    ),
                }}
            />
            <ProductCarouselContainer
                locale={locale}
                productsIds={beautyTips.productsIds}
                vipBrands={vipBrands}
                className={clsx(
                    'desktopS:min-w-beautyTipsCarouselDesktop desktopS:max-w-beautyTipsCarouselDesktop desktop:max-w-beautyTipsCarouselMaxWidth',
                    'w-full desktopS:pr-1 desktop:pr-1.5',
                )}
                type={'beautyTips'}
                navigationButtonClassName={'hidden desktopS:flex'}
            />
        </GlowContainerSection>
    );
};
