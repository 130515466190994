import { useContext, useEffect, useRef, useState } from 'react';
import { MediaValue } from 'domains/common/src';
import { YoutubeScriptContext } from '../../providers';

export const YT_PLAYER_STATES: Record<string, YT.PlayerState> = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5,
};

interface ProductDetailVisualVideoProps {
    media: MediaValue;
    isDisplayed: boolean;
    onStateChange: (state: YT.PlayerState) => void;
}

export const ProductDetailVisualVideo = ({ media, isDisplayed, onStateChange }: ProductDetailVisualVideoProps) => {
    const playerRef = useRef<YT.Player>(null);
    const iframePlayerRef = useRef<HTMLIFrameElement>(null);
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const isYoutubeScriptIsLoaded = useContext(YoutubeScriptContext);

    useEffect(() => {
        if (isYoutubeScriptIsLoaded && !playerRef.current && iframePlayerRef.current) {
            new window.YT.Player(iframePlayerRef.current, {
                events: {
                    onReady: handleReady,
                    onStateChange: handleStateChange,
                },
            });
        }
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [isYoutubeScriptIsLoaded]);

    const handleReady = (event: YT.PlayerEvent) => {
        playerRef.current = event.target;
        setIsPlayerReady(true);
    };

    const handleStateChange = (event: YT.OnStateChangeEvent) => {
        onStateChange(event.data);
    };

    useEffect(() => {
        if (isPlayerReady && playerRef.current) {
            if (isDisplayed) {
                playerRef.current.playVideo();
            } else {
                playerRef.current.pauseVideo();
            }
        }
    }, [isDisplayed, isPlayerReady]);

    return (
        <iframe
            ref={iframePlayerRef}
            id={media.id}
            className={
                'w-productModal tablet:w-productModalTablet desktopS:w-productModalImgDesktopS desktop:w-productModalImgDesktop aspect-1/1'
            }
            src={media.url}
            title={media.title}
            allow={
                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
            }
            referrerPolicy={'strict-origin-when-cross-origin'}
            sandbox={'allow-scripts allow-same-origin allow-presentation'}
        />
    );
};
