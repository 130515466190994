'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    getRecommendedProductsServerAction,
    ProductRecommendationsErrors,
    RecommendedProductsValue,
} from '../../../../server';

type UseRecommendedProductReturnType = AsyncData<RecommendedProductsValue>;

export const useRecommendedProductsList = (vipBrands: VipBrandsValue): UseRecommendedProductReturnType => {
    const t = useTranslations('Discover.Pdp.carousel');

    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.RecommendedProducts,
        ServerActionName.getRecommendedProducts,
        () => getRecommendedProductsServerAction(vipBrands),
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    ProductRecommendationsErrors.Default,
                    error instanceof Error ? error.message : t('error.productRecommendations'),
                ),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
