import { ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeOpenBarServicesDTO = z.infer<typeof HomeOpenBarServicesDTO>;
export const HomeOpenBarServicesDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.OpenBarServices,
    details: z.object({
        title: z.string().optional(),
        items: z.array(
            z.object({
                id: z.string(),
                title: z.string().optional(),
                subTitle: z.string().optional(),
                link: z.string().optional(),
                image: ImageDTO.optional(),
            }),
        ),
    }),
});
