'use client';

import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { NEWSLETTER_MODAL_ID, Position, DataTestConfig } from '@headless-workspace/config';
import { SideMenuModal, UIContext } from '@headless-workspace/core-ui';
import {
    DataLayerEvent,
    DataLayerEventLabel,
    useDataLayer,
    trackNewsletterClick,
} from '@headless-workspace/domain/common/client';
import { UserInfo } from '@headless-workspace/domain/common/server';
import {
    GlowButton,
    GlowIcon,
    GlowInput,
    GlowTextBody,
    GlowTextCaption,
    Icons,
    ModalCommonClasses,
    PropsWithClassName,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { NewsletterConfirmationModal } from '../../../../server';
import { NewsletterModal } from '../../modal';
import { useTriggerNewsletterSubscription } from './useTriggerNewsletterSubscription';

const EMAIL_FORM_ID = 'emailFormId';
const EMAIL_MAX_LENGTH = 70;

type NewsletterFormProps = PropsWithClassName &
    PropsWithLocale & {
        defaultEmail?: string;
        onSubscribe: () => void;
        onUserSubmit: (status: boolean) => void;
        userInfo?: UserInfo;
        newsletterConfirmationPopup: NewsletterConfirmationModal;
    };

export const NewsletterForm: Component<NewsletterFormProps> = ({
    className,
    defaultEmail = '',
    locale,
    onSubscribe,
    onUserSubmit,
    userInfo,
    newsletterConfirmationPopup,
}) => {
    const t = useTranslations('Discover.Home.newsletter');

    const {
        menuModal: { addAndOpen: addAndOpenModal },
    } = useContext(UIContext);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [email, setEmail] = useState(defaultEmail);

    useEffect(() => {
        setEmail(defaultEmail);
    }, [defaultEmail]);

    const { trackEvent } = useDataLayer();
    const trackEventClick = useCallback(
        (label: DataLayerEventLabel) => {
            trackEvent(DataLayerEvent.Click, trackNewsletterClick(label, userInfo));
        },
        [trackEvent, userInfo],
    );

    const triggerSubscribe = useTriggerNewsletterSubscription(email, onSubscribe, trackEventClick);

    const onSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (email === '') {
                setErrorMessage(t('label.errorEmpty'));
            } else if (StringHelpers.validateEmail(email)) {
                setErrorMessage(undefined);
                trackEventClick(DataLayerEventLabel.Init);

                addAndOpenModal(
                    NEWSLETTER_MODAL_ID,
                    SideMenuModal(
                        Position.None,
                        {
                            default: (
                                <NewsletterModal
                                    locale={locale}
                                    email={email}
                                    onSubscribe={triggerSubscribe}
                                    onUserSubmit={onUserSubmit}
                                    newsletterConfirmationPopup={newsletterConfirmationPopup}
                                />
                            ),
                        },
                        ModalCommonClasses,
                    ),
                );
            } else {
                setErrorMessage(t('label.errorInvalid'));
            }
        },
        [addAndOpenModal, email, locale, t, trackEventClick, triggerSubscribe],
    );

    return (
        <form className={className} onSubmit={onSubmit} id={EMAIL_FORM_ID}>
            <GlowTextBody text={t('label.email')} />
            <div className={'flex flex-row gap-0.5 w-full'}>
                <GlowInput
                    className={clsx(
                        'flex-1',
                        'p-0.75',
                        'focus:ring-0',
                        'rounded-0.5 focus:outline-bold outline',
                        'bg-background-form text-text-primary',
                        errorMessage
                            ? 'outline-border-error outline-bold'
                            : 'outline-medium outline-border-primary focus:outline-border-selected',
                    )}
                    type={'email'}
                    inputMode={'email'}
                    enterKeyHint={'send'}
                    maxLength={EMAIL_MAX_LENGTH}
                    value={email}
                    aria-label={t('ariaLabel.input')}
                    aria-autocomplete={'none'}
                    onChange={(e) => setEmail(e.target.value)}
                    dataTestId={DataTestConfig.testIds.newsletterMailInput}
                />
                <GlowButton
                    dataTestId={DataTestConfig.testIds.newsletterSubscriptionButton}
                    className={'hidden tablet:flex'}
                    label={t('action.subscribe')}
                    variant={'primary'}
                    formId={EMAIL_FORM_ID}
                />
            </div>
            <GlowTextCaption text={t('label.hint')} />
            <div
                className={clsx('flex flex-row items-center gap-0.25', 'text-text-error', !errorMessage && 'invisible')}
            >
                <GlowIcon Icon={Icons.Warning} type={'small'} />
                <GlowTextCaption text={errorMessage} color={'error'} />
            </div>
            <GlowButton
                className={'tablet:hidden flex'}
                label={t('action.subscribe')}
                variant={'primary'}
                formId={EMAIL_FORM_ID}
            />
        </form>
    );
};
