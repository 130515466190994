import { Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultSuccess } from '@headless-workspace/core-domain';
import '@headless-workspace/data';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { z } from 'zod';

export interface NotifyMeRepositorySpec {
    getIsNotifyMeEnabled(locale: Locale): Promise<Result<boolean>>;

    getIsNotifyMeDisabledForCountry(locale: Locale): Promise<Result<boolean>>;
}

const ENABLE_NOTIFY_ME_KEY = 'enableNotifyMe';

export class NotifyMeRepository extends ConfigByKeyRepository implements NotifyMeRepositorySpec {
    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getIsNotifyMeEnabled(locale: Locale): Promise<Result<boolean>> {
        return await this.getConfigByKey(ENABLE_NOTIFY_ME_KEY, { locale }, (value) => z.boolean().parse(value));
    }

    async getIsNotifyMeDisabledForCountry(locale: Locale): Promise<Result<boolean>> {
        // FIXME: implement this method when SODA delivered the new endpoint
        return ResultSuccess(true);
    }
}
