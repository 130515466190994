import { Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultEmpty, ResultSuccess } from '@headless-workspace/core-domain';
import { z } from 'zod';
import { AppLinksBannerValue, mapAppLinksBannerValue } from './AppLinksBannerValue';

const SMARTBANNER_APPSFLYER_ENABLED = 'smartBannerAppsFlyerEnabled';
const APPSFLYER_BANNER_KEY = 'appsFlyerBannerKey';

export interface AppLinksBannerRepositorySpec {
    fetchConfiguration(locale: Locale): Promise<Result<AppLinksBannerValue>>;
}

export class AppLinksBannerRepository extends ConfigByKeyRepository implements AppLinksBannerRepositorySpec {
    async fetchConfiguration(locale: Locale): Promise<Result<AppLinksBannerValue>> {
        const [bannerEnabledResultDTO, bannerKeyResultDTO] = await Promise.all([
            this.getConfigByKey(SMARTBANNER_APPSFLYER_ENABLED, { locale }, (value) => z.boolean().parse(value)),
            this.getConfigByKey(APPSFLYER_BANNER_KEY, { locale }, (value) => z.string().parse(value)),
        ]);

        if (bannerEnabledResultDTO.type !== 'success' || bannerKeyResultDTO.type !== 'success') {
            return ResultEmpty();
        }

        return ResultSuccess(mapAppLinksBannerValue(bannerEnabledResultDTO.data, bannerKeyResultDTO.data));
    }
}
