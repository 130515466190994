'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DEFAULT_ZOOM_LVL } from '@headless-workspace/config';
import { GlowImage } from '@headless-workspace/glow-ds';
import { Component, ImageWithSrc } from '@headless-workspace/typings';
import { EmblaCarouselType } from 'embla-carousel';

type ProductDetailZoomableImageProps = {
    emblaApi: EmblaCarouselType | undefined;
    image: ImageWithSrc;
    zoomLevel: number;
};

const INITIAL_DRAG_VALUE = {
    isDragging: false,
    initialX: 0,
    initialY: 0,
    offsetX: 0,
    offsetY: 0,
};

export const ProductDetailZoomableImage: Component<ProductDetailZoomableImageProps> = ({
    emblaApi,
    image,
    zoomLevel,
}) => {
    const imageRef = useRef<HTMLSpanElement | null>(null);
    const dragState = useRef(INITIAL_DRAG_VALUE);

    const [isZoomed, setIsZoomed] = useState(false);

    const resetZoom = () => {
        dragState.current = INITIAL_DRAG_VALUE;
        setIsZoomed(false);
        if (imageRef.current) {
            imageRef.current.style.transform = `translate(0px, 0px) scale(${DEFAULT_ZOOM_LVL})`;
        }
    };

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('select', resetZoom);
            return () => {
                emblaApi.off('select', resetZoom);
            };
        }
    }, [emblaApi]);

    useEffect(() => {
        setIsZoomed(zoomLevel > DEFAULT_ZOOM_LVL);
        if (imageRef.current) {
            const { offsetX, offsetY } = dragState.current;
            imageRef.current.style.transform =
                zoomLevel > DEFAULT_ZOOM_LVL
                    ? `translate(${offsetX}px, ${offsetY}px) scale(${zoomLevel})`
                    : `translate(0px, 0px) scale(${DEFAULT_ZOOM_LVL})`;
        }
    }, [zoomLevel]);

    useEffect(() => {
        if (emblaApi) {
            dragState.current = { ...INITIAL_DRAG_VALUE };
            emblaApi.reInit({ watchDrag: !isZoomed });
        }
    }, [emblaApi, isZoomed]);

    const handleMove =
        (handler: (clientX: number, clientY: number) => void) => (e: React.MouseEvent | React.TouchEvent) => {
            if ('touches' in e) {
                return handler(e.touches[0].clientX, e.touches[0].clientY);
            }
            e.preventDefault();
            return handler(e.clientX, e.clientY);
        };

    const handleDragStart = (clientX: number, clientY: number) => {
        if (isZoomed) {
            const drag = dragState.current;
            drag.isDragging = true;
            drag.initialX = clientX;
            drag.initialY = clientY;
        }
    };

    const handleDragMove = (clientX: number, clientY: number) => {
        const drag = dragState.current;

        if (!drag.isDragging || !imageRef.current) return;

        let deltaX = clientX - drag.initialX;
        let deltaY = clientY - drag.initialY;

        const imageRect = imageRef.current.getBoundingClientRect();
        const containerRect = imageRef.current.parentElement?.getBoundingClientRect();

        if (!containerRect) return;

        const maxOffsetX = (imageRect.width - containerRect.width) / 2;
        const maxOffsetY = (imageRect.height - containerRect.height) / 2;

        if (Math.abs(drag.offsetX + deltaX) > maxOffsetX) {
            deltaX = maxOffsetX - Math.abs(drag.offsetX);
        }
        if (Math.abs(deltaY + drag.offsetY) > maxOffsetY) {
            deltaY = maxOffsetY - Math.abs(drag.offsetY);
        }

        drag.offsetX += deltaX;
        drag.offsetY += deltaY;
        drag.initialX = clientX;
        drag.initialY = clientY;

        imageRef.current.style.transform = `translate(${drag.offsetX}px, ${drag.offsetY}px) scale(${zoomLevel})`;
    };

    const handleDragEnd = () => {
        dragState.current.isDragging = false;
    };

    return (
        <div
            role={'button'}
            tabIndex={0}
            onMouseDown={handleMove(handleDragStart)}
            onMouseMove={handleMove(handleDragMove)}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleMove(handleDragStart)}
            onTouchMove={handleMove(handleDragMove)}
            onTouchEnd={handleDragEnd}
            className={clsx(
                'relative',
                isZoomed ? 'overflow-hidden' : 'overflow-visible',
                isZoomed ? 'cursor-grab' : 'cursor-pointer',
            )}
        >
            <GlowImage
                containerRef={imageRef}
                image={image}
                className={
                    'w-productModal transform ease-out duration-fast2 tablet:w-productModalTablet desktopS:w-productModalImgDesktopS desktop:w-productModalImgDesktop aspect-1/1'
                }
                imgClassName={'object-cover aspect-1/1'}
            />
        </div>
    );
};
