import React from 'react';
import clsx from 'clsx';
import { BannerType, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeBanner, HomeMainBannerValue, HomeUnderBanners } from '../../../../../src';

type HomeMainBannerSectionProps = PropsWithClassName & {
    banner: HomeMainBannerValue;
};

export const HomeMainBannerSection: Component<HomeMainBannerSectionProps> = ({ banner, className }) => {
    return (
        <section className={clsx('flex flex-col gap-1.5', className)}>
            <HomeBanner banner={banner} type={banner.type} />
            {banner.underBanners && (
                <HomeUnderBanners underBanners={banner.underBanners} bannerType={BannerType.Main} />
            )}
        </section>
    );
};
