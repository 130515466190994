'use client';

import { useTranslations } from 'next-intl';
import { buildRoute, DataTestConfig } from '@headless-workspace/config';
import { GlowClickable, GlowIcon, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type LogoProps = PropsWithLocale;

export const Logo: Component<LogoProps> = ({ locale }) => {
    const t = useTranslations('Header.companyLogo');

    return (
        <GlowClickable
            ariaLabel={t('ariaLabel.alt')}
            href={buildRoute(locale, 'home')}
            content={{
                icon: (
                    <GlowIcon
                        Icon={Icons.LogoSephora}
                        type={'fullSize'}
                        alt={t('ariaLabel.alt')}
                        className={
                            'min-w-sephoraLogoSmall w-sephoraLogoSmall desktopS:w-sephoraLogo desktopS:min-w-sephoraLogo text-text-primary'
                        }
                    />
                ),
            }}
            dataTestId={DataTestConfig.testIds.backToHomepageLink}
        />
    );
};
