import React, { PropsWithChildren } from 'react';
import { NextIntlClientProvider, useMessages } from 'next-intl';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type I18nProviderProps = PropsWithLocale & PropsWithChildren;

export const I18nProvider: Component<I18nProviderProps> = ({ locale, children }) => {
    const messages = useMessages();

    return (
        <NextIntlClientProvider locale={locale} messages={messages}>
            {children}
        </NextIntlClientProvider>
    );
};
