import { DefaultValueConfig, Locale } from '@headless-workspace/config';
import { mapImage } from '@headless-workspace/domain/common/client';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { Image } from '@headless-workspace/typings';
import { LanguageHelpers, ObjectHelpers, UrlHelpers } from '@headless-workspace/utils';
import { CTA, HomeBeautyTipsDTO, HomeSlotBase, HomeSlotType, mapHomeSlotBase } from '../../../../index';

export type HomeBeautyTipsValue = HomeSlotBase & {
    type: HomeSlotType.BeautyTips;
    subTitle: string;
    title: HTMLString;
    productsIds: string[];
    image: Image;
    cta: CTA;
};

export const mapHomeBeautyTips = (dto: HomeBeautyTipsDTO, locale: Locale): HomeBeautyTipsValue => {
    const language = LanguageHelpers.mapLocaleToLanguage(locale);

    return {
        ...mapHomeSlotBase(dto),
        type: HomeSlotType.BeautyTips,
        title: HTMLString(dto.details.title),
        subTitle: dto.details.subTitle,
        productsIds: dto.details.productsIds,
        image: mapImage(dto.details.image) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),
        cta: {
            label: dto.details?.ctas[0]?.label ?? '',
            href: UrlHelpers.getHrefPathname(dto.details?.ctas[0]?.link ?? DefaultValueConfig.href, language),
        },
    };
};
