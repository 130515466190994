import React from 'react';
import clsx from 'clsx';
import { DataLayerClassName } from '@headless-workspace/domain/common/client';
import {
    GlowClickable,
    GlowIcon,
    GlowSkeleton,
    GlowTextBody,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
    PropsWithIcon,
    PropsWithSkeleton,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type ConnectionBannerItemProps = PropsWithCTA &
    PropsWithClassName &
    PropsWithAriaLabel &
    PropsWithSkeleton &
    PropsWithIcon & {
        label: string;
        isObfuscated?: boolean;
        colorClassName?: string;
        disabled?: boolean;
        isRedirecting?: boolean;
    };

export const ConnectionBannerItem: Component<ConnectionBannerItemProps> = ({
    className,
    label,
    Icon,
    ariaLabel,
    isObfuscated = false,
    isRedirecting = false,
    colorClassName,
    disabled,
    skeleton,
    ...props
}) => {
    return (
        <GlowClickable
            className={clsx(
                'fill-text-primary text-text-primary',
                'gap-1 hover:text-underline',
                DataLayerClassName.connectionBanner,
                className,
            )}
            ariaLabel={ariaLabel}
            content={{
                labelElement: isRedirecting ? (
                    <GlowSkeleton
                        height={skeleton?.height}
                        width={skeleton?.width}
                        itemClassName={skeleton?.itemClassName}
                    />
                ) : (
                    <GlowTextBody text={label} className={clsx(colorClassName, 'text-nowrap')} TagName={'span'} />
                ),
                icon: !isRedirecting && <GlowIcon className={colorClassName} Icon={Icon} type={'large'} />,
            }}
            isObfuscated={isObfuscated}
            disabled={disabled}
            {...props}
        />
    );
};
