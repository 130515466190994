'use client';

import { useTranslations } from 'next-intl';
import { GlowDropDown, GlowRating, GlowTextBody, GlowTitle } from '@headless-workspace/glow-ds';
import { Component, DropDown } from '@headless-workspace/typings';
import { ReviewSortOption } from '../../../../../src';

const FILTER_DROPDOWN_ID = 'product-detail-ratings-and-reviews-sorting';

type RatingsAndReviewsContentProps = {
    reviewCount: number;
    rating: number;
    maxRating: number;
    sortingOption: ReviewSortOption[];
    onValueUpdate: (value: string) => void;
};

export const RatingsAndReviewsFilter: Component<RatingsAndReviewsContentProps> = ({
    reviewCount,
    rating,
    maxRating,
    sortingOption,
    onValueUpdate,
}) => {
    const t = useTranslations('Discover.Pdp.ratingAndReviews');

    const defaultSortOption = sortingOption?.[0]?.value || '';

    const handleValueUpdate = ({ tag }: DropDown) => {
        if (tag) {
            onValueUpdate(tag);
        }
    };

    return (
        <div className={'flex flex-col gap-1 tablet:gap-0 tablet:flex-row justify-between'}>
            <div id={'product-detail-ratings-and-reviews-summary'} className={'flex flex-col gap-0.5'}>
                <GlowTitle text={t('label.title')} fontSize={'title3'} />
                {rating > 0 && (
                    <div className={'flex flex-row gap-0.5'} data-testid={'product-detail-rating-summary'}>
                        <GlowRating rating={rating} className={'gap-0.5'} />
                        <GlowTextBody text={`${rating}/${maxRating}`} />
                    </div>
                )}
                <GlowTextBody
                    text={t('label.reviews', {
                        reviewCount,
                    })}
                />
            </div>

            {reviewCount > 0 && (
                <GlowDropDown
                    id={FILTER_DROPDOWN_ID}
                    optionsList={sortingOption}
                    onValueUpdate={handleValueUpdate}
                    defaultSelectedValue={defaultSortOption}
                    ariaLabel={t('accessibility.Sortreviews')}
                    title={t('label.SortBy')}
                />
            )}
        </div>
    );
};
