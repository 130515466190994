'use client';

import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { useScrollDirection, useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { isImage, isVideo, MediaValue } from '@headless-workspace/domain/common/client';
import { GlowClickable, GlowIcon, GlowImage, Icons, PropsWithClassName } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';
import { HtmlHelpers } from '@headless-workspace/utils';
import { useDebounceCallback } from 'usehooks-ts';
import { ProductMediaPreviewsDesktop } from './ProductMediaPreviewsDesktop';
import { SocialProof } from './SocialProof';

const SHIFT_RATIO = 0.1;
const SHIFT_MULTIPLIER = 3;
const ZOOM_DELAY = 300;
const ZOOM_SIZE = 1500;
const VISUAL_SIZE = 714;
const TOP_THRESHOLD_DESKTOPS = Number(tailwindTheme.height.headerFixedTablet.split('rem')[0]);
const TOP_THRESHOLD_DESKTOP = 0;
const BOTTOM_THRESHOLD_DESKTOPS = Number(tailwindTheme.height.headerFixedTablet.split('rem')[0]);
const BOTTOM_THRESHOLD_DESKTOP = Number(tailwindTheme.height.headerDesktop.split('rem')[0]);
const COMPUTE_TOP_GAP_DEBOUNCE = 500;

export type ProductVisualDesktopProps = PropsWithClassName & {
    medias: MediaValue[];
    socialProofLabel?: string;
    onProductVisualClick: (index: number) => void;
};

// TODO: Optimize visual fetching when used with the same url (but different size)
export const ProductVisualDesktop: Component<ProductVisualDesktopProps> = ({
    medias,
    socialProofLabel,
    className: containerClassName,
    onProductVisualClick,
}) => {
    const isDesktop = useTailwindBreakpoint('desktop');
    // Top threshold is either 0 on desktop or the header's height on desktopS ; header is always present on DesktopS
    const TOP_THRESHOLD = isDesktop ? TOP_THRESHOLD_DESKTOP : TOP_THRESHOLD_DESKTOPS;
    // Bottom threshold is equal to header's height
    const BOTTOM_THRESHOLD = isDesktop ? BOTTOM_THRESHOLD_DESKTOP : BOTTOM_THRESHOLD_DESKTOPS;

    const visualContainerRef = useRef<HTMLDivElement>(null);
    const zoomVisualRef = useRef<HTMLImageElement>(null);
    const stickyContainerRef = useRef<HTMLDivElement>(null);

    const [selectedVisualIndex, setSelectedVisualIndex] = useState<number>(0);
    const [isHoveringVisual, setIsHoveringVisual] = useState<boolean>(false);
    const [zoomVisualOffset, setZoomVisualOffset] = useState<{ left: number; top: number }>({ left: 0, top: 0 });

    const { scrollDirection } = useScrollDirection();

    // TODO: Check render optimization using a debounced callback
    // Store in a debounced callback to optimize performance ; function will be called every 500ms
    const computeTopGap = useDebounceCallback(
        () => {
            if (isDesktop) {
                const container = stickyContainerRef.current;

                if (container) {
                    const currentTopGap = HtmlHelpers.getStylePropertyValue(container, 'top');
                    let computedTopGap = currentTopGap;
                    if (scrollDirection === 'up' && currentTopGap < BOTTOM_THRESHOLD) {
                        // Case when scrolling up and visual is hidden in header : increase the top gap to match header's height
                        // Multiplier is used to match the speed of the header's animation
                        computedTopGap = Math.min(currentTopGap + SHIFT_RATIO * SHIFT_MULTIPLIER, BOTTOM_THRESHOLD);
                    }

                    if (scrollDirection === 'down' && currentTopGap > TOP_THRESHOLD) {
                        // Case when scrolling down and header is hidden : decrease the top gap to 0
                        computedTopGap = currentTopGap - SHIFT_RATIO;
                    }

                    HtmlHelpers.setStylePropertyValue(container, 'top', computedTopGap);
                }
            } else {
                // DesktopS: the top gap should be the header's height since the header is always displayed
                stickyContainerRef.current &&
                    HtmlHelpers.setStylePropertyValue(stickyContainerRef.current, 'top', BOTTOM_THRESHOLD);
                window.removeEventListener('scroll', computeTopGap);
            }
        },
        COMPUTE_TOP_GAP_DEBOUNCE,
        { leading: true },
    );

    useEffect(() => {
        window.addEventListener('scroll', computeTopGap);
        return () => {
            window.removeEventListener('scroll', computeTopGap);
        };
    }, [computeTopGap]);

    const handleSelectVisual = (index: number) => {
        setIsHoveringVisual(false);
        setSelectedVisualIndex(index);
    };

    const handleMouseEnterVisual = useCallback(() => {
        const timeout = setTimeout(() => setIsHoveringVisual(true), ZOOM_DELAY);
        visualContainerRef.current?.addEventListener('mouseleave', () => clearTimeout(timeout), { once: true });
    }, []);

    const handleMouseLeaveVisual = useCallback(() => {
        const timeout = setTimeout(() => setIsHoveringVisual(false), ZOOM_DELAY);
        visualContainerRef.current?.addEventListener('mouseenter', () => clearTimeout(timeout), { once: true });
    }, []);

    const handleMouseMoveInVisual = useCallback((e: MouseEvent) => {
        if (visualContainerRef.current && zoomVisualRef.current) {
            // Get the visual's container and zoom DOMRects
            const containerRect = visualContainerRef.current.getBoundingClientRect();
            const visualZoomRect = zoomVisualRef.current.getBoundingClientRect();

            // Calculate the width and height of the zoomed visual relative to the container
            const xRatio = (visualZoomRect.width - containerRect.width) / containerRect.width;
            const yRatio = (visualZoomRect.height - containerRect.height) / containerRect.height;

            // Calculate the offset of the zoomed visual based on the mouse position and the ratios
            const left = (e.clientX - containerRect.left) * -xRatio;
            const top = (e.clientY - containerRect.top) * -yRatio;

            setZoomVisualOffset({ left, top });
        }
    }, []);

    const onProductVisualContainerClick = (index: number) => () => {
        return onProductVisualClick(selectedVisualIndex);
    };

    return (
        <section className={containerClassName}>
            <div className={'h-full'}>
                <div className={'sticky flex items-center gap-1'} ref={stickyContainerRef}>
                    <div className={'flex flex-col gap-0.5'}>
                        <ProductMediaPreviewsDesktop
                            medias={medias}
                            onSelectVisual={handleSelectVisual}
                            selectedVisualIndex={selectedVisualIndex}
                            onProductVisualClick={onProductVisualContainerClick(selectedVisualIndex)}
                        />
                    </div>

                    <GlowClickable
                        onClick={onProductVisualContainerClick(selectedVisualIndex)}
                        content={{
                            icon: (
                                <span
                                    ref={visualContainerRef}
                                    onMouseEnter={handleMouseEnterVisual}
                                    onMouseLeave={handleMouseLeaveVisual}
                                    onMouseMove={handleMouseMoveInVisual}
                                    className={'relative overflow-hidden'}
                                >
                                    {isImage(medias[selectedVisualIndex]) && (
                                        <GlowImage
                                            TagName={Image}
                                            width={ZOOM_SIZE}
                                            height={ZOOM_SIZE}
                                            image={medias[selectedVisualIndex]}
                                            className={clsx(
                                                'absolute',
                                                'w-originalProductVisual h-originalProductVisual',
                                                isHoveringVisual ? 'opacity-100' : 'opacity-0',
                                            )}
                                            style={{
                                                top: `${zoomVisualOffset.top}px`,
                                                left: `${zoomVisualOffset.left}px`,
                                            }}
                                            imgClassName={'aspect-1/1 object-cover'}
                                            containerRef={zoomVisualRef}
                                        />
                                    )}
                                    <GlowImage
                                        TagName={Image}
                                        width={VISUAL_SIZE}
                                        height={VISUAL_SIZE}
                                        image={medias[selectedVisualIndex]}
                                        className={clsx(
                                            'w-productVisualDesktopS desktop:w-productVisualDesktop aspect-1/1',
                                            'cursor-pointer opacity-100',
                                            isHoveringVisual && isImage(medias[selectedVisualIndex]) && 'opacity-0',
                                        )}
                                        imgClassName={'aspect-1/1 object-cover'}
                                    />

                                    {isVideo(medias[selectedVisualIndex]) && (
                                        <span
                                            className={
                                                'absolute top-0 left-0 w-full h-full flex items-center justify-center rounded-0.25'
                                            }
                                        >
                                            <GlowIcon
                                                Icon={Icons.Play}
                                                type={'small'}
                                                className={
                                                    'text-text-primary-inverse w-ProductVideoPlayPauseIconTablet h-ProductVideoPlayPauseIconTablet'
                                                }
                                            />
                                        </span>
                                    )}
                                    <SocialProof socialProofLabel={socialProofLabel} />
                                </span>
                            ),
                        }}
                    />
                </div>
            </div>
        </section>
    );
};
