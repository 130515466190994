'use client';

import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Component } from '@headless-workspace/typings';

export const YoutubeScriptContext = createContext(false);

export const YoutubeScriptProvider: Component<PropsWithChildren> = ({ children }) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = () => {
                setIsScriptLoaded(true);
            };
        } else {
            setIsScriptLoaded(true);
        }
    }, []);

    return <YoutubeScriptContext.Provider value={isScriptLoaded}>{children}</YoutubeScriptContext.Provider>;
};
