import { CountryKeyValue, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultEmpty, ResultSuccess } from '@headless-workspace/core-domain';
import { LanguageHelpers } from '@headless-workspace/utils';
import { LoyaltyConfigDTO } from './LoyaltyConfigDTO';
import { EnableOffers, LoyaltyConfigsValue, mapLoyaltyConfigsValue } from './LoyaltyConfigsValue';

const LOYALTY_CONFIGS_KEY = 'loyaltyConfigs';

export interface LoyaltyConfigsRepositorySpec {
    fetchLoyaltyConfiguration(locale: Locale): Promise<Result<LoyaltyConfigsValue>>;
}

export class LoyaltyConfigsRepository extends ConfigByKeyRepository implements LoyaltyConfigsRepositorySpec {
    async fetchLoyaltyConfiguration(locale: Locale): Promise<Result<LoyaltyConfigsValue>> {
        const loyaltyConfigsResult = await this.getConfigByKey(LOYALTY_CONFIGS_KEY, { locale }, (value) =>
            LoyaltyConfigDTO.parse(
                LanguageHelpers.retrieveDataByCountry(value as CountryKeyValue<EnableOffers>, locale),
            ),
        );

        if (loyaltyConfigsResult.type !== 'success') {
            return ResultEmpty();
        }

        return ResultSuccess(mapLoyaltyConfigsValue(loyaltyConfigsResult.data.enableOffers));
    }
}
