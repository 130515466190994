'use client';

import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { getNewsletterSlotServerAction, NewsletterValue } from '../../../../server/lib/newsletter';

type NewsletterReturnType = AsyncData<NewsletterValue>;

const NEWSLETTER_DEFAULT_ERROR = 'NEWSLETTER ERROR';

export const useNewsletter = (): NewsletterReturnType => {
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.NewsletterSlot,
        ServerActionName.getNewsletterSlot,
        getNewsletterSlotServerAction,
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(NEWSLETTER_DEFAULT_ERROR, error instanceof Error ? error.message : undefined),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
