import { BreadcrumbDTO } from '../breadcrumb';

export const breadcrumbMock: BreadcrumbDTO[] = [
    {
        id: 'Home',
        label: 'Sephora',
        url: '/',
    },
    {
        id: 'C301',
        label: 'Parfum',
        url: '/s/Sephora_FR/shop/parfum-c301/',
    },
    {
        id: 'C310',
        label: 'Parfum homme',
        url: '/s/Sephora_FR/shop/parfum/parfum-homme-c310/',
    },
    {
        id: 'C297818',
        label: 'Eau de parfum',
        url: '/s/Sephora_FR/shop/parfum/parfum-homme/eau-de-parfum-c297818/',
    },
];
