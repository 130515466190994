import { CtaDTO, ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeBeautyTipsDTO = z.infer<typeof HomeBeautyTipsDTO>;
export const HomeBeautyTipsDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.BeautyTips,
    details: z.object({
        subTitle: z.string(),
        title: z.string(),
        productsIds: z.array(z.string()),
        image: ImageDTO,
        ctas: z.array(CtaDTO),
    }),
});
