import { GlowTextBody, GlowTitle, PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type ProductTitleWithMetaProps = PropsWithFontFamily & {
    name: {
        full: string;
        partOne: string;
        partTwo?: string;
    };
};

export const ProductTitleWithMeta: Component<ProductTitleWithMetaProps> = ({ name, fontFamily }) => {
    return (
        <>
            <meta itemProp={'name'} content={name.full} />
            <GlowTitle text={name.partOne} fontSize={'title3'} TagName={'h1'} fontFamily={fontFamily} />
            {name.partTwo && <GlowTextBody text={name.partTwo} fontFamily={fontFamily} />}
        </>
    );
};
