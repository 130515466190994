import clsx from 'clsx';
import {
    GlowContainerSection,
    GlowHorizontalScrollContainer,
    GlowOpenBarServiceTile,
    PropsWithClassName,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HomeOpenBarService } from './HomeOpenBarServiceValue';

type HomeOpenBarServicesSectionProps = PropsWithClassName & {
    title: string;
    services: HomeOpenBarService[];
};

export const HomeOpenBarServicesSection: Component<HomeOpenBarServicesSectionProps> = ({
    title,
    services,
    className,
}) => {
    return (
        <GlowContainerSection
            title={title}
            className={clsx('gap-1.5 tablet:gap-2', className)}
            titleContainerClassName={'px-1 desktop:px-1.5'}
        >
            <GlowHorizontalScrollContainer className={'flex-row gap-0.5'}>
                {services.map(({ id, ...rest }) => (
                    <li key={id} className={'first:pl-1 first:desktopS:pl-1.5 last:pr-1 last:desktopS:pr-1.5'}>
                        <GlowOpenBarServiceTile {...rest} />
                    </li>
                ))}
            </GlowHorizontalScrollContainer>
        </GlowContainerSection>
    );
};
