import { CatalogPingRepository, CatalogPingRepositorySpec } from '@headless-workspace/core-domain';
import { RequestStrategy } from '@headless-workspace/data';
import {
    AppLinksBannerRepository,
    AppLinksBannerRepositorySpec,
    BasketRepository,
    BasketRepositorySpec,
    FreeShippingPriceRepository,
    FreeShippingPriceRepositorySpec,
    HolidaysRepository,
    HolidaysRepositorySpec,
    HomeSlotsRepository,
    HomeSlotsRepositorySpec,
    LoyaltyConfigsRepository,
    LoyaltyConfigsRepositorySpec,
    NewsletterConfigRepository,
    NewsletterConfigRepositorySpec,
    NewsletterSlotRepository,
    NewsletterSlotRepositorySpec,
    NotifyMeRepository,
    NotifyMeRepositorySpec,
    OmnibusInformationsRepository,
    OmnibusInformationsRepositorySpec,
    PersistentBannerSlotRepository,
    PersistentBannerSlotRepositorySpec,
    PriceLegalMentionsRepository,
    PriceLegalMentionsRepositorySpec,
    ProductDetailDeliveryInfoRepository,
    ProductDetailDeliveryInfoRepositorySpec,
    ProductsRepository,
    ProductsRepositorySpec,
    PromoStandardTextRepository,
    PromoStandardTextRepositorySpec,
    ReviewsRepository,
    ReviewsRepositorySpec,
    StoreRepository,
    StoreRepositorySpec,
    WishlistRepository,
    WishlistRepositorySpec,
} from '../../src';

export const BasketRepositoryType = 'BasketRepository';
export const NewsletterConfigRepositoryType = 'NewsletterConfigRepository';
export const HomeSlotsRepositoryType = 'HomeSlotsRepository';
export const LoyaltyConfigsRepositoryType = 'LoyaltyConfigsRepository';
export const ProductsRepositoryType = 'ProductsRepository';
export const StoreRepositoryType = 'StoreRepository';
export const PersistentBannerSlotRepositoryType = 'PersistentBannerSlotRepository';
export const FreeShippingPriceRepositoryType = 'FreeShippingPriceRepository';
export const WishlistRepositoryType = 'WishlistRepository';
export const AppLinksBannerRepositoryType = 'AppLinksBannerRepository';
export const PriceLegalMentionsRepositoryType = 'PriceLegalMentionsRepository';
export const CatalogPingRepositoryType = 'CatalogPingRepository';
export const NotifyMeRepositoryType = 'NotifyMeRepository';
export const NewsletterSlotRepositoryType = 'NewsletterSlotRepository';
export const OmnibusInformationsRepositoryType = 'OmnibusInformationsRepository';
export const HolidaysRepositoryType = 'HolidaysRepository';
export const ReviewsRepositoryType = 'ReviewsRepository';
export const ProductDetailDeliveryInfoRepositoryType = 'ProductDetailDeliveryInfoRepository';
export const PromoStandardTextRepositoryType = 'PromoStandardTextRepository';

export const deps = () => ({
    [BasketRepositoryType]: BasketRepository as new (requestStrategy: RequestStrategy) => BasketRepositorySpec,
    [HomeSlotsRepositoryType]: HomeSlotsRepository as new () => HomeSlotsRepositorySpec,
    [NewsletterConfigRepositoryType]: NewsletterConfigRepository as new () => NewsletterConfigRepositorySpec,
    [ProductsRepositoryType]: ProductsRepository as new () => ProductsRepositorySpec,
    [StoreRepositoryType]: StoreRepository as new () => StoreRepositorySpec,
    [LoyaltyConfigsRepositoryType]: LoyaltyConfigsRepository as new () => LoyaltyConfigsRepositorySpec,
    [PersistentBannerSlotRepositoryType]:
        PersistentBannerSlotRepository as new () => PersistentBannerSlotRepositorySpec,
    [FreeShippingPriceRepositoryType]: FreeShippingPriceRepository as new () => FreeShippingPriceRepositorySpec,
    [WishlistRepositoryType]: WishlistRepository as new (requestStrategy: RequestStrategy) => WishlistRepositorySpec,
    [AppLinksBannerRepositoryType]: AppLinksBannerRepository as new () => AppLinksBannerRepositorySpec,
    [CatalogPingRepositoryType]: CatalogPingRepository as new () => CatalogPingRepositorySpec,
    [PriceLegalMentionsRepositoryType]: PriceLegalMentionsRepository as new () => PriceLegalMentionsRepositorySpec,
    [NotifyMeRepositoryType]: NotifyMeRepository as new () => NotifyMeRepositorySpec,
    [NewsletterSlotRepositoryType]: NewsletterSlotRepository as new () => NewsletterSlotRepositorySpec,
    [OmnibusInformationsRepositoryType]: OmnibusInformationsRepository as new () => OmnibusInformationsRepositorySpec,
    [HolidaysRepositoryType]: HolidaysRepository as new () => HolidaysRepositorySpec,
    [ReviewsRepositoryType]: ReviewsRepository as new () => ReviewsRepositorySpec,
    [ProductDetailDeliveryInfoRepositoryType]:
        ProductDetailDeliveryInfoRepository as new () => ProductDetailDeliveryInfoRepositorySpec,
    [PromoStandardTextRepositoryType]: PromoStandardTextRepository as new () => PromoStandardTextRepositorySpec,
});
