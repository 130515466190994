import { CountryKeyValue, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result } from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheRefreshTokenStrategy,
    memoryCacheQueryParamStrategy,
} from '@headless-workspace/data';
import { LanguageHelpers } from '@headless-workspace/utils';
import { PromoStandardTextDTO } from './PromoStandardTextDTO';
import { PromoStandardTextValue } from './PromoStandardTextValue';

export interface PromoStandardTextRepositorySpec {
    getPromoStandardText(locale: Locale): Promise<Result<PromoStandardTextValue>>;
}

const PROMO_STANDARD_TEXT_KEY = 'promoStandardText';

export class PromoStandardTextRepository extends ConfigByKeyRepository implements PromoStandardTextRepositorySpec {
    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getPromoStandardText(locale: Locale): Promise<Result<PromoStandardTextValue>> {
        return await this.getConfigByKey(PROMO_STANDARD_TEXT_KEY, { locale }, (data) =>
            PromoStandardTextDTO.parse(
                LanguageHelpers.retrieveDataByCountry(data as CountryKeyValue<PromoStandardTextValue>, locale),
            ),
        );
    }
}
