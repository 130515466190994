import { ShippingMethodDTO } from '@headless-workspace/domain/common/client';
import { BaseProductDTO } from '@headless-workspace/domain/common/server';
import { z } from 'zod';

const pricePerUnitMock = '100,00 € / 100ml'; // FIXME: default to be removed when unmock FHEAD-1003

export const CustomerBasketContainerDTO = z.object({
    id: z.string(),
    productsCount: z.number(),
    samplesCount: z.number(),
    totalBeforeDiscount: z.number(),
    shippingTotal: z.number(),
    currency: z.string(),
    total: z.number(),
    totalDiscount: z.number(),
    products: z.array(z.unknown()).default([]),
    shipments: z
        .array(
            z.object({
                shippingMethod: z.object({
                    groupId: ShippingMethodDTO,
                    price: z.number(),
                }),
            }),
        )
        .optional(),
});

export const CustomerBasketProductDTO = BaseProductDTO.extend({
    productId: z.string(),
    shadeId: z.number().optional(),
    quantity: z.number(),
    maxQuantity: z.number().optional(), // FIXME: should be optional ??
    salesPrice: z.number().optional(),
    isPreOrderable: z.boolean().optional(),
    releaseDate: z.string().datetime().optional(),
    isBonus: z.boolean().default(false),
    promotion: z.union([z.literal('STANDARD'), z.literal('OMNIBUS'), z.literal('REGULAR')]).optional(),
    pricePerUnit: z.string().default(pricePerUnitMock), // FIXME: default to be removed when unmock FHEAD-1003
});

export const CustomerBasketDTO = z.object({
    id: z.string(),
    productsCount: z.number(),
    samplesCount: z.number(),
    totalBeforeDiscount: z.number(),
    shippingTotal: z.number(),
    currency: z.string(),
    total: z.number(),
    totalDiscount: z.number(),
    products: z.array(CustomerBasketProductDTO).default([]),
    shipments: z
        .array(
            z.object({
                shippingMethod: z.object({
                    groupId: ShippingMethodDTO,
                    price: z.number(),
                }),
            }),
        )
        .optional(),
});

export type CustomerBasketDTO = z.infer<typeof CustomerBasketDTO>;
