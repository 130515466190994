import { GlowTextBody } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type SocialProofProps = {
    socialProofLabel?: string;
};

export const SocialProof: Component<SocialProofProps> = ({ socialProofLabel }) => {
    if (!socialProofLabel) {
        return null;
    }

    return (
        <span
            className={
                'absolute bottom-3 bg-background-neutral -left-full rounded-r-0.5 flex p-0.5 animate-socialProofAnimation'
            }
        >
            <GlowTextBody text={socialProofLabel} TagName={'span'} />
        </span>
    );
};
