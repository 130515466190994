'use client';

import Image from 'next/image';
import { Position, WISHLIST_MODAL_ID } from '@headless-workspace/config';
import { SideMenuModal, UICarouselContainer, useTailwindBreakpoint, useUIContext } from '@headless-workspace/core-ui';
import { GlowProductTile, ModalCommonClasses, PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { TileProductValue, useProductTilesMapper, useWishlist, WishlistModal } from '../../../../src';

type ProductCarouselProps = PropsWithClassName &
    PropsWithLocale & {
        products: TileProductValue[];
        prevBtnAriaLabel: string;
        nextBtnAriaLabel: string;
        wishlistBtnAriaLabel: string;
        thumbArialLabel: string;
        navigationButtonClassName?: string;
        type?: 'beautyTips';
        showStockStatusBtn?: boolean;
        enablePriceExceptionLegalMentions?: boolean;
    };

export const ProductCarousel: Component<ProductCarouselProps> = ({
    className,
    products,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    wishlistBtnAriaLabel,
    thumbArialLabel,
    navigationButtonClassName,
    locale,
    type,
    showStockStatusBtn = false,
    enablePriceExceptionLegalMentions,
}) => {
    const isTablet = useTailwindBreakpoint('tablet');
    const {
        menuModal: { addIfAbsentAndOpen },
    } = useUIContext();

    const { shouldAddToWishlist, update: updateWishlist, isProductInWishlist } = useWishlist();
    const productTiles = useProductTilesMapper(products, locale, showStockStatusBtn, enablePriceExceptionLegalMentions);

    return (
        <UICarouselContainer
            className={className}
            items={productTiles}
            renderItem={(product, isInView) => (
                <GlowProductTile
                    {...product}
                    isTablet={isTablet}
                    isLoading={!isInView}
                    ImageTagName={Image}
                    wishlist={{
                        onClick: () => {
                            if (shouldAddToWishlist) {
                                updateWishlist({
                                    productId: product.id,
                                    productSku: product.sku,
                                    productName: product.name,
                                });
                            } else {
                                addIfAbsentAndOpen(
                                    WISHLIST_MODAL_ID,
                                    SideMenuModal(
                                        Position.None,
                                        {
                                            default: <WishlistModal productSku={product.sku} locale={locale} />,
                                        },
                                        ModalCommonClasses,
                                    ),
                                );
                            }
                        },
                        isAdded: isProductInWishlist(product.sku),
                        ariaLabel: wishlistBtnAriaLabel,
                    }}
                    legalMentionsLabel={product.legalMentionsLabel}
                />
            )}
            prevBtnAriaLabel={prevBtnAriaLabel}
            nextBtnAriaLabel={nextBtnAriaLabel}
            thumbArialLabel={thumbArialLabel}
            navigationButtonClassName={navigationButtonClassName}
            type={type}
        />
    );
};
