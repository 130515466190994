'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { GlowTextBody, PropsWithClassName, PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductInfoTabParamsValue } from '../../../../server';
import { DESCRIPTION_TAB_ID, ProductInfoCTA } from '../productInfoSection';

type ProductSeeDescriptionProps = PropsWithFontFamily & {
    productInfoTabParams: ProductInfoTabParamsValue[];
} & PropsWithClassName;

export const ProductSeeDescription: Component<ProductSeeDescriptionProps> = ({ productInfoTabParams, fontFamily }) => {
    const t = useTranslations('Discover.Pdp.productInfo.productTab.label');

    return (
        <ProductInfoCTA
            productTabs={productInfoTabParams}
            openTabWithId={DESCRIPTION_TAB_ID}
            labelElement={
                <GlowTextBody
                    text={t('seeDescription')}
                    fontFamily={fontFamily}
                    textDecoration={'underline'}
                    TagName={'span'}
                />
            }
            isHyperText={true}
            fontFamily={fontFamily}
        />
    );
};
