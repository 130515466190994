'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowClickable, GlowTextBody, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import {
    ProductDetailDeliveryZoneContainer,
    ProductInfoSection,
    ProductPushLoyalty,
    ProductSeeDescription,
    useIsNotifyMeEnabled,
    useProductDeliveryInfo,
} from '../../../../client';
import { ProductDetailValue, PromotionInformationType } from '../../../../server';
import { ProductDetailBanners } from '../banners/ProductDetailBanners';
import { ProductDetailBuyNowPayLaterText } from './ProductDetailBuyNowPayLaterText';
import { ProductRating } from './ProductRating';
import { ProductTitleWithMeta } from './ProductTitleWithMeta';
import { ProductDetailPricesContainer } from './productDetailPrices';
import { ProductDetailSelectorContainer } from './selector';

const mockEkooStyle = 'h-[56px] bg-background-infos'; // FIXME: delete

type ProductDetailInformationProps = PropsWithLocale &
    PropsWithFontFamily & {
        product: ProductDetailValue;
        vipBrands: VipBrandsValue;
        promotionInformation?: PromotionInformationType;
        redDotText?: string;
    };

export const ProductDetailInformation: Component<ProductDetailInformationProps> = ({
    locale,
    product,
    vipBrands,
    promotionInformation,
    fontFamily,
    redDotText,
}) => {
    const t = useTranslations('Discover.Pdp.information.price');
    const brandTextDecoration = product.isUnderlinedBrand ? 'underline' : 'none';

    const [skuSelected, setSkuSelected] = useState(product.sku);

    // Get product delivery information
    const { isLoading: isProductDeliveryInfoLoading, result: productDeliveryInfoResult } = useProductDeliveryInfo();
    const productDeliveryInfoData = {
        isLoading: isProductDeliveryInfoLoading,
        data: unwrapResultSuccess(productDeliveryInfoResult),
    };

    // Get notify me information
    const { isLoading: isNotifyMeEnabledLoading, result: isNotifyMeEnabledResult } = useIsNotifyMeEnabled();
    const isNotifyMeEnabledData = {
        isLoading: isNotifyMeEnabledLoading,
        data: unwrapResultSuccess(isNotifyMeEnabledResult),
    };

    const discountPercentageLabel = t('label.discountPercentage', {
        discountPercentage: product.discountPercentage,
    });

    const formattedTotalIndividualPrice = product.totalIndividualPrice
        ? t('label.totalIndividualPrice', {
              value: StringHelpers.formatPrice(product.totalIndividualPrice, product.currency, locale),
          })
        : undefined;

    return (
        <section id={'product-detail-information-container'} className={'flex flex-col flex-1 px-1 desktop:px-0 gap-3'}>
            <section id={'product-information'} className={'flex flex-col gap-1.5'}>
                <div id={'product-sku-detail'} className={'flex flex-col gap-0.5'}>
                    <GlowClickable
                        id={'product-brand'}
                        href={product.brand.url}
                        content={{
                            labelElement: (
                                <GlowTextBody
                                    text={product.brand.name}
                                    textDecoration={brandTextDecoration}
                                    fontFamily={fontFamily}
                                />
                            ),
                        }}
                    />
                    <ProductTitleWithMeta name={product.name} fontFamily={fontFamily} />
                    <div className={'flex flex-col tablet:flex-row tablet:justify-between gap-1'}>
                        <ProductSeeDescription
                            productInfoTabParams={product.productInfoTabParams}
                            fontFamily={fontFamily}
                        />
                        <ProductRating
                            fontFamily={fontFamily}
                            rating={product.rating}
                            reviewCount={product.reviewCount}
                        />
                    </div>
                    <ProductDetailPricesContainer
                        price={product.price}
                        priorPrice={product.priorPrice}
                        originalPrice={product.originalPrice}
                        pricePerUnit={product.pricePerUnit}
                        currency={product.currency}
                        locale={locale}
                        fontFamily={fontFamily}
                        promotion={product.promotion}
                        discountPercentageLabel={discountPercentageLabel}
                        priceAlwaysBlack={product.priceAlwaysBlack}
                        isVipBrand={product.isVipBrand}
                        promotionInformation={promotionInformation}
                        formattedTotalIndividualPrice={formattedTotalIndividualPrice}
                    />

                    <ProductDetailBuyNowPayLaterText
                        buyNowPayLaterContent={product.buyNowPayLaterContent}
                        fontFamily={fontFamily}
                    />
                </div>
                <div
                    id={'ekoo'}
                    className={clsx('flex justify-center items-center', 'tablet:w-[343px]', mockEkooStyle)}
                >
                    Ekoo
                </div>
                <ProductDetailSelectorContainer
                    id={product.id}
                    sku={skuSelected}
                    productName={product.name.full}
                    brandName={product.brand.name}
                    productDeliveryInfoData={productDeliveryInfoData}
                    isNotifyMeEnabledData={isNotifyMeEnabledData}
                    locale={locale}
                    vipBrands={vipBrands}
                    price={
                        product.price > 0
                            ? StringHelpers.formatPrice(product.price, product.currency, locale)
                            : undefined
                    }
                    priceBeforeDiscount={
                        product.priceBeforeDiscount
                            ? StringHelpers.formatPrice(product.priceBeforeDiscount, product.currency, locale)
                            : undefined
                    }
                    priceAlwaysBlack={product.priceAlwaysBlack}
                    enableDiscountInfo={product.enableDiscountInfo}
                    fontFamily={fontFamily}
                    type={product.type}
                    formats={product.formats}
                    variants={product.variants}
                    currency={product.currency}
                    squaredShade={product.squaredShade}
                    discountPercentageLabel={discountPercentageLabel}
                    isRedDot={product.isRedDot}
                    redDotText={redDotText}
                />
            </section>
            <ProductPushLoyalty
                currency={product.currency}
                price={product.price}
                fontFamily={fontFamily}
                locale={locale}
                isVipBrand={product.isVipBrand}
            />

            <ProductDetailDeliveryZoneContainer
                locale={locale}
                stock={product.stockLevel}
                productDeliveryInfoData={productDeliveryInfoData}
                isNotifyMeEnabledData={isNotifyMeEnabledData}
                productId={product.id}
            />
            <ProductInfoSection productInfoTabParams={product.productInfoTabParams} fontFamily={fontFamily} />
            <ProductDetailBanners banners={product.banners} />
        </section>
    );
};
