'use client';

import { ADDED_TO_BASKET_MODAL_ID, DataTestConfig, Position } from '@headless-workspace/config';
import { AsyncDataWithoutResult } from '@headless-workspace/core-domain';
import { SideMenuModal, useUIContext } from '@headless-workspace/core-ui';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowButton, GlowSkeleton, GlowTitle, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductAddToBasketModal, ProductDetailDeliveryInfoValue, useProductBasketCTA } from '../../../../../client';

type SelectorAddToBasketProps = PropsWithLocale &
    PropsWithFontFamily & {
        sku: string;
        productDeliveryInfoData: AsyncDataWithoutResult<ProductDetailDeliveryInfoValue>;
        isNotifyMeEnabledData: AsyncDataWithoutResult<boolean>;
        hasShortLabel: boolean;
        vipBrands: VipBrandsValue;
    };

export const SelectorAddToBasketButton: Component<SelectorAddToBasketProps> = ({
    sku,
    productDeliveryInfoData,
    isNotifyMeEnabledData,
    hasShortLabel,
    vipBrands,
    locale,
    fontFamily,
}) => {
    const {
        menuModal: { addIfAbsentAndOpen },
    } = useUIContext();

    const {
        isLoading: isBasketCTALoading,
        cta: basketCTA,
        isAddingToBasket,
    } = useProductBasketCTA({
        sku,
        productDeliveryInfoData,
        isNotifyMeEnabledData,
        hasShortLabel,
    });

    const addToBasketHandler = async () => {
        const productAddedToBasket = await basketCTA.onClick();

        // FIXME: TODO - Implement the error toast
        if (!productAddedToBasket) {
            return null;
        }

        const basketModalClasses =
            'w-modalAddToCartMobile tablet:w-modalAddToCartTablet m-auto mt-1.25 tablet:mt-1 overflow-hidden ';

        addIfAbsentAndOpen(
            ADDED_TO_BASKET_MODAL_ID,
            SideMenuModal(
                Position.None,
                {
                    default: (
                        <ProductAddToBasketModal locale={locale} product={productAddedToBasket} vipBrands={vipBrands} />
                    ),
                },
                basketModalClasses,
            ),
        );
    };

    if (isBasketCTALoading) {
        return <GlowSkeleton containerClassName={'w-full'} />;
    }

    if (basketCTA.variant) {
        return (
            <GlowButton
                dataTestId={DataTestConfig.testIds.addToBasketButton}
                className={'flex-1'}
                Icon={basketCTA.Icon}
                label={basketCTA.label}
                onClick={addToBasketHandler}
                isSpinnerActive={isAddingToBasket}
                variant={basketCTA.variant}
                size={'large'}
                fontFamily={fontFamily}
            />
        );
    }

    return <GlowTitle text={basketCTA.label} fontSize={'title4'} className={'text-text-warning py-0.75 flex-1'} />;
};
