'use client';

import React from 'react';
import { HeaderConnectionBannerItemId } from '@headless-workspace/core-domain';
import { ClientEnvKey, useAppLocale, useClientData } from '@headless-workspace/domain/common/client';
import { ConnectionDataLayerLabel } from '@headless-workspace/domain/common/client';
import { getIcon } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';
import { ConnectionBannerItem } from '../../../../server';
import { bookNow } from '../../appointment/bookNow';

type AppointmentConnectionBannerItemProps = {
    id: HeaderConnectionBannerItemId;
    label: string;
    ariaLabel: string;
    iconName: string;
};

export const AppointmentConnectionBannerItem: Component<AppointmentConnectionBannerItemProps> = ({
    id,
    label,
    ariaLabel,
    iconName,
}) => {
    const { getClientEnvVariable } = useClientData();
    const locale = useAppLocale();

    const onMakeAppointment = () => {
        bookNow(getClientEnvVariable(ClientEnvKey.BooxiKey), LanguageHelpers.parseLanguage(locale));
    };

    return (
        <ConnectionBannerItem
            Icon={getIcon(iconName)}
            label={label}
            ariaLabel={ariaLabel}
            onClick={onMakeAppointment}
            dataLayerLabel={ConnectionDataLayerLabel[id]}
        />
    );
};
