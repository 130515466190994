import React from 'react';
import { useTranslations } from 'next-intl';
import { UIGWPBanner, UIMarketingBanner } from '@headless-workspace/core-ui';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { ProductDetailBannerType, ProductDetailBannersValue } from '../../../../../src';

type ProductDetailBanners = {
    banners?: ProductDetailBannersValue;
};

export const ProductDetailBanners = ({ banners }: ProductDetailBanners) => {
    const t = useTranslations('Discover.Pdp.banner');
    return (
        <div className="flex flex-col gap-0.5">
            {banners?.map((banner) => {
                switch (banner.type) {
                    case ProductDetailBannerType.Loyalty:
                    case ProductDetailBannerType.GiftWithPurchase:
                        return (
                            <UIGWPBanner
                                key={banner.id}
                                image={{
                                    src: banner.image,
                                }}
                                title={<InnerHTML rawHtml={{ content: banner.title }} />}
                                accordion={{
                                    title: t('action.fidelityBanner'),
                                    content: <InnerHTML rawHtml={{ content: banner.content }} />,
                                }}
                            />
                        );
                    case ProductDetailBannerType.Marketing:
                        return (
                            <UIMarketingBanner
                                key={banner.id}
                                title={<InnerHTML rawHtml={{ content: banner.title }} />}
                                content={<InnerHTML rawHtml={{ content: banner.content }} />}
                                image={{ src: banner.image }}
                                button={banner.button}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};
