import { CtaDTO, ImageDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';
import { HomeSlotBaseDTO } from '../HomeSlotBaseDTO';
import { HomeSlotDTOTypes } from '../HomeSlotDTOTypes';

export type HomeBannerBaseDTO = z.infer<typeof HomeBannerBaseDTO>;
export const HomeBannerBaseDTO = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    title: z.string().optional(),
    subTitle: z.string().optional(),
    content: z.string().optional(),
    image: ImageDTO,
    legalMention: z.string().optional(),
    ctas: z.array(CtaDTO).optional(),
});

export type HomeMosaicWindowsDTO = z.infer<typeof HomeMosaicWindowsDTO>;
export const HomeMosaicWindowsDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.MozaicWindows,
    details: z.object({
        items: z.array(
            HomeBannerBaseDTO.extend({
                type: z.union([z.literal('MAIN'), z.literal('UNDER')]),
                tags: z.array(CtaDTO).optional(),
            }),
        ),
    }),
});

export type HomeUnderBannersDTO = z.infer<typeof HomeUnderBannersDTO>;
export const HomeUnderBannersDTO = HomeSlotBaseDTO.extend({
    type: HomeSlotDTOTypes.UnderBanners,
    details: z.object({
        items: z.array(HomeBannerBaseDTO),
    }),
});
