'use client';

import { useTranslations } from 'next-intl';
import { Locale } from '@headless-workspace/config';
import { PromotionType } from '@headless-workspace/domain/common/server';
import {
    GlowClickable,
    GlowIcon,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTextCaption,
    GlowTitle,
    Icons,
    PropsWithFontFamily,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { OmnibusInformationsValue, PromotionInformationType } from '../../../../../server';
import { ProductDetailInfoCTA, ProductDetailInfoModal } from '../../productDetailModal';
import { ProductDetailPrice } from './ProductDetailPrice';

type PromotionLegalMentionInfoProps = PropsWithFontFamily &
    PropsWithLocale & {
        isVipBrand: boolean;
        currency: string;
        priorPrice?: number;
        originalPrice?: number;
        discountPercentageLabel?: string;
        promotion?: PromotionType;
        displayLegalMentionInformation: boolean;
        promotionInformation?: PromotionInformationType;
    };

const PROMOTION_LEGAL_MENTION_INFO_ID = 'promotion-legal-mention-info';
const SECOND_SUP_INDEX = '(2)';
const THIRD_SUP_INDEX = '(3)';

const getPriorPriceLabel = (
    promotion: PromotionType | undefined,
    originalPriceText: string,
    lowerPriceText: string | undefined,
    originalPrice: number,
    priorPrice: number,
    locale: Locale,
    currency: string,
): string => {
    const price = promotion && promotion === PromotionType.STANDARD ? originalPrice : priorPrice;
    const priceText = promotion && promotion === PromotionType.STANDARD ? originalPriceText : lowerPriceText;

    return `${priceText} ${StringHelpers.formatPrice(price ?? 0, currency, locale)}`;
};

const PromotionLegalMentionInfo: Component<PromotionLegalMentionInfoProps> = ({
    priorPrice,
    currency,
    locale,
    isVipBrand,
    fontFamily,
    originalPrice,
    discountPercentageLabel,
    promotion,
    displayLegalMentionInformation,
    promotionInformation,
}) => {
    const t = useTranslations('Discover.Pdp.information.price');

    const displayPromotion = !isVipBrand && promotion;
    const displayLegalMentionThree = promotion === undefined || promotion !== PromotionType.STANDARD;

    const isOmnibusInformations = (value: PromotionInformationType | undefined): value is OmnibusInformationsValue => {
        return value !== undefined && (value as OmnibusInformationsValue).lowerPriceText !== undefined;
    };

    const priorPriceLabel = getPriorPriceLabel(
        promotion,
        promotionInformation?.originalPriceText ?? '',
        isOmnibusInformations(promotionInformation) ? promotionInformation.lowerPriceText : '',
        originalPrice ?? 0,
        priorPrice ?? 0,
        locale,
        currency,
    );

    const renderLegalMentions = (displayLegalMentionThree: boolean, enableModalInformations?: boolean) => {
        return (
            <>
                <div className={'flex flex-row flex-wrap gap-0.5'}>
                    <GlowTextBodySmall
                        className={'first-letter:font-bold'}
                        text={promotionInformation?.legalMentionOne}
                        fontFamily={fontFamily}
                    />
                    <GlowTextBodySmall
                        className={'first-letter:font-bold'}
                        text={promotionInformation?.legalMentionTwo}
                        fontFamily={fontFamily}
                    />
                    {displayLegalMentionThree && (
                        <GlowTextBodySmall
                            className={'first-letter:font-bold'}
                            text={
                                isOmnibusInformations(promotionInformation)
                                    ? promotionInformation.legalMentionThree
                                    : ''
                            }
                            fontFamily={fontFamily}
                        />
                    )}
                </div>

                {enableModalInformations && (
                    <ProductDetailInfoCTA
                        menu={
                            <ProductDetailInfoModal
                                title={t('legalMentionsModal.label.title')}
                                closeButtonLabel={t('modal.action.close')}
                                contentContainerClassName={'p-1 gap-1 overflow-auto'}
                                renderInformation={
                                    <>
                                        <GlowTitle
                                            text={t('legalMentionsModal.label.subTitle')}
                                            fontFamily={fontFamily}
                                            fontSize={'title5'}
                                        />
                                        <GlowTextBody
                                            className={'whitespace-pre-line'}
                                            text={t('legalMentionsModal.label.text')}
                                            fontFamily={fontFamily}
                                        />
                                    </>
                                }
                            />
                        }
                        labelElement={
                            <GlowTextBodySmall
                                text={t('pricesModal.label.readMore')}
                                fontFamily={fontFamily}
                                textDecoration={'underline'}
                                className={'text-left'}
                            />
                        }
                        ariaLabel={t('pricesModal.label.readMore')}
                        className={'py-1 text-text-primary w-full'}
                        isHyperText={true}
                    />
                )}
            </>
        );
    };

    return (
        <div id={PROMOTION_LEGAL_MENTION_INFO_ID} className={'flex flex-col gap-1'}>
            <div className={'flex flex-col gap-0.125'}>
                <div className={'flex flex-row flex-wrap items-center'}>
                    <GlowTextBodySmall test-id={'prior-price-label'} text={priorPriceLabel} fontFamily={fontFamily} />

                    {displayPromotion && (
                        <GlowTextCaption
                            className={'bg-background-error-red rounded-0.25 ml-0.25 px-0.25'}
                            color={'promotion'}
                            text={discountPercentageLabel}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                        />
                    )}
                    {displayLegalMentionInformation && (
                        <GlowTextBodySmall
                            text={SECOND_SUP_INDEX}
                            fontWeight={'bold'}
                            TagName={'sup'}
                            className={'ml-0.25'}
                        />
                    )}
                </div>

                {displayLegalMentionThree && (
                    <div className={'flex flex-row flex-wrap'}>
                        <GlowTextBodySmall text={promotionInformation?.originalPriceText} fontFamily={fontFamily} />
                        <GlowTextBodySmall
                            className={'ml-0.25'}
                            text={StringHelpers.formatPrice(originalPrice ?? 0, currency, locale)}
                            fontFamily={fontFamily}
                        />
                        {displayLegalMentionInformation && (
                            <GlowTextBodySmall
                                text={THIRD_SUP_INDEX}
                                TagName={'sup'}
                                fontWeight={'bold'}
                                className={'ml-0.25'}
                            />
                        )}
                    </div>
                )}
            </div>

            {displayLegalMentionInformation &&
                renderLegalMentions(
                    displayLegalMentionThree,
                    isOmnibusInformations(promotionInformation) ? promotionInformation.enableModalInformations : false,
                )}
        </div>
    );
};

type ProductDetailPricesPromotionProps = PropsWithFontFamily & {
    isVipBrand: boolean;
    price: number;
    currency: string;
    priorPrice?: number;
    pricePerUnit?: string;
    locale: Locale;
    originalPrice?: number;
    discountPercentageLabel?: string;
    promotion?: PromotionType;
    priceAlwaysBlack: boolean;
    displayLegalMentionInformation: boolean;
    promotionInformation?: PromotionInformationType;
    onOpenProductPriceModal?: () => void;
};

const PRODUCT_DETAIL_PRICES_PROMOTION_ID = 'product-detail-prices-promotion';

export const ProductDetailPricesPromotion: Component<ProductDetailPricesPromotionProps> = ({
    priorPrice,
    price,
    currency,
    locale,
    isVipBrand,
    pricePerUnit,
    fontFamily,
    priceAlwaysBlack,
    originalPrice,
    discountPercentageLabel,
    promotion,
    displayLegalMentionInformation,
    promotionInformation,
    onOpenProductPriceModal,
}) => {
    return (
        <div id={PRODUCT_DETAIL_PRICES_PROMOTION_ID} className={'flex flex-col gap-0.5'}>
            <ProductDetailPrice
                price={price}
                locale={locale}
                currency={currency}
                pricePerUnit={pricePerUnit}
                fontFamily={fontFamily}
                promotion={promotion}
                priceAlwaysBlack={priceAlwaysBlack}
                displayLegalMentionInformation={displayLegalMentionInformation}
            />
            <div className={'flex flex-row gap-1 items-center'}>
                <PromotionLegalMentionInfo
                    priorPrice={priorPrice}
                    currency={currency}
                    locale={locale}
                    isVipBrand={isVipBrand}
                    fontFamily={fontFamily}
                    originalPrice={originalPrice}
                    discountPercentageLabel={discountPercentageLabel}
                    promotion={promotion}
                    displayLegalMentionInformation={displayLegalMentionInformation}
                    promotionInformation={promotionInformation}
                />

                {onOpenProductPriceModal && (
                    <GlowClickable
                        onClick={onOpenProductPriceModal}
                        content={{
                            icon: <GlowIcon Icon={Icons.Info} type={'large'} />,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
