import { Locale } from '@headless-workspace/config';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { ReviewsMock } from '../../../../client/lib/productDetail/ratingsAndReviews/ReviewsMock';
import { ProductDetailReviewsWithSortingValue } from './ProductDetailReviewValue';

export interface ReviewsRepositorySpec {
    getReviews(
        productId: string,
        offset: number,
        limit: number,
        filters: string,
        sort: number[],
        locale: Locale,
    ): Promise<Result<ProductDetailReviewsWithSortingValue>>;
}

export class ReviewsRepository extends Repository implements ReviewsRepositorySpec {
    path = '/catalog/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getReviews(
        productId: string,
        offset: number,
        limit: number,
        filters: string,
        sort: number[],
        locale: Locale,
    ): Promise<Result<ProductDetailReviewsWithSortingValue>> {
        try {
            // MOCK : Simulate API delay
            return await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(ResultSuccess(ReviewsMock));
                }, 500);
            });
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
