import { Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultSuccess } from '@headless-workspace/core-domain';
import { z } from 'zod';

const ENABLE_PRICE_LEGAL_MENTIONS = 'enablePriceLegalMentions';

export interface PriceLegalMentionsRepositorySpec {
    fetchEnablePriceLegalMentions(locale: Locale): Promise<Result<boolean>>;
}

export class PriceLegalMentionsRepository extends ConfigByKeyRepository implements PriceLegalMentionsRepositorySpec {
    async fetchEnablePriceLegalMentions(locale: Locale): Promise<Result<boolean>> {
        const enablePriceExceptionLegalMentionsResult = await this.getConfigByKey(
            ENABLE_PRICE_LEGAL_MENTIONS,
            { locale },
            (value) => z.boolean().parse(value),
        );
        if (enablePriceExceptionLegalMentionsResult.type === 'success') {
            return ResultSuccess(enablePriceExceptionLegalMentionsResult.data);
        }
        return enablePriceExceptionLegalMentionsResult;
    }
}
