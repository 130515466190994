import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import { RequestStrategy } from '@headless-workspace/data';
import { DecoderHelpers } from '@headless-workspace/utils';
import { WishlistContainerDTO, WishlistDTO } from './WishlistDTO';
import { WishlistEntity } from './WishlistEntity';

export interface WishlistRepositorySpec {
    getCustomerWishlist(locale: string, customerId: string): Promise<Result<WishlistEntity>>;
    addToWishlist(locale: string, customerId: string, productId: string): Promise<Result<boolean>>;
    deleteFromWishlist(locale: string, customerId: string, productId: string): Promise<Result<boolean>>;
}

export class WishlistRepository extends Repository implements WishlistRepositorySpec {
    readonly path = '/customer/v1';

    constructor(requestStrategy: RequestStrategy) {
        super(requestStrategy.authStrategy, requestStrategy.refreshTokenStrategy, requestStrategy.queryParamStrategy);
    }

    async getCustomerWishlist(locale: string, customerId: string): Promise<Result<WishlistEntity>> {
        try {
            const wishlistResponse = await this.datasource.getResource(
                `${this.path}/customers/${customerId}/wishlists`,
                {
                    locale,
                },
            );

            const wishlistResult = WishlistContainerDTO.parse(wishlistResponse);
            const wishlists = DecoderHelpers.safeParseArray(wishlistResult.wishlists, WishlistDTO);
            const wishlist = wishlists[0];

            if (!wishlist || !wishlist.id) {
                return ResultEmpty();
            }

            return ResultSuccess(wishlist);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async addToWishlist(locale: string, customerId: string, productId: string): Promise<Result<boolean>> {
        try {
            const customerWishlist = await this.getCustomerWishlist(locale, customerId);

            if (customerWishlist.type === 'success') {
                await this.datasource.createResource(
                    `${this.path}/customers/${customerId}/wishlists/${customerWishlist.data.id}/products`,
                    {
                        productId,
                    },
                    { locale },
                );
                return ResultSuccess(true);
            }

            return customerWishlist;
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async deleteFromWishlist(locale: string, customerId: string, wishlistProductId: string): Promise<Result<boolean>> {
        try {
            const customerWishlist = await this.getCustomerWishlist(locale, customerId);

            if (customerWishlist.type === 'success') {
                await this.datasource.deleteResource(
                    `${this.path}/customers/${customerId}/wishlists/${customerWishlist.data.id}/products/${wishlistProductId}`,
                    null,
                    { locale },
                );
                return ResultSuccess(true);
            }
            return customerWishlist;
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
