'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute, DataTestConfig } from '@headless-workspace/config';
import { GlowClickable, GlowIconSkeleton, GlowIconWithChip, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useWishlistProductsList } from './useWishlistProductsList';

export const WishlistContainer: Component<PropsWithLocale> = ({ locale }) => {
    const { isLoading, productsCount } = useWishlistProductsList();
    const t = useTranslations('Header');

    return (
        <GlowClickable
            className={clsx(
                'relative hidden desktop:flex h-full p-0.5 cursor-pointer',
                'text-text-primary items-center justify-center',
            )}
            ariaLabel={t('navigation.ariaLabel.wishlist')}
            href={buildRoute(locale, 'wishlist')}
            dataTestId={DataTestConfig.testIds.headerWishlistLink}
            content={{
                icon: isLoading ? (
                    <GlowIconSkeleton Icon={Icons.HeartAnimation} />
                ) : (
                    <GlowIconWithChip
                        hasDigit={true}
                        count={productsCount}
                        Icon={Icons.Heart}
                        type={'large'}
                        fill={'none'}
                    />
                ),
            }}
        />
    );
};
