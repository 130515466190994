export type AppLinksBannerValue = Readonly<{
    keyValue: string;
    enabled: boolean;
}>;

export const mapAppLinksBannerValue = (bannerEnabledDTO: boolean, bannerKeyDTO: string): AppLinksBannerValue => {
    return {
        keyValue: bannerKeyDTO,
        enabled: bannerEnabledDTO,
    };
};
