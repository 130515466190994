'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { getWishlistServerAction, WishlistEntity, WishlistErrors } from '../../..';

const DEFAULT_PRODUCTS_COUNT = 0;

type UseWishlistProductsListReturnType = AsyncData<WishlistEntity> & {
    productsCount: number;
};

export const useWishlistProductsList = (): UseWishlistProductsListReturnType => {
    const t = useTranslations('Header.wishlist.banner.error');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.Wishlist,
        ServerActionName.getWishlist,
        getWishlistServerAction,
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    WishlistErrors.Default,
                    error instanceof Error ? error.message : t(WishlistErrors.Default),
                ),
                productsCount: DEFAULT_PRODUCTS_COUNT,
            };
        }

        return {
            isLoading,
            result: ResultEmpty(),
            productsCount: DEFAULT_PRODUCTS_COUNT,
        };
    }

    return {
        isLoading,
        result: data,
        productsCount: data.type === 'success' ? data.data.products.length : DEFAULT_PRODUCTS_COUNT,
    };
};
