import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { getHolidaysServerAction } from '../../../../server';
import { HolidaysErrors } from './HolidaysErrors';
import { HolidaysValue } from './HolidaysValue';

export const useHolidays = (): AsyncData<HolidaysValue> => {
    const t = useTranslations('Common.holidays.error');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.Holidays,
        ServerActionName.getHolidays,
        getHolidaysServerAction,
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(HolidaysErrors.Default, error instanceof Error ? error.message : t('default')),
            };
        }
        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
