import React from 'react';
import { GlowSkeleton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const ConnectionBannerGreetingSkeleton: Component = () => {
    return (
        <>
            <GlowSkeleton width={'17.875rem'} height={'2rem'} />
            <GlowSkeleton height={'1.25rem'} containerClassName={'w-full'} />
            <GlowSkeleton height={'1.25rem'} containerClassName={'w-full'} />
            <GlowSkeleton height={'1.25rem'} containerClassName={'w-full'} />
            <GlowSkeleton height={'1.25rem'} width={'17.875rem'} />
        </>
    );
};
