import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { getProductDetailDeliveryInfoServerAction } from '../../../../server';
import { ProductDeliveryInfoErrors } from './ProductDeliveryInfoErrors';
import { ProductDetailDeliveryInfoValue } from './ProductDetailDeliveryInfoValue';

export const useProductDeliveryInfo = (): AsyncData<ProductDetailDeliveryInfoValue> => {
    const t = useTranslations('Discover.Pdp.deliveryZone.error');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.Delivery,
        ServerActionName.getProductDeliveryInfo,
        getProductDetailDeliveryInfoServerAction,
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    ProductDeliveryInfoErrors.Default,
                    error instanceof Error ? error.message : t('default'),
                ),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
