import { HolidaysValue } from './HolidaysValue';

export const holidaysValueMock: HolidaysValue = {
    '1': [1],
    '2': [],
    '3': [],
    '4': [1],
    '5': [1, 8, 9, 20],
    '6': [],
    '7': [6, 13, 14, 20, 27],
    '8': [3, 10, 15, 17, 24, 31],
    '9': [21, 28],
    '10': [5, 12, 19, 26],
    '11': [1, 2, 9, 11, 16, 23, 30],
    '12': [25],
};
