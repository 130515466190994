import { DefaultValueConfig } from '@headless-workspace/config';
import { ObjectHelpers } from '@headless-workspace/utils';
import { BrandCornerDTO, BrandCornerValue } from '../../../../../__mocks__/factory/productDetailBrandCorner';

export const mapBrandCorner = ({ image, ...dto }: BrandCornerDTO): BrandCornerValue => {
    const imageSrc = image?.default ?? DefaultValueConfig.imagePlaceholder;
    const imageAlt = image?.alt;

    return {
        ...dto,
        image: ObjectHelpers.createImageWithSrc(imageSrc, imageAlt),
    };
};
