import { Locale, SUPPORTED_LOCALES } from '@headless-workspace/config';
import messages_cs_CZ from './translations/cs-CZ.json';
import messages_da_DK from './translations/da-DK.json';
import messages_de_CH from './translations/de-CH.json';
import messages_de_DE from './translations/de-DE.json';
import messages_el_GR from './translations/el-GR.json';
import messages_es_ES from './translations/es-ES.json';
import messages_fr_CH from './translations/fr-CH.json';
import messages_fr_FR from './translations/fr-FR.json';
import messages_it_IT from './translations/it-IT.json';
import messages_pl_PL from './translations/pl-PL.json';
import messages_pt_PT from './translations/pt-PT.json';
import messages_ro_RO from './translations/ro-RO.json';
import messages_sv_SE from './translations/sv-SE.json';
import messages_tr_TR from './translations/tr-TR.json';

export const messages: { [key in Locale]: DiscoverMessages } = {
    [SUPPORTED_LOCALES.DEFAULT]: messages_fr_FR,
    [SUPPORTED_LOCALES.de_DE]: messages_de_DE,
    [SUPPORTED_LOCALES.de_CH]: messages_de_CH,
    [SUPPORTED_LOCALES.fr_CH]: messages_fr_CH,
    [SUPPORTED_LOCALES.tr_TR]: messages_tr_TR,
    [SUPPORTED_LOCALES.da_DK]: messages_da_DK,
    [SUPPORTED_LOCALES.sv_SE]: messages_sv_SE,
    [SUPPORTED_LOCALES.el_GR]: messages_el_GR,
    [SUPPORTED_LOCALES.ro_RO]: messages_ro_RO,
    [SUPPORTED_LOCALES.pl_PL]: messages_pl_PL,
    [SUPPORTED_LOCALES.cs_CZ]: messages_cs_CZ,
    [SUPPORTED_LOCALES.pt_PT]: messages_pt_PT,
    [SUPPORTED_LOCALES.es_ES]: messages_es_ES,
    [SUPPORTED_LOCALES.it_IT]: messages_it_IT,
};
