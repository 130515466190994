import clsx from 'clsx';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { AsyncComponent, checkFeatureFlagServerAction, getVipBrands } from '@headless-workspace/domain/common/server';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import {
    DI,
    HomeBeautyTipsSection,
    HomeBrandoramaSection,
    homeDisplayStyle,
    HomeFastAccessSection,
    HomeMainBannerSection,
    HomeOpenBarServicesSection,
    HomeProductCarouselSection,
    HomeSlotsRepositoryType,
    HomeSlotType,
    HomeUnderBanners,
} from '../../../../src';

const homeSlotClassName = 'py-1 desktop:py-2';

type HomeContentProps = PropsWithLocale;

export const HomeTemplate: AsyncComponent<HomeContentProps> = async ({ locale }) => {
    const [homeSlotsResult, vipBrandsResult] = await Promise.all([
        DI.get(HomeSlotsRepositoryType).getSlots(locale),
        getVipBrands(locale),
    ]);

    const vipBrands = unwrapResultSuccess(vipBrandsResult) ?? {};
    const openBarServicesEnabled = await checkFeatureFlagServerAction('isOpenBarServicesEnabled', locale);

    return (
        homeSlotsResult.type === 'success' &&
        homeSlotsResult.data.map((slot) => {
            switch (slot.type) {
                case HomeSlotType.FastAccess:
                    return (
                        <HomeFastAccessSection
                            className={homeDisplayStyle({ displayDevice: slot.display })}
                            key={slot.id}
                            fastAccess={slot}
                        />
                    );
                case HomeSlotType.MainBanner:
                    return (
                        <HomeMainBannerSection
                            className={clsx('pb-1 desktop:pb-2', homeDisplayStyle({ displayDevice: slot.display }))}
                            key={slot.id}
                            banner={slot}
                        />
                    );
                case HomeSlotType.UnderBanners:
                    return (
                        <HomeUnderBanners
                            className={clsx(homeSlotClassName, homeDisplayStyle({ displayDevice: slot.display }))}
                            key={slot.id}
                            underBanners={slot}
                        />
                    );
                case HomeSlotType.Carousel:
                    return (
                        <HomeProductCarouselSection
                            key={slot.id}
                            className={clsx(homeDisplayStyle({ displayDevice: slot.display }), homeSlotClassName)}
                            title={slot.title}
                            cta={slot.cta}
                            productsIds={slot.productsIds}
                            locale={locale}
                            vipBrands={vipBrands}
                        />
                    );
                case HomeSlotType.Brandorama:
                    return (
                        <HomeBrandoramaSection
                            className={clsx(homeSlotClassName, homeDisplayStyle({ displayDevice: slot.display }))}
                            key={slot.id}
                            brandorama={slot}
                            locale={locale}
                        />
                    );
                case HomeSlotType.OpenBarServices:
                    return openBarServicesEnabled ? (
                        <HomeOpenBarServicesSection
                            key={slot.id}
                            className={clsx(homeDisplayStyle({ displayDevice: slot.display }), homeSlotClassName)}
                            title={slot.title}
                            services={slot.services}
                        />
                    ) : null;
                case HomeSlotType.BeautyTips:
                    return (
                        <HomeBeautyTipsSection
                            key={slot.id}
                            className={clsx(homeDisplayStyle({ displayDevice: slot.display }), homeSlotClassName)}
                            beautyTips={slot}
                            locale={locale}
                            vipBrands={vipBrands}
                        />
                    );
                default:
                    return null;
            }
        })
    );
};
