import React from 'react';
import { HeaderSlotsValue, HeaderSlotType } from '@headless-workspace/core-domain';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ConnectionBanner, MyBeautyProgramBanner, StoresBanner } from '../../../../';

type FixedHeaderSlotsProps = PropsWithLocale & {
    headerSlots: HeaderSlotsValue;
    isEnableOffers?: boolean;
};

export const FixedHeaderSlots: Component<FixedHeaderSlotsProps> = ({ locale, headerSlots, isEnableOffers }) => {
    return headerSlots.rightItems.map((item) => {
        switch (item.type) {
            case HeaderSlotType.StoreAndServices:
                return (
                    <StoresBanner
                        key={item.type}
                        locale={locale}
                        {...item}
                        favoriteStoreItems={headerSlots.storeBannerFavoriteStoreItems}
                    />
                );
            case HeaderSlotType.MyBeautyProgram:
                return <MyBeautyProgramBanner key={item.type} {...item} />;
            case HeaderSlotType.MyAccount:
                return (
                    <ConnectionBanner
                        key={item.type}
                        locale={locale}
                        hoverButton={item}
                        bannerItems={headerSlots.connectionBannerItems}
                        isEnableOffers={isEnableOffers}
                        dynamicOfferRules={headerSlots.dynamicOfferRules}
                    />
                );
            default:
                return null;
        }
    });
};
