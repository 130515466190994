'use client';

import React, { useCallback, useContext } from 'react';
import { PRODUCT_INFORMATION_MENU_ID, Position } from '@headless-workspace/config';
import { SideMenuModal, UIContext } from '@headless-workspace/core-ui';
import {
    GlowClickableWithRightIcon,
    GlowTextBody,
    Icons,
    PropsWithClassName,
    GlowClickable,
    PropsWithAriaLabel,
} from '@headless-workspace/glow-ds';
import { Component, Node } from '@headless-workspace/typings';

export type ProductDetailInfoCTAProps = PropsWithClassName &
    PropsWithAriaLabel & { menu: Node } & (
        | {
              isHyperText?: boolean;
              labelElement: Node | string;
          }
        | {
              buttonElement: (onClick: () => void) => Node;
          }
    );

export const ProductDetailInfoCTA: Component<ProductDetailInfoCTAProps> = ({
    menu,
    className,
    ariaLabel,
    ...props
}) => {
    const {
        menuModal: { addAndOpen: addAndOpenMenu },
    } = useContext(UIContext);

    const onOpenMenu = useCallback(() => {
        const productDetailInfosMenuClassName = 'flex flex-col bg-background-brand overflow-hidden';
        addAndOpenMenu(
            PRODUCT_INFORMATION_MENU_ID,
            SideMenuModal(Position.Right, { default: menu }, productDetailInfosMenuClassName),
        );
    }, [addAndOpenMenu, menu]);

    if ('isHyperText' in props && props.isHyperText) {
        return (
            <GlowClickable
                onClick={onOpenMenu}
                content={{
                    labelElement: props.labelElement,
                }}
            />
        );
    }

    if ('buttonElement' in props) {
        return props.buttonElement(onOpenMenu);
    }

    return (
        <GlowClickableWithRightIcon
            className={className}
            ariaLabel={ariaLabel}
            content={{
                labelElement:
                    typeof props.labelElement === 'string' ? (
                        <GlowTextBody
                            text={props.labelElement}
                            fontWeight={'medium'}
                            className={'text-wrap text-left'}
                            TagName={'span'}
                        />
                    ) : (
                        props.labelElement
                    ),
            }}
            rightIcon={Icons.ChevronRight}
            onClick={onOpenMenu}
        />
    );
};
