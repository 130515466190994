import { DisplayDTO } from '@headless-workspace/domain/common/client';
import { z } from 'zod';

export const PersistentBannerSlotDTO = z.object({
    id: z.string(),
    type: z.string(),
    display: DisplayDTO,
    details: z.object({
        subTitle: z.string().optional(),
        backgroundColor: z.string().optional(),
        endOfCountDown: z.string().optional(),
        cta: z
            .object({
                label: z.string().optional(),
                link: z.string().url().optional(),
            })
            .optional(),
    }),
});

export type PersistentBannerSlotDTO = z.infer<typeof PersistentBannerSlotDTO>;
