import { getTranslations } from 'next-intl/server';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { AsyncComponent } from '@headless-workspace/domain/common/server';
import { GlowClickable, GlowTextBody, PropsWithLocale } from '@headless-workspace/glow-ds';
import {
    getReviewsWithSortingServerAction,
    PRODUCT_DETAIL_RATING_SECTION_ID,
    RatingsAndReviewsContent,
} from '../../../../../src';

type ProductDetailRatingsAndReviewsSectionProps = PropsWithLocale & {
    reviewCount: number;
    rating: number;
    productId: string;
};

const MAX_RATING = 5;

// FIXME: remove this mock when api is ready
const MOCKED_PRODUCT_ID = '123';

const INIT_REVIEW_WITH_SORTING = {
    reviews: [],
    sortingOption: [],
    ratingFilter: [],
};

// Fetch the first 14 reviews for SEO purposes
const BASE_REVIEW_OFFSET = 0;
const BASE_REVIEW_LIMIT = 14;

export const ProductDetailRatingsAndReviewsSection: AsyncComponent<
    ProductDetailRatingsAndReviewsSectionProps
> = async ({ reviewCount, rating, productId, locale }) => {
    const t = await getTranslations('Discover.Pdp.ratingAndReviews');

    const reviewsWithSortingResult = await getReviewsWithSortingServerAction(
        MOCKED_PRODUCT_ID,
        BASE_REVIEW_OFFSET,
        BASE_REVIEW_LIMIT,
    );
    const { reviews, ratingFilter, sortingOption } =
        unwrapResultSuccess(reviewsWithSortingResult) ?? INIT_REVIEW_WITH_SORTING;

    return (
        <section
            id={PRODUCT_DETAIL_RATING_SECTION_ID}
            className={clsx(
                'flex flex-col',
                'py-1.5 px-1 desktop:px-5.5 gap-2.5',
                'scroll-mt-headerFixed tablet:scroll-mt-headerFixedTablet desktopS:scroll-mt-headerFixedMainDesktop desktop:scroll-mt-headerDesktop',
            )}
        >
            <RatingsAndReviewsContent
                ratingFilter={ratingFilter}
                rating={rating}
                baseReviews={reviews}
                reviewCount={reviewCount}
                maxRating={MAX_RATING}
                sortingOption={sortingOption}
                productId={productId}
                locale={locale}
            />
            {reviewCount === 0 && (
                <GlowClickable
                    content={{
                        labelElement: <GlowTextBody text={t('action.reviewConditions')} />,
                    }}
                    href={buildRoute(locale, 'reviewConditions')}
                    className={'text-underline mt-0.5'}
                />
            )}
        </section>
    );
};
