'use client';

import { useTranslations } from 'next-intl';
import { PropsWithFontFamily, typographyStyle } from '@headless-workspace/glow-ds';
import { Component, Node } from '@headless-workspace/typings';
import { ProductInfoTabParamsValue } from '../../../../server';
import { ProductDetailInfoCTA, ProductDetailInfoModal } from '../productDetailModal';
import { ProductInfoTabs } from './ProductInfoTabs';

type ProductInfoCTAProps = PropsWithFontFamily & {
    productTabs: ProductInfoTabParamsValue[];
    openTabWithId: string;
    labelElement?: Node;
    isHyperText?: boolean;
};

export const ProductInfoCTA: Component<ProductInfoCTAProps> = ({
    productTabs,
    openTabWithId,
    labelElement,
    isHyperText,
    fontFamily,
}) => {
    const t = useTranslations('Discover.Pdp.productInfo.productTab');
    const ariaLabel = typeof labelElement === 'string' ? labelElement : undefined;

    return (
        productTabs?.length > 0 && (
            <ProductDetailInfoCTA
                menu={
                    <ProductDetailInfoModal
                        title={t('label.modalTitle')}
                        closeButtonLabel={t('action.closeModal')}
                        renderInformation={
                            <ProductInfoTabs
                                productInfoTabs={productTabs}
                                openTabWithId={openTabWithId}
                                className={typographyStyle({ fontFamily })}
                            />
                        }
                    />
                }
                labelElement={labelElement}
                ariaLabel={ariaLabel}
                className={'py-1 text-text-primary w-full'}
                isHyperText={isHyperText}
            />
        )
    );
};
