'use client';

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { Locale } from '@headless-workspace/config';
import { useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { ProductType, StockStatus } from '@headless-workspace/domain/common/client';
import { ProductTile } from '@headless-workspace/glow-ds';
import { FunctionHelpers, StringHelpers } from '@headless-workspace/utils';
import { TileProductValue } from '../../../../src';

const mockProductStatus = StockStatus.Available;

export const useProductTilesMapper = (
    products: TileProductValue[],

    locale: Locale,
    showStockStatusBtn: boolean,

    enablePriceExceptionLegalMentions?: boolean,
): ProductTile[] => {
    // TODO : This part should be refactored to pass directly the base property in parameter to allow the customisation for each component / page
    const t = useTranslations('Discover.Home.carousel');
    const isMobile = useTailwindBreakpoint('tablet', true, true);

    const formatPrice = (price: number, currency: string) => StringHelpers.formatPrice(price, currency, locale);

    const getReview = (reviewsCount?: number) =>
        reviewsCount && reviewsCount > 0 ? { count: reviewsCount, label: t('label.reviews') } : undefined;

    const getVariantPossibilities = (productType: ProductType, name: string, variantsCount?: number) => {
        switch (productType) {
            case ProductType.MONO_SKU:
                return name;
            case ProductType.PRODUCT_SET:
                return t('label.productSet', { variants: variantsCount });
            case ProductType.MULTI_SKU_SIZE:
                return t('label.multiFormats', { variants: variantsCount });
            default:
                return t('label.multiSeveralShades', { variants: variantsCount });
        }
    };

    const statusLabel = (stockStatus: StockStatus, showStockStatusBtn: boolean): string => {
        if (!showStockStatusBtn) {
            return t('action.discover');
        }
        switch (stockStatus) {
            case StockStatus.Available:
                return isMobile ? t('action.inStockMobile') : t('action.inStock');
            case StockStatus.OutOfStock:
                return t('action.outOfStock');
            case StockStatus.AvailableOtherStore:
            default:
                return t('action.inStock');
        }
    };

    return useMemo<ProductTile[]>(() => {
        return products.map((product) => {
            const {
                name,
                brand,
                image,
                reviewsCount,
                variantName,
                isEngravable,
                type,
                variantsCount,
                totalIndividualPrice,
                price,
                minPrice,
                priceBeforeDiscount,
                discountPercentage,
                defaultVariantId,
                currency,
                enableDiscountInfo,
                ...rest
            } = product;

            return {
                ...rest,
                brand,
                name,
                image: { ...image, alt: t('ariaLabel.discoverProduct') },
                review: getReview(reviewsCount),
                button: {
                    label: statusLabel(mockProductStatus, showStockStatusBtn),
                    onClick: FunctionHelpers.emptyFn,
                },
                minimalProductPriceLabel: t('label.fromPrice'),
                variantName,
                variantPossibilities: getVariantPossibilities(type, name, variantsCount),
                engravingOptionLabel: isEngravable ? t('label.engravingOption') : undefined,
                totalIndividualPriceLabel: totalIndividualPrice
                    ? t('label.totalIndividualPrice', { value: formatPrice(totalIndividualPrice, currency) })
                    : undefined,
                basePriceLabel: t('label.smallestPrice'),
                noReviewsLabel: t('label.noReviews'),
                minPrice: minPrice ? formatPrice(minPrice, currency) : undefined,
                price: formatPrice(price, currency),
                priceBeforeDiscount: priceBeforeDiscount ? formatPrice(priceBeforeDiscount, currency) : undefined,
                discountPercentageLabel:
                    discountPercentage && enableDiscountInfo
                        ? t('label.discountPercentage', { discountPercentage })
                        : undefined,
                enableDiscountInfo,
                legalMentionsLabel: enablePriceExceptionLegalMentions ? t('label.deliveryInformation') : undefined,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, locale, isMobile]);
};
