'use client';

import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { FidIcon, RichText } from '@headless-workspace/domain/common/client';
import {
    getIcon,
    GlowClickable,
    GlowDivider,
    GlowIcon,
    GlowRating,
    GlowTextBody,
    GlowTextBodySmall,
    IconName,
    Icons,
    PropsWithClassName,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { DateHelpers, FunctionHelpers } from '@headless-workspace/utils';
import { ProductReview, ReviewPurchaserType } from '../../../../server/lib/productDetail';

type ReviewPostListItemProps = PropsWithLocale & PropsWithClassName & ProductReview;

const MAX_CONTENT_LENGTH_MOBILE = 104;
const MAX_CONTENT_LENGTH = 124;

export const ReviewCardListItem: Component<ReviewPostListItemProps> = ({
    rating,
    createdAt,
    userName,
    userGender,
    userAge,
    userCard,
    purchaserType,
    purchaseProductSince,
    title,
    content,
    language,
    isRecommended,
    source,
    vote,
    locale,
    className,
}) => {
    const t = useTranslations('Discover.Pdp.ratingAndReviews');
    const isMobile = useTailwindBreakpoint('tablet', true, true);
    const [shouldClamp, setShouldClamp] = useState(true);
    const [isClamp, setIsClamp] = useState(false);
    const [reviewContent, setReviewContent] = useState(content);

    const seeMoreOnClick = () => {
        if (!content) {
            return;
        }

        if (isClamp) {
            setReviewContent(content);
            setIsClamp(false);
        } else {
            setReviewContent(`${content.slice(0, isMobile ? MAX_CONTENT_LENGTH_MOBILE : MAX_CONTENT_LENGTH)}...`);
            setIsClamp(true);
        }
    };

    useEffect(() => {
        if (!content) {
            setShouldClamp(false);
            setIsClamp(false);
            return;
        }

        const maxLength = isMobile ? MAX_CONTENT_LENGTH_MOBILE : MAX_CONTENT_LENGTH;
        const needsClamping = content.length > maxLength;

        setShouldClamp(needsClamping);
        setIsClamp(needsClamping);

        const updatedContent = needsClamping ? `${content.slice(0, maxLength)}...` : content;

        setReviewContent(updatedContent);
    }, [content, isMobile]);

    return (
        <li
            className={clsx(
                'flex flex-col',
                'py-1 gap-1',
                'border-solid border-medium border-border-primary rounded-0.5',
                className,
            )}
        >
            <div className={'flex flex-row justify-between px-1'}>
                <GlowRating rating={rating} className={'gap-0.5'} />
                <GlowTextBodySmall text={DateHelpers.formatDateToShortDateString(locale, createdAt)} />
            </div>
            <div className={'flex flex-row justify-between px-1'}>
                <div className={'flex flex-col'}>
                    <GlowTextBody text={userName} fontWeight={'bold'} />
                    {userGender && <GlowTextBody text={userGender} />}
                    {userAge && <GlowTextBody text={userAge} />}
                </div>
                <FidIcon displayPreBuyIcon={false} userCard={userCard} />
            </div>
            <div className={'flex flex-col px-1'}>
                <Purchaser purchaserType={purchaserType} />
                <GlowTextBodySmall
                    text={t('label.purchaseProductSince', {
                        time: purchaseProductSince,
                    })}
                    color={'tertiary'}
                />
            </div>
            <div className={'flex flex-col gap-0.5 px-1'}>
                <GlowTextBody text={title} fontWeight={'bold'} />
                <div>
                    {reviewContent && (
                        <>
                            <GlowTextBody text={reviewContent} className={'inline'} />
                            {shouldClamp && (
                                <GlowClickable
                                    content={{
                                        labelElement: (
                                            <GlowTextBody
                                                text={isClamp ? t('action.seeMore') : t('action.seeLess')}
                                                textDecoration={'underline'}
                                                TagName={'span'}
                                            />
                                        ),
                                    }}
                                    onClick={seeMoreOnClick}
                                    className={'pl-0.25 !inline'}
                                />
                            )}
                        </>
                    )}
                </div>
                {language && language !== locale && (
                    <GlowClickable
                        content={{
                            labelElement: (
                                <GlowTextBody
                                    text={t('action.translateWithGoogle')}
                                    textDecoration={'underline'}
                                    TagName={'span'}
                                />
                            ),
                        }}
                        onClick={FunctionHelpers.emptyFn} //FIXME: replace by the right action
                    />
                )}
            </div>
            {isRecommended !== undefined && (
                <GlowTextBody text={t('label.recommends', { isRecommended })} fontWeight={'bold'} className={'px-1'} />
            )}
            {source && (
                <div className={'flex justify-center rounded-0.5 bg-surface-secondary p-0.5 mx-1'}>
                    <RichText
                        fontSize={'bodySmall'}
                        color={'primary'}
                        messageKey={'Discover.Pdp.ratingAndReviews.action.source'}
                        values={{
                            label: source.label,
                        }}
                        links={{
                            brandLink: {
                                href: source.href,
                            },
                        }}
                        dataTestId={'review-source'}
                    />
                </div>
            )}
            <GlowDivider />
            <div className={'flex flex-col gap-0.5 px-1'}>
                <GlowTextBody text={t('label.isReviewUseful')} />
                <div className={'flex flex-row gap-0.5 w-full'}>
                    <VoteButton iconName={IconName.Like} vote={vote.like} />
                    <VoteButton iconName={IconName.Dislike} vote={vote.dislike} />
                </div>
            </div>
            <GlowClickable
                content={{
                    labelElement: (
                        <GlowTextBody text={t('action.reportContent')} textDecoration={'underline'} TagName={'span'} />
                    ),
                }}
                onClick={FunctionHelpers.emptyFn} //FIXME: replace by the right action
                className={'px-1'}
            />
        </li>
    );
};

const Purchaser: Component<{ purchaserType: ProductReview['purchaserType'] }> = ({ purchaserType }) => {
    const t = useTranslations('Discover.Pdp.ratingAndReviews');

    switch (purchaserType) {
        case ReviewPurchaserType.VERIFIED:
            return (
                <div className={'flex flex-row gap-0.5 items-center'}>
                    <GlowIcon Icon={Icons.Verified} type={'fullSize'} width={'1rem'} height={'1rem'} />
                    <GlowTextBody text={t('label.verifiedPurchaser')} />
                </div>
            );
        case ReviewPurchaserType.TESTER:
            return <GlowTextBody text={t('label.testerPurchaser')} />;
        case ReviewPurchaserType.UNCONTROLLED:
            return <GlowTextBody text={t('label.uncontrolledPurchaser')} />;
    }
};

const VoteButton: Component<{ iconName: IconName; vote: number }> = ({ iconName, vote }) => {
    return (
        <div
            className={clsx(
                'w-full',
                // CSS to create an “internal border” => prevents offset
                'relative before:absolute',
                'before:w-full before:h-full',
                'hover:before:rounded-1.5',
                'hover:before:border-bold active:before:border-bold',
                'hover:before:border-border-brand active:before:border-border-brand',
                'hover:before:z-hoverBeforeBorder active:before:z-hoverBeforeBorder',
                'pointer-events-none',
            )}
        >
            <GlowClickable
                className={clsx(
                    'w-full',
                    'flex flex-row flex-1 items-center justify-center',
                    'gap-0.5 px-1.5 py-0.5',
                    'rounded-1.5 border-solid border-medium',
                    'pointer-events-auto',
                )}
                content={{
                    icon: <GlowIcon Icon={getIcon(iconName)} type={'large'} />,
                    labelElement: <GlowTextBody text={`${vote}`} TagName={'span'} />,
                }}
                onClick={FunctionHelpers.emptyFn} //FIXME: replace by the right action in FHEAD-250
            />
        </div>
    );
};
