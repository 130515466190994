export const productDetailDeliveryTermsAndConditions = {
    title: 'Livraison et retours',
    ctaLabel: 'button',
    caption: `* Jours ouvrables: du lundi au samedi hors jours fériés. Pour la livraison à l’étranger, veuillez consulter notre FAQ.`,
    data: [
        {
            title: 'Retrait en magasin',
            iconName: ['Storefront'],
            description: {
                content:
                    '<p>À partir de 2h en fonction des horaires du magasin choisi :<span style="font-weight: bold; color: #005B00" > Offerte</span></p>',
            },
        },
        {
            title: 'Livraison à domicile',
            iconName: ['Truck'],
            description: {
                content:
                    '<p>Standard (2 à 4 jours ouvrables*) : 3,95 € ou<span style="font-weight: bold; color: #005B00"> offerte</span> à partir de 60 €</p><p>Express (1 à 2 jours ouvrables*) : 7,95 €*</p><p>Coursier (en 3h*) : 9,95 €</p>',
            },
            caption: {
                content: '<p>Attention: la livraison express n’est pas disponible en corse</p>',
            },
        },
        {
            title: 'Livraison en point relais',
            iconName: ['Box', 'StrokeDown'],
            description: {
                content:
                    '<p>Standard (2 à 4 jours ouvrables*) : 2,95 € ou<span style="font-weight: bold; color:#005B00"> offerte</span> à partir de 60 €</p><p>Rapide (1 à 2 jours ouvrables*) : 2,95 € ou<span style="font-weight: bold; color:#005B00"> offerte</span> à partir de 60 €</p>',
            },
        },
        {
            title: 'Retours',
            iconName: ['Returns'],
            description: {
                content: '<p>Retours gratuits sous 14 jours</p>',
            },
        },
    ],
};
