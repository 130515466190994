'use client';

import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { Position, PRODUCT_PRICES_INFORMATION_MENU_ID } from '@headless-workspace/config';
import { SideMenuModal, useUIContext } from '@headless-workspace/core-ui';
import { PromotionType } from '@headless-workspace/domain/common/server';
import { GlowTextBody, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { PromotionInformationType } from '../../../../../server';
import { ProductDetailInfoModal } from '../../productDetailModal';
import { ProductDetailPrice } from './ProductDetailPrice';
import { ProductDetailPricesPromotion } from './ProductDetailPricesPromotion';

type ProductDetailPricesContainerProps = PropsWithFontFamily &
    PropsWithLocale & {
        price: number;
        minPrice?: number;
        pricePerUnit?: string;
        priorPrice?: number;
        originalPrice?: number;
        currency: string;
        priceAlwaysBlack: boolean;
        promotion?: PromotionType;
        discountPercentageLabel?: string;
        isVipBrand: boolean;
        promotionInformation?: PromotionInformationType;
        formattedTotalIndividualPrice?: string;
    };

export const ProductDetailPricesContainer: Component<ProductDetailPricesContainerProps> = ({
    price,
    pricePerUnit,
    currency,
    locale,
    fontFamily,
    promotion,
    discountPercentageLabel,
    priorPrice,
    originalPrice,
    isVipBrand,
    priceAlwaysBlack,
    promotionInformation,
    formattedTotalIndividualPrice,
}) => {
    const t = useTranslations('Discover.Pdp.information.price');

    const {
        menuModal: { addAndOpen: addAndOpenMenu },
    } = useUIContext();

    const priceInfoModal = useMemo(
        () => (
            <ProductDetailInfoModal
                title={t('pricesModal.label.title')}
                closeButtonLabel={t('modal.action.close')}
                contentContainerClassName={'m-1'}
                productModalId={PRODUCT_PRICES_INFORMATION_MENU_ID}
                renderInformation={
                    <ProductDetailPricesPromotion
                        priorPrice={priorPrice}
                        price={price}
                        currency={currency}
                        locale={locale}
                        isVipBrand={isVipBrand}
                        pricePerUnit={pricePerUnit}
                        fontFamily={fontFamily}
                        priceAlwaysBlack={priceAlwaysBlack}
                        originalPrice={originalPrice}
                        discountPercentageLabel={discountPercentageLabel}
                        promotion={promotion}
                        displayLegalMentionInformation={true}
                        promotionInformation={promotionInformation}
                    />
                }
            />
        ),
        [price],
    );

    const onOpenMenu = useCallback(
        (content: ReactNode) => {
            const menuClassName = 'flex flex-col text-text-brand overflow-hidden';
            addAndOpenMenu(
                PRODUCT_PRICES_INFORMATION_MENU_ID,
                SideMenuModal(Position.Right, { default: content }, menuClassName),
            );
        },
        [addAndOpenMenu],
    );

    const isProductWithoutPromotion = price === originalPrice;

    return (
        <>
            {isProductWithoutPromotion ? (
                <ProductDetailPrice
                    price={price}
                    locale={locale}
                    currency={currency}
                    pricePerUnit={pricePerUnit}
                    fontFamily={fontFamily}
                    promotion={promotion}
                    priceAlwaysBlack={priceAlwaysBlack}
                    displayLegalMentionInformation={false}
                />
            ) : (
                <ProductDetailPricesPromotion
                    priorPrice={priorPrice}
                    price={price}
                    currency={currency}
                    locale={locale}
                    isVipBrand={isVipBrand}
                    pricePerUnit={pricePerUnit}
                    fontFamily={fontFamily}
                    priceAlwaysBlack={priceAlwaysBlack}
                    originalPrice={originalPrice}
                    discountPercentageLabel={discountPercentageLabel}
                    promotion={promotion}
                    onOpenProductPriceModal={() => onOpenMenu(priceInfoModal)}
                    displayLegalMentionInformation={false}
                    promotionInformation={promotionInformation}
                />
            )}

            {formattedTotalIndividualPrice && (
                <GlowTextBody
                    text={formattedTotalIndividualPrice}
                    className={'mt-1'}
                    fontFamily={fontFamily}
                    fontWeight={'bold'}
                />
            )}
        </>
    );
};
