import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { GlowButton, GlowDivider, GlowSkeleton, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { AppointmentButton } from '../../../../../src';

type StoresBannerSkeletonProps = PropsWithLocale;

export const StoresBannerSkeleton: Component<StoresBannerSkeletonProps> = ({ locale }) => {
    const t = useTranslations('Header.storesAndServices');

    return (
        <>
            <div className={'flex flex-col flex-1 gap-2'}>
                <GlowSkeleton height={'2rem'} />

                <div className={'flex flex-col gap-y-0.75'}>
                    <GlowSkeleton width={'50%'} height={'1.688rem'} />
                    <GlowSkeleton height={'1.25rem'} />
                </div>

                <div className={'flex flex-col gap-y-0.5'}>
                    <GlowSkeleton width={'70%'} height={'1.25rem'} />
                    <GlowSkeleton width={'80%'} height={'1.25rem'} />
                    <GlowSkeleton width={'30%'} height={'1.25rem'} />
                    <GlowSkeleton width={'60%'} height={'1.25rem'} />
                </div>
            </div>

            <GlowDivider vertical={true} />
            <div className={clsx('flex flex-col flex-1 gap-2')}>
                <div className={'flex flex-col gap-y-0.75'}>
                    <GlowSkeleton height={'2rem'} width={'50%'} />
                    <div className={'flex flex-col gap-y-0.5'}>
                        <GlowSkeleton width={'60%'} height={'1.25rem'} />
                        <GlowSkeleton width={'70%'} height={'1.25rem'} />
                        <GlowSkeleton width={'40%'} height={'1.25rem'} />
                    </div>
                </div>
                <div className={'flex flex-col gap-1'}>
                    <AppointmentButton />
                    <GlowButton
                        variant={'secondary'}
                        label={t('banner.action.seeServices')}
                        href={buildRoute(locale, 'allServices')}
                    />
                </div>
            </div>
        </>
    );
};
