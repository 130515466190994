import { ConfigByKeyRepository, Result, ResultSuccess } from '@headless-workspace/core-domain';
import '@headless-workspace/data';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { HolidaysValue } from './HolidaysValue';
import { holidaysValueMock } from './holidaysMock';

export interface HolidaysRepositorySpec {
    getHolidaysCalendar(locale: string): Promise<Result<HolidaysValue>>;
}

// const HOLIDAYS_CALENDAR_KEY = 'holidaysCalendar';

export class HolidaysRepository extends ConfigByKeyRepository implements HolidaysRepositorySpec {
    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getHolidaysCalendar(locale: string): Promise<Result<HolidaysValue>> {
        // FIXME: Use the line below to fetch the data from SODA
        // return await this.getConfigByKey(HOLIDAYS_CALENDAR_KEY, { locale }, (value) => HolidaysDTO.parse(value));
        return ResultSuccess(holidaysValueMock);
    }
}
