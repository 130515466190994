import { DefaultValueConfig } from '@headless-workspace/config';
import { PropsWithButton } from '@headless-workspace/glow-ds';
import { ObjectHelpers } from '@headless-workspace/utils';
import { ProductDetailBannerType } from '../ProductDetailBannerType';
import { FidelityBannerValue } from '../fidelityBanner';
import { MarketingBannerDTO } from './MarketingBannerDTO';

export type MarketingBannerValue = FidelityBannerValue & PropsWithButton;

export const mapMarketingBanner = ({
    id,
    image,
    title,
    description,
    cta,
    legalMention,
}: MarketingBannerDTO): MarketingBannerValue => {
    const imageSrc = image?.default ?? DefaultValueConfig.imagePlaceholder;
    const imageAlt = image?.alt;

    return {
        type: ProductDetailBannerType.Marketing,
        title: title ?? '',
        content: description ?? '',
        button: { label: cta.label, href: cta.link },
        id,
        legalMention,
        image: ObjectHelpers.createImageWithSrc(imageSrc, imageAlt),
    };
};
